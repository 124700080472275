<template>
  <div>
    <Header title="Impact"/>
    <div class="impact-wrap">
      <!-- section -->
      <div class="section-heading">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <h3 class="title">Our IMPACT</h3>
            </div>
            <div class="section-col">
              <div class="img" id="impactRotateImage">
                <img width="1024" height="768" src="~@/assets/img/en/Impact/impact_bg3.jpeg" class="attachment-full size-full" alt="" sizes="(max-width: 1024px) 100vw, 1024px">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-tangible-impact">
        <div class="inner">
          <div class="title">Powerful Results, Tangible <strong>IMPACT</strong></div>
          <div class="section-row">
            <div class="section-col">
              <p>Kitkit School is transforming learning and delivering powerful results for children, regardless of initial capacity and access to resources. Since Kitkit was developed in 2016, our partner organizations have helped make our growing impact possible, delivering Kitkit School to children and hard-to-reach communities across the globe.&ZeroWidthSpace;</p>
            </div>
            <div class="section-col">
              <p class="font-bold">Our comprehensive learning app has demonstrated…</p>
              <ul class="dotted-list"><li>Significant gains in literacy and numeracy</li><li>Improved outcomes for those who need it the most</li><li>Transformative benefits beyond academics</li></ul>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-global-learning">
        <div class="inner">
          <div class="heading-title-wrap">
            <h4 class="heading-title"><b>Global</b> <b>Learning</b> <strong>XPRIZE</strong></h4>
          </div>
          <p class="desc"><strong>Kitkit School is proud to be named the co-winner of the $15M Global Learning XPRIZE competition&nbsp;</strong>that challenges teams from around the world to develop open-source, scalable software that will enable children in developing countries to teach themselves basic reading, writing, and arithmetic.</p>
          <div class="section-row">
            <div class="section-col">
              <div class="video-wrap">
                <div class="video">
                  <iframe class="video-iframe" allowfullscreen="" title="youtube Video Player" src="https://www.youtube.com/embed/9Hqgk2BXdAc?feature=oembed&amp;start=1&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
                </div>
              </div>
            </div>
            <div class="section-col">
              <p class="desc"><b>XPRIZE</b><span style="font-weight: 400;">, in partnership with </span><b>UNESCO</b><span style="font-weight: 400;">, </span><b>World Food Programme</b><span style="font-weight: 400;">, and the </span><b>Tanzanian Ministry of Education</b><span style="font-weight: 400;">, conducted a </span><b>15-month</b><span style="font-weight: 400;"> long field test with </span><b>3,000 children</b><span style="font-weight: 400;"> in Tanzania with its five finalists’ solutions in remote villages in Tanzania. About <strong>450 children</strong> in <strong>30 villages</strong> used <strong>Kitkit School. </strong>While all of the top-five finalists demonstrated learning gains, the prize was given to the top performers.&nbsp; &nbsp;<strong><span style="color: #ffc300;"><a style="color: #ffc300; text-decoration: underline;" href="https://www.xprize.org/prizes/global-learning" target="_blank" rel="noopener">Learn</a></span></strong><span style="text-decoration: underline;"><strong><span style="color: #ffc300; text-decoration: underline;"><a style="color: #ffc300; text-decoration: underline;" href="https://www.xprize.org/prizes/global-learning" target="_blank" rel="noopener"> More</a></span></strong></span></span></p>
            </div>
          </div>
          <div class="banner-wrap">
            <a target="_blank" href="/doc/Impact-Brief-Update-XPRIZE-3.pdf" class="icon-box-wrapper">
              <div class="icon-box-icon">
                <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.06 69"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M28,49.59A2.14,2.14,0,0,1,26.51,49l-8.63-8.62a2.16,2.16,0,1,1,3.05-3.05l7.1,7.1,7.1-7.1a2.16,2.16,0,1,1,3,3.05L29.56,49A2.17,2.17,0,0,1,28,49.59Z"></path><path d="M28,49.59a2.16,2.16,0,0,1-2.16-2.15V25.87a2.16,2.16,0,0,1,4.32,0V47.44A2.16,2.16,0,0,1,28,49.59Z"></path><path d="M41,58.22H15.09a2.16,2.16,0,0,1,0-4.31H41a2.16,2.16,0,0,1,0,4.31Z"></path><path d="M50.53,69h-45A5.47,5.47,0,0,1,0,63.6V19.31a7.16,7.16,0,0,1,2.09-5L14.27,2.09a7.08,7.08,0,0,1,5-2.09H50.53a5.47,5.47,0,0,1,5.53,5.39V63.61A5.47,5.47,0,0,1,50.53,69ZM19.31,4.31a2.8,2.8,0,0,0-2,.82L5.13,17.32a2.84,2.84,0,0,0-.82,2V63.6a1.17,1.17,0,0,0,1.23,1.09h45a1.16,1.16,0,0,0,1.22-1.08V5.39a1.16,1.16,0,0,0-1.22-1.08Z"></path><path d="M16.17,21.56H8.62a2.16,2.16,0,0,1,0-4.31h7.55a1.08,1.08,0,0,0,1.08-1.08V8.62a2.16,2.16,0,0,1,4.31,0v7.55A5.4,5.4,0,0,1,16.17,21.56Z"></path></g></g></svg>				</span>
              </div>
              <div class="icon-box-content">
                <h3 class="icon-box-title">
                  <span>Kitkit School</span>
                </h3>
                <p class="icon-box-description">Global Learning XPRIZE Field Test Data Report</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-featured-field-tests">
        <div class="inner">
          <div class="title">Featured <strong>FIELD TESTS </strong></div>
          <div class="section-row">
            <div class="section-col">
              <p>The Enuma, Inc. team is committed to promoting an inclusive and equitable quality education where all learners can succeed. As the tangible result of Enuma’s core commitment to deliver world-class learning solutions to every child in need, Kitkit School has been field-tested in a wide variety of settings with a wide spectrum of children, and has proven positive results for early learners, their families, schools, and communities.</p>
            </div>
            <div class="section-col">
              <div class="column-wrap">
                <div class="widget-wrap">
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#bangladesh"><img src="~@/assets/img/en/Impact/bangflag@2x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#bangladesh">BANGLADESH</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#tanzania"><img src="~@/assets/img/en/Impact/tanzania@4x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#tanzania">TANZANIA</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#kenya"><img src="~@/assets/img/en/Impact/kenya@4x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#kenya">KENYA</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#rwanda"><img src="~@/assets/img/en/Impact/rwanda@4x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#rwanda">RWANDA</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section (수정) -->
      <div class="section-bangladesh" id="bangladesh">
        <div class="inner">
          <h4 class="title">Cox's Bazar,<strong> BANGLADESH</strong></h4>
          <div class="section-row">
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/bangladesh@2x.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/bangladeshphoto.jpg" alt="">
              </div>
            </div>
            <div class="section-col">
              <div class="desc-box"><p><span style="font-weight: 400;">Together with the International Rescue Committee and Imagine Worldwide, we are bringing Kikit School for Rohingya Learners to Cox’s Bazar, Bangladesh. To date, <strong>over 900,000 Rohingya</strong> have fled violence and persecution in Myanmar to seek refuge in Cox’s Bazar, and over half of those displaced are children or adolescents. As an estimated majority of the Rohingya children are reported to have never participated in any form of schooling prior to and since arriving in Bangladesh, the need for quality educational services is extremely urgent. </span></p><p><span style="font-weight: 400;">We are honored to deliver our localized digital solution to homes and learning centers throughout the camp, alongside our dedicated partners, as part of a coordinated early response to this emergency.</span></p></div>
              <div class="btn-wrapper">
                <a href="https://enuma-public.s3-us-west-2.amazonaws.com/irc/IRC+Pop-Up+Model+-+Cox's+Bazar+Feasibility+Study_v1.pdf" target="_blank" class="btn" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>IRC’s FEASIBILITY STUDY</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-tanzania" id="tanzania">
        <div class="inner">
          <h4 class="heading-title">Mtwara and Bagamoyo,  <strong>TANZANIA </strong></h4>
          <div class="section-row">
            <div class="section-col">
              <div class="desc-box">
                <p><span style="font-weight: 400;">2017 controlled trial in rural Tanzania revealed that Kitkit School was effective in improving learning outcomes in both in-school and out-of-school settings, building literacy and math skills for </span><b>100 students</b><span style="font-weight: 400;"> over three months in two test groups: 1) 6- to 10-year-olds in a community center, 2) a treatment group of Standard 1-3 students in a rural primary school.</span></p><p><span style="font-weight: 400;">Results are deeply encouraging, particularly in the context of Tanzania where 23.2% of 7- to 13-year-old children not enrolled in primary schools due to various barriers to school enrollment. Our results demonstrate that mobile technology approach as an educational tool has a great potential to facilitate “self-directed” learning for children in remote or fragile areas.</span></p>
              </div>
              <div class="btn-wrapper">
                <a href="/doc/Kitkit_CaseStudy_Mtwara_.pdf" target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>MTWARA CASE STUDY</span>
                </a>
              </div>
            </div>
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/tanzamap.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/tanzania_impact.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section (수정)-->
      <div class="section-kenya" id="kenya">
        <div class="inner">
          <div class="title">Kakuma Refugee Camp,  <strong>KENYA</strong></div>
          <div class="section-row">
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/kenyamap.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/kenya_impact.jpg" alt="">
              </div>
            </div>
            <div class="section-col">
              <div class="desc-box">
                <p><span style="font-weight: 400;">Xavier Project partnered with Enuma to improve access to quality education for </span><b>240 refugee and host community children</b><span style="font-weight: 400;"> using Kitkit School at the Kalobeyei Settlement of the Kakuma Refugee Settlement.</span></p><p><span style="font-weight: 400;">For the randomly selected subset of 35 students studied, the results were dramatic… especially for those who needed the intervention the most. With 30 minutes of independent learning daily with Kitkit School for 8 weeks, students in Kalobeyei Settlement increased their post-test scores by double-digit averages in both literacy and math.</span></p>
              </div>
              <div class="btn-wrapper">
                <a href="/doc/Xavier-Project_CaseStudy.pdf" target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>KAKUMA CASE STUDY</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-rwanda" id="rwanda">
        <div class="inner">
          <h4 class="title">Kagina Primary School,  <strong>RWANDA</strong></h4>
          <div class="section-row">
            <div class="section-col">
              <div class="desc-box">
                <p><span style="font-weight: 400;">Good Neighbors Rwanda partnered with Enuma, Inc. to equip students at Kagina Primary School in Kagina, Rwanda with basic English literacy and math skills to prepare them for the transition of the language of instruction from Kinyarwanda to English in Grade 4. From September to December 2018, </span><b>684 students</b><span style="font-weight: 400;"> participated in the 25-week Kitkit Session opportunity.</span></p><p><span style="font-weight: 400;">Students demonstrated significant learning gains with a positive correlation of intervention time to higher achievement.</span></p>
              </div>
              <div class="btn-wrapper">
                <a href="/doc/Kitkit_CaseStudy_Mtwara_.pdf" target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>RWANDA CASE STUDY</span>
                </a>
              </div>
            </div>
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/rwandamap.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/rwandagroup.jpeg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-impact-summary">
        <div class="inner">
          <h1 class="heading-title">IMPACT SUMMARY</h1>
          <div class="btn-wrapper">
            <a href="/doc/Kitkit-School-Impact-Summary-.pdf" target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
              <i aria-hidden="true" class="fas fa-download"></i>
              <span>DOWNLOAD</span>
            </a>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-slider">
        <div class="inner">
          <div class="slider-wrap">
            <swiper class="swiper" :options="swiperOption" ref="swiper">
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        "Nashin has since become one of the active members of the Kitkit class...Kitkit School motivates him to come to school every day."
                      </div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-title">Xavier Report</span></cite>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        "This project inspired me and the learners in my class. I have seen a lot of changes in my learners like they can now read and write and they also do mathematics correctly."					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">William Atari</span><span class="testimonial-title">TEACHER</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        "This Kitkit project has brought a lot of improvement in our school. One of the girls in from the Kitkit class has improved up to position 3 this term."					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Simon Peter</span><span class="testimonial-title">TEACHER</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        "The program has really helped the children to improve. As their teacher, I also found it easier to teach in class as the pupils were motivated and actively participating."					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Chachita Abel</span><span class="testimonial-title">TEACHER</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        "As soon as the student is logged in, they’re learning, and I’ve never seen 30 students in a classroom so engaged."					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Edmund Page</span><span class="testimonial-title">Xavier Project</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        "When we started kitkit class, there was a lot of absenteeism. But as we continued, many kids felt encouraged to come to school every day."					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Paul Nadia</span><span class="testimonial-title">Xavier Project</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        "I was amazed. Children really proved they have natural ability to explore by themselves!"
                      </div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Heeju</span><span class="testimonial-title">TEACHER</span></cite></div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "Impact",
  head: {
    title: {
      inner: 'Impact',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Kitkit School' },
      { name: 'twitter:image', content: require('../assets/img/common/rwanda@4x.png')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Impact - Kitkit School' },
      { property: 'og:description', content: 'Our IMPACT Powerful Results, Tangible IMPACT Kitkit School is transforming learning and delivering powerful results for children, regardless of initial capacity and access to resources. Since Kitkit was developed in 2016, our partner organizations have helped make our growing impact possible, delivering Kitkit School to children and hard-to-reach communities across the globe. Our comprehensive learning […]' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/xprize-logo-black@4x-1024x251.png') },
      { property: 'og:url', content: 'http://kitkitschool.com/impact/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/impact/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  data() {
    return {
      thumbsSwiper: null,
      swiperOption: {
        loop: true,
        autoplay : {
          delay : 3000,
          disableOnInteraction : false,
        },
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          999: {
            slidesPerView: 2,
          }
        },
        on: {
          init : function () {
            // console.log('swiper 초기화');
          },
          activeIndexChange: function () {
            // console.log(this.realIndex);
          }
        },
      },
    };
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    handleScroll () {
      // var scrollHeight = document.body.scrollHeight;
      // var maxRotate = -8.25;
      // var rotateVal = -8.943 + ((document.querySelector('html').scrollTop * maxRotate) / scrollHeight) * 35;
      // var rotateImage = document.getElementById("impactRotateImage");
      // rotateImage.style.transform = 'rotateZ('+ rotateVal +'deg)';
    }
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
    Swiper,
    SwiperSlide
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.impact-wrap {
  padding-top: 76px;
  overflow: hidden;

  .inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  .section-row {
    display: flex;
  }

  img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  p {
    margin-bottom: 20px;
    line-height: 39.6px
  }

  b, strong {
    font-weight: 600;
  }

  .btn-wrapper {
    display: flex;
    padding-bottom: 25px;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      font-family: "Lato", Lato, Open sans;
      font-size: 2rem;
      font-weight: 900;
      fill: #4f3c33;
      color: #4f3c33;
      background-color: #ffffff;
      border-style: solid;
      border-width: 3px 3px 3px 3px;
      border-color: rgba(255, 255, 255, 0);
      border-radius: 45px 45px 45px 45px;
      padding: 15px 100px 15px 100px;
      transition: all 0.3s ease;

      i {
        margin-right: 18px;
      }

      &:hover {
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0);
        border-color: #ffffff;
      }
    }
  }

  .font-bold {
    font-weight: 600;
  }

  .dotted-list {
    padding-left: 10px;

    li {
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 22px;
      font-weight: 400;
      line-height: 44px;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-heading {
    background-color: #ffe300;
    padding-top: 10px;

    .section-row {
      .section-col {
        width: 50%;

        &:first-child {
          display: flex;
          padding: 0 10% 0 0;
          align-items: center;
        }

        &:nth-child(2) {
          padding: 10px;
        }

        .title {
          color: #000000;
          font-family: "Ubuntu", Lato, Open sans;
          font-size: 6rem;
          font-weight: 900;
          line-height: 1.2em;
        }

        .img {
          transform: rotateZ(-8.943deg);
        }

        img {
          max-width: 85%;
          border-style: solid;
          border-width: 10px 10px 10px 10px;
          border-color: #ffffff;
          vertical-align: middle;
          display: inline-block;
          height: auto;
        }
      }
    }
  }

  .section-tangible-impact {
    padding: 100px 0 80px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin-top: -12px;
      margin-bottom: 20px;
    }

    p {
      color: #333;
      font-family: "Lato", Lato, Open sans;
      font-size: 22px;
      line-height: 39.6px;
    }

    .section-row {
      padding-top: 35px;

      .section-col {
        width: 50%;

        &:first-child {
          padding-right: 30px;
        }

        &:nth-child(2) {
          padding-left: 50px;

          p {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.4em;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .section-global-learning {
    position: relative;
    background-color: #3f7f25;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0;

    .heading-title-wrap {
      position: relative;

      .heading-title {
        display: inline-block;
        color: #ffffff;
        font-family: "Ubuntu", Lato, Open sans;
        font-size: 3.8rem;
        font-weight: 400;
        line-height: 42px;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: calc(100% - 427px - 20px);
        height: 5px;
        background-color: #FFC300;
        right: 0;
        bottom: 7px;
      }
    }

    .desc {
      padding-top: 40px;
      color: #ffffff;
      font-family: "Lato", Lato, Open sans;
      font-size: 22px;
      margin-bottom: 40px;
      line-height: 39.6px;
    }

    .video-wrap {
      .video {
        position: relative;
        padding-bottom: 56.25%;
      }

      iframe {
        position: absolute;
        max-width: 100%;
        width: 100%;
        margin: 0;
        line-height: 1;
        border: none;
        top: 0;
        left: 0;
      }
    }

    .section-row {
      padding-bottom: 30px;

      .section-col {
        &:first-child {
          width: 40%;
          padding-right: 30px;
        }

        &:nth-child(2) {
          width: 60%;
          padding-left: 30px;

          .desc {
            padding-top: 0;
            line-height: 36.3px;
            margin-top: -5px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .banner-wrap {
      width: 100%;
      background-image: url(~@/assets/img/en/Impact/img-download-btn.png);
      background-position: center right;
      background-repeat: no-repeat;
      background-size: cover;
      transition: background 0.4s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

      &:hover {
        background-position: center center;
      }

      .icon-box-wrapper {
        display: flex;
        padding: 28px 20px;

        .icon-box-icon {
          padding-left: 10px;
          text-align: left;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          .icon {
            margin-right: 20px;
            fill: #FFFFFF;
            color: #FFFFFF;
            border-color: #FFFFFF;
            font-size: 50px;
            display: inline-block;
            line-height: 1;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            text-align: center;

            svg {
              width: 1em;
              height: 1em;
              position: relative;
              display: block;
            }
          }
        }

        .icon-box-content {
          .icon-box-title {
            font-family: "Lato", Lato, Open sans;
            font-weight: 700;
            line-height: 1em;
            color: #EC7015;
            margin-bottom: 10px;
          }

          .icon-box-description {
            color: #FFFFFF;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
            line-height: 1.2em;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .section-featured-field-tests {
    padding: 100px 0 80px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin-bottom: 20px;
      margin-top: -12px;
    }

    .section-row {
      padding-top: 35px;

      .section-col {
        &:first-child {
          width: 59.982%;
          padding-right: 30px;

          p {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
            line-height: 39.6px;
          }
        }

        &:nth-child(2) {
          width: 40.018%;
          padding-left: 50px;

          .column-wrap {
            .widget-wrap {
              .image-box {
                display: flex;
                margin-bottom: 10px;
                margin-top: -10px;

                .image-box-wrapper {
                  display: flex;

                  .image-box-img {
                    width: 25%;
                    margin-right: 15px;
                    margin-bottom: 0;

                    a {
                      display: block;
                    }
                  }

                  .image-box-content {
                    display: flex;
                    width: 100%;
                    align-items: center;

                    .image-box-title {
                      color: #333333;
                      font-family: "Lato", Lato, Open sans;
                      font-size: 24px;
                      font-weight: 900;
                    }
                  }
                }


                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .section-bangladesh {
    background-color: #4f3c33;
    padding: 120px 0 100px;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .title {
      color: #ffffff;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      line-height: 38px;
      margin-bottom: 20px;
      margin-top: -12px;
      text-align: right;
      padding: 0px 72px 0px 0px;
    }

    .section-row {
      .section-col {
        &:first-child {
          width: 50%;
          padding: 0 50px 0 0;

          .heading-img {
            margin: -213px 0px 60px 0px;

            img {
              max-width: 90%;
            }
          }

          .sub-img {
            padding-top: 130px;

            img {
              max-width: 100%;
              border-style: solid;
              border-width: 7px 7px 7px 7px;
              border-color: #ffffff;
            }
          }
        }

        &:nth-child(2) {
          width: 50%;

          .desc-box {
            padding-top: 35px;
            padding-bottom: 20px;
            padding-left: 30px;

            p {
              color: #ffffff;
              font-family: "Lato", Lato, Open sans;
              font-size: 22px;
              line-height: 39.6px;
            }
          }
        }
      }
    }
  }

  .section-tanzania {
    background-color: #0394e5;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 120px 0 100px 0;

    .heading-title {
      color: #ffffff;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin-top: -12px;
      margin-bottom: 20px;
    }

    .section-row {
      margin-top: 35px;

      .section-col {
        width: 50%;
        padding-right: 30px;

        .desc-box {
          padding-bottom: 20px;

          p {
            color: #ffffff;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
          }
        }

        &:nth-child(2) {
          padding: 0px 0px 0px 50px;
          margin-top: -35px;

          .heading-img {
            text-align: right;
            margin: -220px 0px 20px 0px;

            img {
              max-width: 70%;
            }
          }

          .sub-img {
            padding-top: 120px;

            img {
              max-width: 100%;
              border-style: solid;
              border-width: 7px 7px 7px 7px;
              border-color: #ffffff;
            }
          }
        }

        .btn-wrapper {
          a {
            fill: #0394e5;
            color: #0394e5;

            &:hover {
              color: #ffffff;
              background-color: rgba(0, 0, 0, 0);
              border-color: #ffffff;
            }
          }
        }
      }
    }
  }

  .section-kenya {
    background-color: #ffe300;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 120px 0px 100px 0px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin: -12px 0px 20px 0px;
      text-align: right;
    }

    .section-row {
      .section-col {
        width: 50%;

        &:first-child {
          margin: -0 0px 0px 0px;
          padding: 0 50px 0px 0px;
        }

        .heading-img {
          margin: -205px 0px 10px 0px;

          img {
            max-width: 70%;
          }
        }

        .sub-img {
          padding: 50px 0px 0px 0px;

          img {
            max-width: 100%;
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            border-color: #ffffff;
          }
        }

        .desc-box {
          padding: 45px 0px 20px 30px;

          p {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
          }
        }

        .btn-wrapper {
          a {
            fill: #ffe300;
            color: #ffe300;
            background-color: #333333;

            &:hover {
              color: #333333;
              background-color: rgba(0, 0, 0, 0);
              border-color: #333333;
            }
          }
        }
      }
    }
  }

  .section-rwanda {
    background-color: #3f7f25;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 120px 0px 100px 0px;

    .title {
      color: #ffffff;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin: -12px 0px 20px 0px;
    }

    .section-row {
      .section-col {
        width: 50%;

        &:nth-child(2) {
          margin: -35px 0px 0px 0px;
          padding: 0px 0px 0px 50px;
          text-align: right;
        }

        .heading-img {
          margin: -190px 0px 15px 0px;

          img {
            max-width: 70%;
          }
        }

        .sub-img {
          margin: 0px 0px 0px 0px;
          padding: 10px 0px 0px 0px;

          img {
            max-width: 100%;
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            border-color: #ffffff;
          }
        }

        .desc-box {
          padding: 30px 30px 20px 0px;

          p {
            color: #ffffff;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
          }
        }

        .btn-wrapper {
          a {
            fill: #3f7f25;
            color: #3f7f25;
            background-color: #ffffff;

            &:hover {
              color: #ffffff;
              background-color: rgba(0, 0, 0, 0);
              border-color: #ffffff;
            }
          }
        }
      }
    }
  }

  .section-impact-summary {
    padding: 70px 0px 70px 0px;
    text-align: center;

    .inner {
      padding: 10px;
    }

    .heading-title {
      margin-bottom: 20px;
      line-height: 40px;
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 40px;
      font-weight: 500;
    }

    .btn-wrapper {
      a {
        fill: #ffffff;
        color: #ffffff;
        background-color: #ec7015;
        padding: 15px 50px 15px 50px;

        &:hover {
          color: #ec7015;
          background-color: rgba(0, 0, 0, 0);
          border-color: #ec7015;
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .impact-wrap {
    .inner {
      max-width: 1025px;
    }

    p {
      line-height: 32.4px;
    }

    .btn-wrapper {
      a {
        padding: 15px 50px 15px 50px;
        align-items: flex-start;

        i {
        }

        span {
          display: inline-block;
          line-height: 20px;
        }
      }
    }

    .dotted-list {
      li {
        font-size: 18px;
        line-height: 1.6em;
      }
    }

    .section-heading {
      padding: 10px 25px 10px 25px;

      .section-row {
        .section-col {
          .title {
            font-size: 35px;
          }
        }
      }
    }

    .section-tangible-impact {
      padding: 100px 25px 100px 25px;

      .title {
        font-size: 40px;
      }

      p {
        font-size: 18px;
        line-height: 32.4px;
      }

      .section-row {
        .section-col {
          &:nth-child(2) {
            p {
              line-height: 32.4px;
            }
          }
        }
      }
    }

    .section-global-learning {
      padding: 100px 25px 70px 25px;

      .heading-title-wrap {
        &::after {
          content: none;
        }
      }

      .desc {
        font-size: 18px;
        line-height: 32.4px;
      }

      .section-row {
        .section-col {
          &:first-child {
            width: 45%;
            padding: 0px 20px 0px 0px;
          }

          &:nth-child(2) {
            width: 55%;
            padding: 0;

            .desc {
              font-size: 18px;
              line-height: 29.7px;
            }
          }
        }
      }

      .banner-wrap {
        .icon-box-wrapper {
          padding: 12px 10px 12px 10px;

          .icon-box-icon {
            .icon {
              font-size: 36px;
            }
          }

          .icon-box-content {
            .icon-box-title {
              font-size: 16px;
              margin-bottom: 4px;
            }

            .icon-box-description {
              font-size: 16px;
            }
          }
        }
      }
    }

    .section-featured-field-tests {
      padding: 100px 25px 60px 25px;

      .section-row {
        .section-col {
          &:first-child {
            p {
              font-size: 18px;
              line-height: 32.4px;
            }
          }
        }
      }
    }

    .section-bangladesh {
      padding: 100px 25px 60px 25px;

      .title {
        font-size: 32px;
      }

      .section-row {
        .section-col {
          &:first-child {

            .heading-img {
              margin: -180px 0px 20px 0px;

              img {
                max-width: 85%;
              }
            }

            .sub-img {
              padding: 30px 0px 0px 0px;

              img {
                max-width: 100%;
                border-width: 5px 5px 5px 5px;
              }
            }
          }

          &:nth-child(2) {
            width: 50%;

            .desc-box {
              padding-left: 0;

              p {
                font-size: 16px;
                line-height: 1.6em;
              }
            }
          }
        }
      }
    }

    .section-tanzania {
      padding: 100px 25px 60px 25px;

      .heading-title {
        font-size: 32px;
      }

      .section-row {
        .section-col {
          width: 50%;
          padding-right: 0;

          .desc-box {
            padding: 20px 0px 0px 0px;

            p {
              font-size: 16px;
              line-height: 1.6em;
            }
          }

          &:nth-child(2) {
            .heading-img {
              margin: -170px 0px 0px 0px;

              img {
                max-width: 85%;
              }
            }

            .sub-img {
              img {
                max-width: 95%;
                border-width: 5px 5px 5px 5px;
              }
            }
          }
        }
      }
    }

    .section-kenya {
      padding: 100px 25px 60px 25px;

      .title {
        font-size: 32px;
      }

      .section-row {
        .section-col {


          .heading-img {
            margin: -170px 0px 10px 0px;

            img {
              max-width: 85%;
            }
          }

          .sub-img {

            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding-left: 0;

            p {
              font-size: 16px;
              line-height: 1.6em;
            }
          }
        }
      }
    }

    .section-rwanda {
      padding: 100px 25px 60px 25px;

      .title {
        font-size: 32px;
      }

      .section-row {
        .section-col {
          .heading-img {
            margin: -150px 0px 85px 0px;

            img {
              max-width: 85%;
            }
          }

          .sub-img {
            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding-right: 0;

            p {
              font-size: 16px;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .impact-wrap {
    padding-top: 90px !important;

    .inner {
      max-width: 768px;
    }

    .section-row {
      flex-direction: column;

      .section-col {
        width: 100%;
      }
    }

    p {
      line-height: 28.8px;
    }

    .btn-wrapper {
      a {
        padding: 12px 20px 12px 20px;
      }
    }

    .section-heading {
      padding: 20px 20px 20px 20px;

      .section-row {
        .section-col {
          width: 100%;

          img {
            max-width: 100%;
          }
        }
      }
    }

    .section-tangible-impact {
      padding: 50px 20px 30px 20px;

      .title {
        font-size: 32px;
        line-height: 32px;
      }

      .section-row {

        .section-col {
          width: 100%;

          &:first-child {
            padding-right: 0;
            margin-bottom: 20px;

            p {
              margin-bottom: 30px;
            }
          }

          &:nth-child(2) {
            padding-left: 0;

            p {
            }

            .dotted-list {
              margin: 15px 0 15px 20px;
            }
          }
        }
      }
    }

    .section-global-learning {
      padding: 70px 20px 70px 20px;

      .heading-title-wrap {
        .heading-title {
          font-size: 32px;
        }
      }

      .desc {
        padding-top: 30px;
      }

      .section-row {
        .section-col {
          &:first-child {
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
            width: 100%;
          }

          &:nth-child(2) {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
            width: 100%;
          }
        }
      }

      .banner-wrap {
        background-image: url('~@/assets/img/en/Impact/img-download-bg-m.png');
        background-position: center center;
        background-size: cover;

        .icon-box-wrapper {
          flex-direction: column;

          .icon-box-icon {
            padding-left: 0;
            margin-bottom: 5px;
          }
        }
      }
    }

    .section-featured-field-tests {
      padding: 70px 20px 30px 20px;

      .title {
        font-size: 32px;
        line-height: 36px;
      }

      .section-row {
        padding-top: 10px;

        .section-col {
          &:first-child {
            padding-right: 0;
            width: 100%;
          }

          &:nth-child(2) {
            display: none;
            width: 100%;
          }
        }
      }
    }

    .section-bangladesh {
      padding: 70px 20px 50px 20px;

      .title {
        font-size: 30px;
        text-align: left;
      }

      .section-row {
        .section-col {
          &:first-child {
            width: 100%;
            padding: 0;

            .heading-img {
              margin: 0;

              img {
              }
            }

            .sub-img {
              padding: 0;
              margin: 0;
            }
          }

          &:nth-child(2) {
            width: 100%;

            .desc-box {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .section-tanzania {
      padding: 70px 20px 40px 20px;

      .heading-title {
        font-size: 30px;
        line-height: 30px;
        padding-bottom: 30px;
      }

      .section-row {
        .section-col {
          .desc-box {
            margin-bottom: 40px;

            p {
              font-size: 18px;
            }
          }

          &:first-child {
            width: 100%;
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
            padding: 0;
          }

          &:nth-child(2) {
            width: 100%;
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
            padding: 0;

            .heading-img {
              padding: 0;
              margin: 0;
            }

            .sub-img {
              padding-top: 20px;

              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    .section-kenya {
      padding: 70px 20px 50px 20px;

      .title {
        font-size: 30px;
        line-height: 30px;
        text-align: left;
      }

      .section-row {
        .section-col {

          &:first-child {
            width: 100%;
            padding: 0;
          }

          &:nth-child(2) {
            width: 100%;

          }

          .heading-img {
            margin: 0;
            padding: 0;

            img {
            }
          }

          .sub-img {
            padding: 0;
            margin-top: 20px;

            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding: 30px 0px 20px 0px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }

    .section-rwanda {
      padding: 70px 20px 50px 20px;

      .title {
        font-size: 30px;
        line-height: 30px;
        text-align: left;
        margin-bottom: 60px;
      }

      .section-row {
        .section-col {

          &:first-child {
            width: 100%;
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
            padding: 0;
          }

          &:nth-child(2) {
            width: 100%;
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
            padding: 0;
          }

          .heading-img {
            margin: 0;
            padding: 0;

            img {
            }
          }

          .sub-img {
            padding: 0;
            margin-top: 20px;

            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding: 30px 0px 20px 0px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }

    .section-impact-summary {

      .inner {
      }

      .heading-title {

      }

      .btn-wrapper {
        padding-bottom: 0;

        a {


          &:hover {

          }
        }
      }
    }
  }
}
</style>
