<template>
  <div id="app" class="wrap">
    <router-view/>
  </div>
</template>
<script>
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import '@/assets/scss/style';
</style>
