import Vue from 'vue'
import App from './App.vue'
import VueHead from 'vue-head'
import router from './router'
import store from './store'
import {BootstrapVue} from 'bootstrap-vue'
import VueYoutube from 'vue-youtube'
import twitter from 'vue-twitter'

Vue.use(BootstrapVue);
Vue.use(twitter)
Vue.use(VueYoutube)
Vue.use(VueHead)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
