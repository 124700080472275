<template>
  <div>
    <Header title="Impact"/>
    <div class="impact-wrap">
      <!-- section -->
      <div class="section-heading">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <h3 class="title">Athari Yetu</h3>
            </div>
            <div class="section-col">
              <div class="img" id="impactRotateImage">
                <img width="1024" height="768" src="~@/assets/img/en/Impact/impact_bg3.jpeg" class="attachment-full size-full" alt="" sizes="(max-width: 1024px) 100vw, 1024px">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-tangible-impact">
        <div class="inner">
          <div class="title">Matokeo yenye nguvu, <strong>ATHARI</strong> inayoonekana</div>
          <div class="section-row">
            <div class="section-col">
              <p><span style="font-weight: 400;">Kitkit Shule inabadili kujifunza na kutoa matokeo yenye nguvu kwa watoto, bila kujali uwezo wao wa awali na upatikanaji wa rasilimali. Tangu Kitkit iundwe mwaka 2016, mashirika tunayoshirikiana nayo yamewezesha athari yetu inayokua, kuifikisha Kitkit Shule kwa watoto na pia kwa watoto walio katika jamii ngumu kufikika duniani kote.</span></p>
            </div>
            <div class="section-col">
              <p class="font-bold">Programu yetu pana ya kujifunzia imeonyesha…</p>
              <ul class="dotted-list"><li>Ongezeka kwenye kusoma, kuandika na kuhesabu</li><li>Matokeo yaliyoboreka kwa wale wanaoyahitaji zaidi</li><li>Faida za kimabadiliko hata zaidi ya elimu</li></ul>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-global-learning">
        <div class="inner">
          <div class="heading-title-wrap">
            <h4 class="heading-title">Shindano la Kujifunza la Kidunia la XPRIZE</h4>
          </div>
          <p class="desc">Kitkit Shule inajivunia kuwa mshindi mwenza wa Dola za Kimarekani Milioni 15 za Shindano la Kujifunza la Kidunia la XPRIZE ambalo linazitaka timu mbalimbali kutoka ulimwenguni kote kutengeneza programu za wazi ambazo zinaweza kupanuliwa ili kuweza kuwasaidia watoto kujifunza wenyewe kusoma, kuandika na kuhesabu.</p>
          <div class="section-row">
            <div class="section-col">
              <div class="video-wrap">
                <div class="video">
                  <iframe class="video-iframe" allowfullscreen="" title="youtube Video Player" src="https://www.youtube.com/embed/9Hqgk2BXdAc?feature=oembed&amp;start=1&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
                </div>
              </div>
              <div class="btn-wrap">
                <a href="https://www.xprize.org/prizes/global-learning" class="btn">Jifunze zaidi</a>
              </div>
            </div>
            <div class="section-col">
              <p class="desc">
                <span style="font-weight: 400;">XPRIZE, ikiwa inashirikiana na UNESCO, Shirika la chakula duniani, na Wizara ya Elimu Tanzania, ilifanya majaribio kwenye vijiji yaliyochukua miezi 15 yakijumuisha watoto 3,000 kutoka Tanzania kwa kutumia ufumbuzi kutoka kwa washindi watano. Takribani watoto 450 kwenye vijiji 30 walitumia Kitkit Shule. Wakati washindi wote watano walionyesha matokeo mazuri kwenye kujifunza lakini tuzo ilitolewa kwa waliofanya vizuri zaidi.</span>
              </p>
            </div>
          </div>
<!--          <div class="banner-wrap">-->
<!--            <div class="icon-box-wrapper">-->
<!--              <div class="icon-box-icon">-->
<!--                <span class="icon">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.06 69"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M28,49.59A2.14,2.14,0,0,1,26.51,49l-8.63-8.62a2.16,2.16,0,1,1,3.05-3.05l7.1,7.1,7.1-7.1a2.16,2.16,0,1,1,3,3.05L29.56,49A2.17,2.17,0,0,1,28,49.59Z"></path><path d="M28,49.59a2.16,2.16,0,0,1-2.16-2.15V25.87a2.16,2.16,0,0,1,4.32,0V47.44A2.16,2.16,0,0,1,28,49.59Z"></path><path d="M41,58.22H15.09a2.16,2.16,0,0,1,0-4.31H41a2.16,2.16,0,0,1,0,4.31Z"></path><path d="M50.53,69h-45A5.47,5.47,0,0,1,0,63.6V19.31a7.16,7.16,0,0,1,2.09-5L14.27,2.09a7.08,7.08,0,0,1,5-2.09H50.53a5.47,5.47,0,0,1,5.53,5.39V63.61A5.47,5.47,0,0,1,50.53,69ZM19.31,4.31a2.8,2.8,0,0,0-2,.82L5.13,17.32a2.84,2.84,0,0,0-.82,2V63.6a1.17,1.17,0,0,0,1.23,1.09h45a1.16,1.16,0,0,0,1.22-1.08V5.39a1.16,1.16,0,0,0-1.22-1.08Z"></path><path d="M16.17,21.56H8.62a2.16,2.16,0,0,1,0-4.31h7.55a1.08,1.08,0,0,0,1.08-1.08V8.62a2.16,2.16,0,0,1,4.31,0v7.55A5.4,5.4,0,0,1,16.17,21.56Z"></path></g></g></svg>				</span>-->
<!--              </div>-->
<!--              <div class="icon-box-content">-->
<!--                <h3 class="icon-box-title">-->
<!--                  <span>Kitkit School</span>-->
<!--                </h3>-->
<!--                <p class="icon-box-description">Global Learning XPRIZE Field Test Data Report</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>

      <!-- section -->
      <div class="section-featured-field-tests">
        <div class="inner">
          <div class="title"><strong>MAJARIBIO YALIYOFANYIKA</strong></div>
          <div class="section-row">
            <div class="section-col">
              <p><span style="font-weight: 400;">Timu ya Enuma imejidhatiti kukuza elimu bora na yenye usawa ambapo kila anayejifunza atafanikiwa. Kama matokeo yanayoonekana ya dhamira kuu ya Enuma ya kutoa ufumbuzi wa viwango vya dunia wa kujifunza kwa kila mtoto anayehitaji, Kitkit Shule imejaribiwa katika mazingira tofauti tofauti na wanafunzi tofauti tofauti na imeonyesha matokeo chanya kwa wanaojifunza wa elimu ya awali, familia zao, shule na jamii.</span></p>
            </div>
            <div class="section-col">
              <div class="column-wrap">
                <div class="widget-wrap">
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#bangladesh"><img src="~@/assets/img/en/Impact/bangflag@2x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#bangladesh">BANGLADESH</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#tanzania"><img src="~@/assets/img/en/Impact/tanzania@4x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#tanzania">TANZANIA</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#kenya"><img src="~@/assets/img/en/Impact/kenya@4x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#kenya">KENYA</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="image-box">
                    <div class="widget-container">
                      <div class="image-box-wrapper">
                        <figure class="image-box-img">
                          <a href="#rwanda"><img src="~@/assets/img/en/Impact/rwanda@4x.png" alt=""></a>
                        </figure>
                        <div class="image-box-content">
                          <h3 class="image-box-title">
                            <a href="#rwanda">RWANDA</a>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section (수정) -->
      <div class="section-bangladesh" id="bangladesh">
        <div class="inner">
          <h4 class="title">Cox's Bazar,<strong> BANGLADESH</strong></h4>
          <div class="section-row">
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/bangladesh@2x.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/bangladeshphoto.jpg" alt="">
              </div>
            </div>
            <div class="section-col">
              <div class="desc-box"><p>Kwa kushirikiana na Kamati ya uokoaji ya kimataifa na Fikiria Ulimwenguni(Imagine Worldwide), tunaileta Kitkit Shule kwa ajili ya wanafunzi wa Rohingya katika eneo la Cox’s Bazar, Bangladesh. Hadi sasa, zaidi wa Warohingya 900,000 wamekimbia vurugu na mateso nchini Myanmar kutafuta hifadhi eneo la Cox’s Bazar, na zaidi ya nusu ya waliohamishwa ni Watoto au vijana wadogo. Kama inavyokadiriwa kuwa wengi wa watoto wa Rohingya wanaripotiwa kuwa hawajawahi kushiriki katika mfumo wowote wa masomo kabla na baada ya kuja Bangladesh, uhitaji wa elimu bora ni wa haraka sana.</p><p>Ni heshima kwetu kuwaletea utatuzi wetu wa kiteknolojia uliotengenezwa kwa ajili ya mazingira husika kwa kupitia majumbani na kwenye vituo vya kujifunzia na pia kwenye kambi, pamoja na washirika wetu waliojitolea, kama sehemu ya mwitikio wa mapema kwenye dharura hii.</p></div>
              <div class="btn-wrapper">
                <a href="https://enuma-public.s3-us-west-2.amazonaws.com/irc/IRC+Pop-Up+Model+-+Cox's+Bazar+Feasibility+Study_v1.pdf" target="_blank" class="btn" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>IRC’s FEASIBILITY STUDY</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-tanzania" id="tanzania">
        <div class="inner">
          <h4 class="heading-title">Mtwara and Bagamoyo,  <strong>TANZANIA </strong></h4>
          <div class="section-row">
            <div class="section-col">
              <div class="desc-box">
                <p><span style="font-weight: 400;">Mwaka 2017 Utafiti uliodhibitiwa ulionyesha kuwa Kitkit iliweza kufanya vizuri kwenye </span><span style="font-weight: 400;">kuboresha matokeo ya kujifunza kwa wote waliopo shuleni na wale ambao hawapo katika mazingira ya shuleni, kujenga ujuzi wa kusoma, kuandika na kuhesabu kwa wanafunzi 100 kwa zaidi ya miezi mitatu kwa makundi mawili ya majaribio: 1) Wenye umri wa miaka 6 mpaka 10 kwenye kituo cha jamii, 2) Kundi lililotumia Kitkit la wanafunzi wa darasa la 1 hadi la 3 kwenye shule ya msingi kijijini.</span></p>
                <p><span style="font-weight: 400;">Matokeo yanatia moyo sana, sanasana kwa muktadha wa Tanzania ambapo asilimia 23.2 ya watoto kati ya miaka 7 hadi 14 hawajaandikishwa shuleni kutokana na sababu mbalimbali. Matokeo yetu yalionyesha kwamba mbinu inayohamishika ya kiteknolojia ikitumika kama zana ya kielimu ina uwezo mkubwa sana kuwezesha “Kujiongoza Mwenyewe” Katika kujifunza kwa watoto kwenye maeneo ya vijijini</span></p>
              </div>
              <div class="btn-wrapper">
                <a href="/doc/Kitkit_CaseStudy_Mtwara_.pdf" target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>MTWARA CASE STUDY</span>
                </a>
              </div>
            </div>
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/tanzamap.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/tanzania_impact.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section (수정)-->
      <div class="section-kenya" id="kenya">
        <div class="inner">
          <div class="title">Kambi ya wakimbizi ya Kakuma,    <strong>KENYA</strong></div>
          <div class="section-row">
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/kenyamap.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/kenya_impact.jpg" alt="">
              </div>
            </div>
            <div class="section-col">
              <div class="desc-box">
                <p><span style="font-weight: 400;">Mradi wa Xavier ulishirikiana na Enuma kuboresha upatikanaji wa Elimu kwa wakimbizi 240 na watoto wa jamii wenyeji wao kwa kutumia Kitkit Shule katika Shule ya Msingi Kolobeyei katika kambi ya wakimbizi ya Kakuma.</span></p>
                <p><span style="font-weight: 400;">Kwa kundi la wanafunzi 35 waliochaguliwa bila mpangilio maalum na kuchunguzwa, Matokeo yalikuwa makubwa… sana sana kwa wale ambao walihitaji msaada zaidi. Kwa dakika 30 za kujifunza wenyewe kila siku kwa kutumia Kitkit Shule kwa wiki 8, wanafunzi kwenye makazi ya Kalobeyei waliongeza matokeo yao baada ya jaribio kwa wastani wa tarakimu mbili kwenye kusoma na kuandika na hesabu.</span></p>
              </div>
              <div class="btn-wrapper">
                <a href="/doc/Xavier-Project_CaseStudy.pdf"
                   target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>KAKUMA CASE STUDY</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-rwanda" id="rwanda">
        <div class="inner">
          <h4 class="title">Shule ya Msingi Kagina,  <strong>RWANDA</strong></h4>
          <div class="section-row">
            <div class="section-col">
              <div class="desc-box"><p><span style="font-weight: 400;">Shirika la Majirani Wema(Good Neighbours) Rwanda walishirikiana na Kampuni ya Enuma kuwawezesha wanafunzi katika shule ya msingi Kagina, Rwanda kwa ujuzi wa elimu ya Kingereza, kusoma kuandika na hesabu kwa ajili ya kuwaandaa kwa kuanza kutumia lugha ya Kiingereza kama lugha ya Kiofisi badala ya Lugha ya Kinyarwanda kwa darasa la 4. Kutoka mwezi wa tisa hadi wa kumi na mbili mwaka 2018, wanafunzi 684 walishiriki kwenye fursa wa wiki 25. </span></p><p><span style="font-weight: 400;">Wanafunzi walionyesha matokeo makubwa kwenye kujifunza na uwiano chanya wa muda uliotumika kufikia mafanikio makubwa</span></p></div>
              <div class="btn-wrapper">
                <a href="/doc/Kitkit_CaseStudy_Mtwara_.pdf" target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
                  <i aria-hidden="true" class="fas fa-download"></i>
                  <span>RWANDA CASE STUDY</span>
                </a>
              </div>
            </div>
            <div class="section-col">
              <div class="heading-img">
                <img src="~@/assets/img/en/Impact/rwandamap.png" alt="">
              </div>
              <div class="sub-img">
                <img src="~@/assets/img/en/Impact/rwandagroup.jpeg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-impact-summary">
        <div class="inner">
          <h1 class="heading-title">MUHTASARI WA ATHARI</h1>
          <div class="btn-wrapper">
            <a href="/doc/Kitkit-School-Impact-Summary-.pdf" target="_blank" class="elementor-button-link elementor-button elementor-size-lg" role="button">
              <i aria-hidden="true" class="fas fa-download"></i>
              <span>Pakua</span>
            </a>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-slider">
        <div class="inner">
          <div class="slider-wrap">
            <swiper class="swiper" :options="swiperOption" ref="swiper">
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        “Mradi huu umenihamasisha mimi na wanafunzi darasani kwangu. Nimeona mabadiliko mengi kwa wanafunzi wangu kama sasa hivi wanaweza wakasoma na kuandika na wanaweza pia wakafanya hesabu vizuri”
                      </div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">William Atari</span><span class="testimonial-title">Mwalimu</span></cite>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        “Mradi wa Kitkit umeleta maboresho makubwa shuleni kwetu, Mojawapo wa wasichana kutoka darasa la kitkit amepiga hatua kubwa hadi kufikia nafasi ya tatu kwa muhula huu”					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Simon Peter</span><span class="testimonial-title">Mwalimu</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        “Mpango umewasaidia wanafunzi sana kuboreka. Kama mwalimu, nimejikuta napata urahisi kufundisha darasani kwa sababu wanafunzi walikuwa wamehamasika na wanashiriki kikamilifu”					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Chachita Abel</span><span class="testimonial-title">Mwalimu</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        “Mara tu wanafunzi wakishaingia wanaanza kujifunza, na sijawahi kuona wanafunzi 30 darasani wakiwa wanajifunza kwa makini vile”					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Edmund Page</span><span class="testimonial-title">Mradi wa Xavier</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        “Tulivyoanzisha darasa la Kitkit, Kulikuwa na watoro wengi sana. Ila kadri tulivyoendelea, watoto wengi sana walijisikia kuhamasika kuendelea kuja shuleni kila siku”
                      </div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Paul Nadia</span><span class="testimonial-title">Mradi wa Xavier</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        “Toka hapo Nashim amekuwa mwanachama hai wa darasa la Kitkit…. Kitkit Shule imemhamasisha kuja shuleni kila siku”					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-title">Taarifa ya Xavier</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="content">
                  <div class="testimonial">
                    <div class="testimonial-content">
                      <div class="testimonial-text">
                        “Nilishangazwa. Watoto wameonyesha kuwa wana uwezo wa kiasili wa kuchunguza wenyewe”					</div>
                    </div>
                    <div class="testimonial-footer">
                      <cite class="testimonial-cite"><span class="testimonial-name">Heeju</span><span class="testimonial-title">Mwalimu</span></cite>			</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../../components/swheader'
import footer from '../../components/swfooter'
import toTop from '../../components/toTop'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "swImpact",
  head: {
    title: {
      inner: 'Impact',
      separator: '-',
      complement: 'Swahili Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Impact' },
      { name: 'twitter:image', content: require('../../assets/img/common/rwandamap.png')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Impact - Swahili Kitkit School' },
      { property: 'og:description', content: 'Athari Yetu Matokeo yenye nguvu, ATHARI inayoonekana Kitkit Shule inabadili kujifunza na kutoa matokeo yenye nguvu kwa watoto, bila kujali uwezo wao wa awali na upatikanaji wa rasilimali. Tangu Kitkit iundwe mwaka 2016, mashirika tunayoshirikiana nayo yamewezesha athari yetu inayokua, kuifikisha Kitkit Shule kwa watoto na pia kwa watoto walio katika jamii ngumu kufikika duniani […]' },
      { property: 'og:site_name', content: 'Swahili Kitkit School' },
      { property: 'og:image', content: require('../../assets/img/common/impact_bg3.jpeg') },
      { property: 'og:url', content: 'http://kitkitschool.com/impact/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/impact/' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  data() {
    return {
      thumbsSwiper: null,
      swiperOption: {
        loop: true,
        autoplay : {
          delay : 3000,
          disableOnInteraction : false,
        },
        slidesPerView: 1,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          999: {
            slidesPerView: 2,
          }
        },
        on: {
          init : function () {
            // console.log('swiper 초기화');
          },
          activeIndexChange: function () {
            // console.log(this.realIndex);
          }
        },
      },
    };
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    handleScroll () {
      // var scrollHeight = document.body.scrollHeight;
      // var maxRotate = -8.25;
      // var rotateVal = -8.943 + ((document.querySelector('html').scrollTop * maxRotate) / scrollHeight) * 35;
      // var rotateImage = document.getElementById("impactRotateImage");
      // rotateImage.style.transform = 'rotateZ('+ rotateVal +'deg)';
    }
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
    Swiper,
    SwiperSlide
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.impact-wrap {
  padding-top: 76px;
  overflow: hidden;

  .inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  .section-row {
    display: flex;
  }

  img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  p {
    margin-bottom: 20px;
    line-height: 39.6px
  }

  b, strong {
    font-weight: 600;
  }

  .btn-wrapper {
    display: flex;
    padding-bottom: 25px;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      font-family: "Lato", Lato, Open sans;
      font-size: 2rem;
      font-weight: 900;
      fill: #4f3c33;
      color: #4f3c33;
      background-color: #ffffff;
      border-style: solid;
      border-width: 3px 3px 3px 3px;
      border-color: rgba(255, 255, 255, 0);
      border-radius: 45px 45px 45px 45px;
      padding: 15px 100px 15px 100px;
      transition: all 0.3s ease;

      i {
        margin-right: 18px;
      }

      &:hover {
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0);
        border-color: #ffffff;
      }
    }
  }

  .font-bold {
    font-weight: 600;
  }

  .dotted-list {
    padding-left: 10px;

    li {
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 22px;
      font-weight: 400;
      line-height: 44px;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-heading {
    background-color: #ffe300;
    padding-top: 10px;

    .section-row {
      .section-col {
        width: 50%;

        &:first-child {
          display: flex;
          padding: 0 10% 0 0;
          align-items: center;
        }

        &:nth-child(2) {
          padding: 10px;
        }

        .title {
          color: #000000;
          font-family: "Ubuntu", Lato, Open sans;
          font-size: 6rem;
          font-weight: 900;
          line-height: 1.2em;
        }

        .img {
          transform: rotateZ(-8.943deg);
        }

        img {
          max-width: 85%;
          border-style: solid;
          border-width: 10px 10px 10px 10px;
          border-color: #ffffff;
          vertical-align: middle;
          display: inline-block;
          height: auto;
        }
      }
    }
  }

  .section-tangible-impact {
    padding: 100px 0 80px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin-top: -12px;
      margin-bottom: 20px;
    }

    p {
      color: #333;
      font-family: "Lato", Lato, Open sans;
      font-size: 22px;
      line-height: 39.6px;
    }

    .section-row {
      padding-top: 35px;

      .section-col {
        width: 50%;

        &:first-child {
          padding-right: 30px;
        }

        &:nth-child(2) {
          padding-left: 50px;

          p {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.4em;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .section-global-learning {
    position: relative;
    background-color: #3f7f25;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0;

    .heading-title-wrap {
      position: relative;

      .heading-title {
        display: inline-block;
        color: #ffffff;
        font-family: "Ubuntu", Lato, Open sans;
        font-size: 3.8rem;
        font-weight: 400;
        line-height: 42px;
      }
    }

    .desc {
      padding-top: 40px;
      color: #ffffff;
      font-family: "Lato", Lato, Open sans;
      font-size: 22px;
      margin-bottom: 40px;
      line-height: 39.6px;
    }

    .video-wrap {
      .video {
        position: relative;
        padding-bottom: 56.25%;
      }

      iframe {
        position: absolute;
        max-width: 100%;
        width: 100%;
        margin: 0;
        line-height: 1;
        border: none;
        top: 0;
        left: 0;
      }
    }

    .btn-wrap {
      text-align: center;
      margin-top: 20px;

      .btn {
        display: inline-block;
        line-height: 1;
        font-family: "Lato", Sans-serif;
        font-size: 2rem;
        font-weight: 900;
        text-transform: uppercase;
        fill: #333333;
        color: #333333;
        background-color: #ffc300;
        border-style: solid;
        border-width: 3px 3px 3px 3px;
        border-color: rgba(255,255,255,0);
        border-radius: 45px 45px 45px 45px;
        padding: 15px 130px 15px 130px;

        &:hover {
          color: #ffffff;
          background-color: rgba(0,0,0,0);
          border-color: #ffffff;
        }
      }
    }

    .section-row {
      .section-col {
        &:first-child {
          width: 40%;
          padding-right: 30px;
        }

        &:nth-child(2) {
          width: 60%;
          padding-left: 30px;

          .desc {
            padding-top: 0;
            line-height: 36.3px;
            margin-top: -5px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .banner-wrap {
      width: 100%;
      background-image: url(~@/assets/img/en/Impact/img-download-btn.png);
      background-position: center right;
      background-repeat: no-repeat;
      background-size: cover;
      transition: background 0.4s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

      &:hover {
        background-position: center center;
      }

      .icon-box-wrapper {
        display: flex;
        padding: 28px 20px;

        .icon-box-icon {
          padding-left: 10px;
          text-align: left;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;

          .icon {
            margin-right: 20px;
            fill: #FFFFFF;
            color: #FFFFFF;
            border-color: #FFFFFF;
            font-size: 50px;
            display: inline-block;
            line-height: 1;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            text-align: center;

            svg {
              width: 1em;
              height: 1em;
              position: relative;
              display: block;
            }
          }
        }

        .icon-box-content {
          .icon-box-title {
            font-family: "Lato", Lato, Open sans;
            font-weight: 700;
            line-height: 1em;
            color: #EC7015;
            margin-bottom: 10px;
          }

          .icon-box-description {
            color: #FFFFFF;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
            line-height: 1.2em;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .section-featured-field-tests {
    padding: 100px 0 80px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin-bottom: 20px;
      margin-top: -12px;
    }

    .section-row {
      padding-top: 35px;

      .section-col {
        &:first-child {
          width: 59.982%;
          padding-right: 30px;

          p {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
            line-height: 39.6px;
          }
        }

        &:nth-child(2) {
          width: 40.018%;
          padding-left: 50px;

          .column-wrap {
            .widget-wrap {
              .image-box {
                display: flex;
                margin-bottom: 10px;
                margin-top: -10px;

                .image-box-wrapper {
                  display: flex;

                  .image-box-img {
                    width: 25%;
                    margin-right: 15px;
                    margin-bottom: 0;

                    a {
                      display: block;
                    }
                  }

                  .image-box-content {
                    display: flex;
                    width: 100%;
                    align-items: center;

                    .image-box-title {
                      color: #333333;
                      font-family: "Lato", Lato, Open sans;
                      font-size: 24px;
                      font-weight: 900;
                    }
                  }
                }


                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .section-bangladesh {
    background-color: #4f3c33;
    padding: 120px 0 100px;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .title {
      color: #ffffff;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      line-height: 38px;
      margin-bottom: 20px;
      margin-top: -12px;
      text-align: right;
      padding: 0px 72px 0px 0px;
    }

    .section-row {
      .section-col {
        &:first-child {
          width: 50%;
          padding: 0 50px 0 0;

          .heading-img {
            margin: -213px 0px 60px 0px;

            img {
              max-width: 90%;
            }
          }

          .sub-img {
            padding-top: 130px;

            img {
              max-width: 100%;
              border-style: solid;
              border-width: 7px 7px 7px 7px;
              border-color: #ffffff;
            }
          }
        }

        &:nth-child(2) {
          width: 50%;

          .desc-box {
            padding-top: 35px;
            padding-bottom: 20px;
            padding-left: 30px;

            p {
              color: #ffffff;
              font-family: "Lato", Lato, Open sans;
              font-size: 22px;
              line-height: 39.6px;
            }
          }
        }
      }
    }
  }

  .section-tanzania {
    background-color: #0394e5;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 120px 0 100px 0;

    .heading-title {
      color: #ffffff;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin-top: -12px;
      margin-bottom: 20px;
    }

    .section-row {
      margin-top: 35px;

      .section-col {
        width: 50%;
        padding-right: 30px;

        .desc-box {
          padding-bottom: 20px;

          p {
            color: #ffffff;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
          }
        }

        &:nth-child(2) {
          padding: 0px 0px 0px 50px;
          margin-top: -35px;

          .heading-img {
            text-align: right;
            margin: -220px 0px 20px 0px;

            img {
              max-width: 70%;
            }
          }

          .sub-img {
            padding-top: 120px;

            img {
              max-width: 100%;
              border-style: solid;
              border-width: 7px 7px 7px 7px;
              border-color: #ffffff;
            }
          }
        }

        .btn-wrapper {
          a {
            fill: #0394e5;
            color: #0394e5;

            &:hover {
              color: #ffffff;
              background-color: rgba(0, 0, 0, 0);
              border-color: #ffffff;
            }
          }
        }
      }
    }
  }

  .section-kenya {
    background-color: #ffe300;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 120px 0px 100px 0px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin: -12px 0px 20px 0px;
      text-align: right;
    }

    .section-row {
      .section-col {
        width: 50%;

        &:first-child {
          margin: -0 0px 0px 0px;
          padding: 0 50px 0px 0px;
        }

        .heading-img {
          margin: -205px 0px 10px 0px;

          img {
            max-width: 70%;
          }
        }

        .sub-img {
          padding: 50px 0px 0px 0px;

          img {
            max-width: 100%;
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            border-color: #ffffff;
          }
        }

        .desc-box {
          padding: 45px 0px 20px 30px;

          p {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
          }
        }

        .btn-wrapper {
          a {
            fill: #ffe300;
            color: #ffe300;
            background-color: #333333;

            &:hover {
              color: #333333;
              background-color: rgba(0, 0, 0, 0);
              border-color: #333333;
            }
          }
        }
      }
    }
  }

  .section-rwanda {
    background-color: #3f7f25;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 120px 0px 100px 0px;

    .title {
      color: #ffffff;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 3.8rem;
      font-weight: 400;
      margin: -12px 0px 20px 0px;
    }

    .section-row {
      .section-col {
        width: 50%;

        &:nth-child(2) {
          margin: -35px 0px 0px 0px;
          padding: 0px 0px 0px 50px;
          text-align: right;
        }

        .heading-img {
          margin: -190px 0px 15px 0px;

          img {
            max-width: 70%;
          }
        }

        .sub-img {
          margin: 0px 0px 0px 0px;
          padding: 10px 0px 0px 0px;

          img {
            max-width: 100%;
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            border-color: #ffffff;
          }
        }

        .desc-box {
          padding: 30px 30px 20px 0px;

          p {
            color: #ffffff;
            font-family: "Lato", Lato, Open sans;
            font-size: 22px;
          }
        }

        .btn-wrapper {
          a {
            fill: #3f7f25;
            color: #3f7f25;
            background-color: #ffffff;

            &:hover {
              color: #ffffff;
              background-color: rgba(0, 0, 0, 0);
              border-color: #ffffff;
            }
          }
        }
      }
    }
  }

  .section-impact-summary {
    padding: 70px 0px 70px 0px;
    text-align: center;

    .inner {
      padding: 10px;
    }

    .heading-title {
      margin-bottom: 20px;
      line-height: 40px;
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 40px;
      font-weight: 500;
    }

    .btn-wrapper {
      a {
        fill: #ffffff;
        color: #ffffff;
        background-color: #ec7015;
        padding: 15px 50px 15px 50px;

        &:hover {
          color: #ec7015;
          background-color: rgba(0, 0, 0, 0);
          border-color: #ec7015;
        }
      }
    }
  }
}
@media (max-width: 1204px) {
  .impact-wrap .section-global-learning .btn-wrap .btn {
    padding: 15px 50px 15px 50px;
  }
}

@media (max-width: 999px) {
  .impact-wrap {
    .inner {
      max-width: 1025px;
    }

    p {
      line-height: 32.4px;
    }

    .btn-wrapper {
      a {
        padding: 15px 50px 15px 50px;
        align-items: flex-start;

        i {
        }

        span {
          display: inline-block;
          line-height: 20px;
        }
      }
    }

    .dotted-list {
      li {
        font-size: 18px;
        line-height: 1.6em;
      }
    }

    .section-heading {
      padding: 10px 25px 10px 25px;

      .section-row {
        .section-col {
          .title {
            font-size: 35px;
          }
        }
      }
    }

    .section-tangible-impact {
      padding: 100px 25px 100px 25px;

      .title {
        font-size: 40px;
      }

      p {
        font-size: 18px;
        line-height: 32.4px;
      }

      .section-row {
        .section-col {
          &:nth-child(2) {
            p {
              line-height: 32.4px;
            }
          }
        }
      }
    }

    .section-global-learning {
      padding: 100px 25px 70px 25px;

      .heading-title-wrap {
        &::after {
          content: none;
        }
      }

      .desc {
        font-size: 18px;
        line-height: 32.4px;
      }

      .section-row {
        .section-col {
          &:first-child {
            width: 45%;
            padding: 0px 20px 0px 0px;
          }

          &:nth-child(2) {
            width: 55%;
            padding: 0;

            .desc {
              font-size: 18px;
              line-height: 32.4px;
            }
          }
        }
      }

      .banner-wrap {
        .icon-box-wrapper {
          padding: 12px 10px 12px 10px;

          .icon-box-icon {
            .icon {
              font-size: 36px;
            }
          }

          .icon-box-content {
            .icon-box-title {
              font-size: 16px;
              margin-bottom: 4px;
            }

            .icon-box-description {
              font-size: 16px;
            }
          }
        }
      }
    }

    .section-featured-field-tests {
      padding: 100px 25px 60px 25px;

      .section-row {
        .section-col {
          &:first-child {
            p {
              font-size: 18px;
              line-height: 32.4px;
            }
          }
        }
      }
    }

    .section-bangladesh {
      padding: 100px 25px 60px 25px;

      .title {
        font-size: 32px;
      }

      .section-row {
        .section-col {
          &:first-child {

            .heading-img {
              margin: -180px 0px 20px 0px;

              img {
                max-width: 85%;
              }
            }

            .sub-img {
              padding: 30px 0px 0px 0px;

              img {
                max-width: 100%;
                border-width: 5px 5px 5px 5px;
              }
            }
          }

          &:nth-child(2) {
            width: 50%;

            .desc-box {
              padding-left: 0;

              p {
                font-size: 16px;
                line-height: 1.6em;
              }
            }
          }
        }
      }
    }

    .section-tanzania {
      padding: 100px 25px 60px 25px;

      .heading-title {
        font-size: 32px;
      }

      .section-row {
        .section-col {
          width: 50%;
          padding-right: 0;

          .desc-box {
            padding: 20px 0px 0px 0px;

            p {
              font-size: 16px;
              line-height: 1.6em;
            }
          }

          &:nth-child(2) {
            .heading-img {
              margin: -170px 0px 0px 0px;

              img {
                max-width: 85%;
              }
            }

            .sub-img {
              img {
                max-width: 95%;
                border-width: 5px 5px 5px 5px;
              }
            }
          }
        }
      }
    }

    .section-kenya {
      padding: 100px 25px 60px 25px;

      .title {
        font-size: 32px;
      }

      .section-row {
        .section-col {


          .heading-img {
            margin: -170px 0px 10px 0px;

            img {
              max-width: 85%;
            }
          }

          .sub-img {

            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding-left: 0;

            p {
              font-size: 16px;
              line-height: 1.6em;
            }
          }
        }
      }
    }

    .section-rwanda {
      padding: 100px 25px 60px 25px;

      .title {
        font-size: 32px;
      }

      .section-row {
        .section-col {
          .heading-img {
            margin: -150px 0px 85px 0px;

            img {
              max-width: 85%;
            }
          }

          .sub-img {
            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding-right: 0;

            p {
              font-size: 16px;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .impact-wrap {
    padding-top: 90px !important;

    .inner {
      max-width: 768px;
    }

    .section-row {
      flex-direction: column;

      .section-col {
        width: 100%;
      }
    }

    p {
      line-height: 28.8px;
    }

    .btn-wrapper {
      a {
        padding: 12px 20px 12px 20px;
      }
    }

    .section-heading {
      padding: 20px 20px 20px 20px;

      .section-row {
        .section-col {
          width: 100%;

          img {
            max-width: 100%;
          }
        }
      }
    }

    .section-tangible-impact {
      padding: 50px 20px 30px 20px;

      .title {
        font-size: 32px;
        line-height: 32px;
      }

      .section-row {

        .section-col {
          width: 100%;

          &:first-child {
            padding-right: 0;
            margin-bottom: 20px;

            p {
              margin-bottom: 30px;
            }
          }

          &:nth-child(2) {
            padding-left: 0;

            p {
            }

            .dotted-list {
              margin: 15px 0 15px 20px;
            }
          }

          .btn-wrap {
            .btn {
              padding: 15px 50px 15px 50px;
            }
          }
        }
      }
    }

    .section-global-learning {
      padding: 70px 20px 70px 20px;

      .heading-title-wrap {
        .heading-title {
          font-size: 32px;
        }
      }

      .desc {
        padding-top: 30px;
      }

      .section-row {
        .section-col {
          &:first-child {
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
            width: 100%;
          }

          &:nth-child(2) {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
            width: 100%;
          }
        }
      }

      .banner-wrap {
        background-image: url('~@/assets/img/en/Impact/img-download-bg-m.png');
        background-position: center center;
        background-size: cover;

        .icon-box-wrapper {
          flex-direction: column;

          .icon-box-icon {
            padding-left: 0;
            margin-bottom: 5px;
          }
        }
      }
    }

    .section-featured-field-tests {
      padding: 70px 20px 30px 20px;

      .title {
        font-size: 32px;
        line-height: 36px;
      }

      .section-row {
        padding-top: 10px;

        .section-col {
          &:first-child {
            padding-right: 0;
            width: 100%;
          }

          &:nth-child(2) {
            display: none;
            width: 100%;
          }
        }
      }
    }

    .section-bangladesh {
      padding: 70px 20px 50px 20px;

      .title {
        font-size: 30px;
        text-align: left;
      }

      .section-row {
        .section-col {
          &:first-child {
            width: 100%;
            padding: 0;

            .heading-img {
              margin: 0;

              img {
              }
            }

            .sub-img {
              padding: 0;
              margin: 0;
            }
          }

          &:nth-child(2) {
            width: 100%;

            .desc-box {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .section-tanzania {
      padding: 70px 20px 40px 20px;

      .heading-title {
        font-size: 30px;
        line-height: 30px;
        padding-bottom: 30px;
      }

      .section-row {
        .section-col {
          .desc-box {
            margin-bottom: 40px;

            p {
              font-size: 18px;
            }
          }

          &:first-child {
            width: 100%;
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
            padding: 0;
          }

          &:nth-child(2) {
            width: 100%;
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
            padding: 0;

            .heading-img {
              padding: 0;
              margin: 0;
            }

            .sub-img {
              padding-top: 20px;

              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    .section-kenya {
      padding: 70px 20px 50px 20px;

      .title {
        font-size: 30px;
        line-height: 30px;
        text-align: left;
      }

      .section-row {
        .section-col {

          &:first-child {
            width: 100%;
            padding: 0;
          }

          &:nth-child(2) {
            width: 100%;

          }

          .heading-img {
            margin: 0;
            padding: 0;

            img {
            }
          }

          .sub-img {
            padding: 0;
            margin-top: 20px;

            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding: 30px 0px 20px 0px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }

    .section-rwanda {
      padding: 70px 20px 50px 20px;

      .title {
        font-size: 30px;
        line-height: 30px;
        text-align: left;
        margin-bottom: 60px;
      }

      .section-row {
        .section-col {

          &:first-child {
            width: 100%;
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
            padding: 0;
          }

          &:nth-child(2) {
            width: 100%;
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
            padding: 0;
          }

          .heading-img {
            margin: 0;
            padding: 0;

            img {
            }
          }

          .sub-img {
            padding: 0;
            margin-top: 20px;

            img {
              max-width: 100%;
              border-width: 5px 5px 5px 5px;
            }
          }

          .desc-box {
            padding: 30px 0px 20px 0px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }

    .section-impact-summary {

      .inner {
      }

      .heading-title {

      }

      .btn-wrapper {
        padding-bottom: 0;

        a {


          &:hover {

          }
        }
      }
    }
  }
}
</style>
