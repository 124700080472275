<template>
  <div>
    <Header title="Press"/>
    <div class="press-wrap">
      <div class="section-heading">
        <div class="inner">
          <h3>PRESS</h3>
          <p>Featured News |&nbsp; Resources &amp; Press Releases&nbsp; |&nbsp; Videos&nbsp; |&nbsp; Photos</p>
        </div>
      </div>
      <div class="section-featured-news">
        <div class="inner">
          <div class="box">
            <h2>Featured News</h2>
            <div class="section-row">
              <div class="section-col">
                <div class="card-box">
                  <div class="item">
                    <div class="item-inner">
                      <a class="title" href="https://medium.com/airbel/putting-learning-in-the-hands-of-children-9cea857e01d5" target="_blank">Putting Education in the Hands of Children</a>
                      <div class="spacer-49"></div>
                      <div class="bottom-area">
                        <p>11. 14.<br>2019</p>
                        <a href="https://medium.com/airbel/putting-learning-in-the-hands-of-children-9cea857e01d5"
                           target="_blank" class="logo-img">
                          <img src="~@/assets/img/en/Press/airbel-logo-black-tagline.jpeg" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="https://www.forbes.com/sites/peterhigh/2019/05/16/meet-the-winners-of-elon-musks-15-million-xprize-to-end-global-illiteracy"
                         target="_blank">Meet The Winners Of Elon Musk's $15 Million XPRIZE To End Global Illiteracy</a>
                      <div class="spacer-30"></div>
                      <div class="bottom-area">
                        <p>5. 16.<br>2019</p>
                        <a href="https://www.forbes.com/sites/peterhigh/2019/05/16/meet-the-winners-of-elon-musks-15-million-xprize-to-end-global-illiteracy"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/logo_news_forbes@4x.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="https://www.edsurge.com/news/2019-05-15-the-5-year-15-million-global-learning-xprize-competition-is-over-here-s-who-won"
                         target="_blank">The 5-Year, $15 Million Global Learning XPRIZE Competition Is Over. Here’s Who
                        Won.</a>
                      <div class="spacer-15"></div>
                      <div class="bottom-area">
                        <p>5. 15.<br>2019</p>
                        <a href="https://www.edsurge.com/news/2019-05-15-the-5-year-15-million-global-learning-xprize-competition-is-over-here-s-who-won"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/logo_news_edsurge@4x.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="http://www.xavierproject.org/xavier-project-partners-with-enuma-to-launch-kitkit-school/"
                         target="_blank">Xavier Project partners with Enuma to launch Kitkit School</a>
                      <div class="spacer-21"></div>
                      <div class="bottom-area">
                        <p>1. 19.<br>2018</p>
                        <a href="http://www.xavierproject.org/xavier-project-partners-with-enuma-to-launch-kitkit-school/"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/logo_xavier@1.5x.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="https://www.edsurge.com/news/2017-09-18-here-are-the-5-finalists-for-the-15m-xprize-global-learning-challenge"
                         target="_blank">Here Are the 5 Finalists for the $15M XPRIZE Global Learning Challenge</a>
                      <div class="spacer-35"></div>
                      <div class="bottom-area">
                        <p>1. 19.<br>2018</p>
                        <a href="https://www.edsurge.com/news/2017-09-18-here-are-the-5-finalists-for-the-15m-xprize-global-learning-challenge"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/logo_news_edsurge@4x.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-col">
                <div class="card-box">
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="https://www.educationnext.org/taking-tablet-learning-global-can-technology-eradicate-illiteracy-less-developed-countries/"
                         target="_blank">Taking Tablet Learning Global: <small>Can learning technology eradicate
                        illiteracy in less-developed countries?</small></a>
                      <div class="spacer-10"></div>
                      <div class="bottom-area">
                        <p>FALL.<br>2019</p>
                        <a href="https://www.educationnext.org/taking-tablet-learning-global-can-technology-eradicate-illiteracy-less-developed-countries/"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/ednextlogo-ob6fofxcwq4u0n7q4bm43a6k0jqcqn0c178ztg9kls.jpeg" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title" href="https://www.bbc.com/news/technology-48294792" target="_blank">Global
                        education X-Prize awards $10m</a>
                      <div class="spacer-15"></div>
                      <div class="bottom-area">
                        <p>5. 16.<br>2019</p>
                        <a href="https://www.bbc.com/news/technology-48294792" target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/bbc_news-ob6g92htnzm45fidd2ydlx7d2olio4u1rtkv1uws0s.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="https://techcrunch.com/2019/05/15/xprize-names-two-grand-prize-winners-in-15-million-global-learning-challenge/"
                         target="_blank">XPRIZE names two grand prize winners in $15 million Global Learning Challenge</a>
                      <div class="spacer-40"></div>
                      <div class="bottom-area">
                        <p>5. 15.<br>2019</p>
                        <a href="https://techcrunch.com/2019/05/15/xprize-names-two-grand-prize-winners-in-15-million-global-learning-challenge/"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/techcrunch_transparent.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="https://allchildrenreading.org/mothers-edtech-startup-has-vision-to-make-learning-accessible-for-deaf-children-around-the-world/%20"
                         target="_blank">Mother's EdTech startup casts vision to make learning
                        accessible for deaf children around the world</a>
                      <div class="bottom-area">
                        <p>9. 24.<br>2018</p>
                        <a href="https://allchildrenreading.org/mothers-edtech-startup-has-vision-to-make-learning-accessible-for-deaf-children-around-the-world/%20"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/logo_news_all@4x.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="item-inner">
                      <a class="title"
                         href="http://www.techforkorea.com/2017/01/25/kick-it-with-kitkit-school-an-ed-app-debuting-at-an-xprize-event/"
                         target="_blank">Kick It with KitKit School
                        – an Ed App Debuting at an XPRIZE Event</a>
                      <div class="spacer-41"></div>
                      <div class="bottom-area">
                        <p>1. 25.<br>2017</p>
                        <a href="http://www.techforkorea.com/2017/01/25/kick-it-with-kitkit-school-an-ed-app-debuting-at-an-xprize-event/"
                           target="_blank" class="no-lightbox">
                          <img src="~@/assets/img/en/Press/logo_news_techkorea@4x.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section-col">
                <div class="card-box-lg">
                  <div class="item resource">
                    <div class="item-inner">
                      <div class="title">Resources</div>
                      <ul class="download-list">
                        <li><a href="/doc/Impact-Brief-Update-XPRIZE-3.pdf" target="_blank"><i aria-hidden="true" class="far fa-file-pdf"></i><span>Kitkit School Global Learning XPRIZE <br>Field Test Data Report</span></a></li>
                        <li><a href="/doc/Kitkit-School-Impact-Summary-.pdf" target="_blank"><i aria-hidden="true" class="far fa-file-pdf"></i><span>Kitkit School Impact Summary</span></a></li>
                        <li><a href="/doc/kitkitschool_product_overview.pdf" target="_blank"><i aria-hidden="true" class="far fa-file-pdf"></i><span>Kitkit School  Product Overview</span></a></li>
                        <li><a href="/doc/Kitkit-Handbook_V3a.pdf" target="_blank"><i aria-hidden="true" class="far fa-file-pdf"></i><span>Kitkit School Design and Learning Document</span></a></li>
                        <li><a href="/doc/Kitkit_CaseStudy_Mtwara_.pdf" target="_blank"><i aria-hidden="true" class="far fa-file-pdf"></i><span>Mtwara Case Study</span></a></li>
                        <li><a href="/doc/Kitkit-School-Kagina-Rwanda-Case-Study.pdf" target="_blank"><i aria-hidden="true" class="far fa-file-pdf"></i><span>Rwanda Case Study</span></a></li>
                        <li><a href="/doc/Xavier-Project_CaseStudy.pdf" target="_blank"><i aria-hidden="true" class="far fa-file-pdf"></i><span>Xavier Case Study</span></a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="item press-releases">
                    <div class="item-inner">
                      <div class="title">Press Releases</div>
                      <div class="content">
                        <p><a href="https://www.xprize.org/articles/global-learning-xprize-two-grand-prize-winners"><span style="color: #ffffff;">$15M Global Learning XPRIZE Culminates With Two Grand Prize Winners – May 15, 2019</span></a></p>
                        <p><a href="http://x-prize.rachelsadd.com/files/2017/01/kitkit-school-enuma-012017.pdf" target="_blank" rel="noopener">Enuma Launches Kitkit School Tablet-Based Learning Application in Global Learning XPRIZE Competition – Jan 1, 2018</a></p>
                      </div>
                    </div>
                  </div>
                  <div class="item video">
                    <div class="item-inner">
                      <div class="title">Video</div>
                      <div class="video-wrap">
                        <div class="video">
                          <iframe class="elementor-video-iframe" allowfullscreen="" title="youtube Video Player" src="https://www.youtube.com/embed/ITfhUl8Qs8Q?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
                        </div>
                        <div class="desc">
                          <a class="elementor-icon elementor-animation-" href="https://www.youtube.com/playlist?list=PLKGu8D2CSCIVFKZTP3-_BfOV35MP1o2Jc" target="_blank">
                            <i class="fa fa-youtube-play" aria-hidden="true"></i>
                          </a>
                          <a href="https://www.youtube.com/playlist?list=PLKGu8D2CSCIVFKZTP3-_BfOV35MP1o2Jc" target="_blank">More Kitkit School Videos</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <h2>Photos</h2>
            <CoolLightBox
                :items="items"
                :index="index"
                @close="index = null"
            >
            </CoolLightBox>
            <div class="gallery-wrap">
              <div
                  class="item"
                  v-for="(image, imageIndex) in items"
                  :key="imageIndex"
                  @click="index = imageIndex"
              >
                <img
                    class="img"
                    :src="image"
                    alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'
import CoolLightBox from 'vue-cool-lightbox'

export default {
  name: "Press",
  head: {
    title: {
      inner: 'Press',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Press' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Press - Kitkit School' },
      { property: 'og:description', content: 'PRESS Featured News |  Resources & Press Releases  |  Videos  |  Photos Featured News Photos' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/kitkitphoto12-768x576.jpeg') },
      { property: 'og:url', content: 'http://kitkitschool.com/press/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/press/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
    CoolLightBox
  },
  data: function () {
    return {
      items: [
        require(`../assets/img/en/Press/kitkitphoto12.jpg`),
        require(`../assets/img/en/Press/kitkitphoto13.jpg`),
        require(`../assets/img/en/Press/kitkitphoto16.jpg`),
        require(`../assets/img/en/Press/kitkitphoto9.jpg`),

        require(`../assets/img/en/Press/kitkitphoto3.jpg`),
        require(`../assets/img/en/Press/kitkitphoto14.jpg`),
        require(`../assets/img/en/Press/kitkitphoto4.jpg`),
        require(`../assets/img/en/Press/kitkitphoto6.jpg`),

        require(`../assets/img/en/Press/kitkitphoto8.jpg`),
        require(`../assets/img/en/Press/kitkitphoto11.jpg`),
        require(`../assets/img/en/Press/kitkitphoto7.jpg`),
        require(`../assets/img/en/Press/kitkitphoto10.jpg`),
      ],
      index: null,
    };
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';
@import "~vue-cool-lightbox/dist/vue-cool-lightbox.min.css";


.press-wrap {
  padding-top: 76px;

  b, strong {
    font-weight: 600;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: 600;
    margin: 0 0 20px;
    color: #333;
    line-height: 1.4;
  }

  .inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  .section-heading {
    background-color: #6d684e;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0;
    margin-bottom: 0;
    padding: 30px 0 10px 0;
    z-index: 1;

    .inner {
      padding: 10px;
    }

    h3 {
      color: #ffe000;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 6rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.2em;
    }

    p {
      margin: -5px 0 0 0;
      color: #dddddd;
      font-family: "Lato", Lato, Open sans;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 20px;
    }
  }

  .section-featured-news {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0 0 0;
    background-color: #ffe000;

    .inner {
      max-width: 1140px;
    }

    .box {
      &:nth-child(2) {
        padding: 80px 0 100px;

        h2 {
          padding: 0;
        }
      }
    }

    h2 {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 48px;
      font-weight: 600;
      line-height: 1.2em;
      letter-spacing: -0.2px;
      padding: 10px;
    }

    .section-row {
      display: flex;

      .section-col {
        padding: 10px;

        &:first-child {
          width: 26%;

          .card-box {
            .item {
              &:nth-child(2) {
                img {
                  max-width: 85%;
                }
              }
              &:nth-child(3) {
                img {
                  max-width: 90%;
                }
              }
              &:nth-child(4) {
                img {
                  max-width: 80%;
                }
              }
              &:nth-child(5) {
                img {
                  max-width: 80%;
                }
              }
            }
          }
        }

        &:nth-child(2) {
          width: 25.666%;

          .card-box {
            .item {
              &:nth-child(1) {
                img {
                  max-width: 90%;
                }
              }
              &:nth-child(2) {
                img {
                  max-width: 60%;
                }
              }
              &:nth-child(3) {
                img {
                  max-width: 75%;
                }
              }
              &:nth-child(4) {
                img {
                  max-width: 40%;
                }
              }
              &:nth-child(5) {
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }

        &:nth-child(3) {
          width: 48%;
          padding: 0;
        }
      }
    }

    .card-box {
      .item {
        .item-inner {
          max-width: 500px;
          display: flex;
          flex-direction: column;
          position: relative;
          transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
          margin: 0 auto 20px;
          background-color: #ffffff;
          padding-bottom: 20px;

          &:hover {
            box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          margin-top: 20px;
          margin-bottom: 10px;
          padding: 0 20px 0 20px;
          color: #333333;
          font-family: "Lato", Lato, Open sans;
          font-size: 18px;
          font-weight: 600;
          line-height: 1.5em;
        }

        .spacer-49 {
          height: 49px;
          margin-bottom: 20px;
        }

        .spacer-30 {
          height: 30px;
          margin-bottom: 20px;
        }

        .spacer-41 {
          height: 41px;
          margin-bottom: 20px;
        }

        .spacer-40 {
          height: 40px;
          margin-bottom: 20px;
        }

        .spacer-35 {
          height: 30px;
          margin-bottom: 20px;
        }

        .spacer-15 {
          height: 15px;
          margin-bottom: 20px;
        }

        .spacer-10 {
          height: 10px;
          margin-bottom: 20px;
        }

        .spacer-21 {
          height: 21px;
          margin-bottom: 20px;
        }

        .bottom-area {
          display: flex;

          > p {
            display: flex;
            align-items: flex-end;
            width: 26%;
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.2em;
            padding-left: 20px;
            margin-bottom: 0;
          }
          > a {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 74%;
            text-align: right;
            margin-right: 20px;

            img {
              max-width: 60%;
              height: auto;
            }
          }
        }
      }
    }

    .card-box-lg {
      padding: 10px 10px 10px 80px;

      .item {
        background-color: #6d684e;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        margin-top: 0;
        padding: 0 0 20px 0;
        max-width: 500px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .title {
          margin-bottom: 0;
          padding: 20px 20px 0;
          color: #ffe300;
          font-family: "Ubuntu", Lato, Open sans;
          font-size: 42px;
          font-weight: 600;
          line-height: 63px;
        }

        .download-list {
          padding: 25px 25px 10px;
          position: relative;
          width: 100%;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-line-pack: start;
          align-content: flex-start;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          li {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 22px;

            &:hover {
              a {
                color: #fff;
                text-decoration: underline;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            a {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              text-align: left;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -ms-flex-direction: row;
              flex-direction: row;
              justify-content: flex-start;

              i {
                margin-right: 15px;
                font-size: 20px;
                fill: #ffffff;
                color: #ffffff;
                border-color: #ffffff;
                width: 1em;
                height: 1em;
                position: relative;
                display: block;
              }

              span {
                display: inline-block;
                font-family: "Lato", Lato, Open sans;
                line-height: 23.5px;
                font-size: 18px;
                font-weight: 600;
                color: #ffffff;
              }
            }
          }
        }

        &.press-releases {
          .content {
            margin-top: 20px;
            margin-bottom: 10px;

            p {
              padding: 0 25px;
              font-family: "Lato", Lato, Open sans;
              font-size: 18px;
              font-weight: 400;
              line-height: 1.4em;
              color: #fff;

              a {
                color: #fff;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .video-wrap {
          padding: 0 20px 20px;
          margin-top: 20px;

          .video {
            position: relative;
            padding-bottom: 56.25%;
            margin-bottom: 20px;

            iframe {
              position: absolute;
              left: 0;
              top: 0;
              max-width: 100%;
              width: 100%;
              margin: 0;
              line-height: 1;
              border: none;
              height: 100%;
            }
          }

          .desc {
            padding-top: 5px;
            display: flex;
            align-items: center;

            i {
              margin-right: 15px;
              fill: #ffffff;
              color: #ffffff;
              border-color: #ffffff;
              font-size: 25px;
            }

            a {
              &:nth-child(2) {
                font-family: "Lato", Lato, Open sans;
                font-size: 18px;
                color: #ffffff;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .gallery-wrap {
      .item {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        width: 100%;
        max-width: 25%;
        padding: 0 15px 15px 0;
        margin: 0;
        cursor: pointer;

        .img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .press-wrap {
    .inner {
      max-width: 1025px;
    }

    .section-heading {
      .inner {
        max-width: 1025px;
      }

      h3 {
        font-size: 40px;
      }
    }

    .section-featured-news {
      margin-top: 0;
      margin-bottom: 0;
      padding: 50px 10px 0 10px;

      .inner {
        max-width: 1025px;
      }

      .box {
        &:nth-child(2) {
          padding: 80px 10px 80px 10px;
        }
      }

      h2 {
        font-size: 30px;
      }

      .section-row {
        flex-wrap: wrap;

        .section-col {
          &:first-child {
            width: 50%;
          }

          &:nth-child(2) {
            width: 50%;
          }

          &:nth-child(3) {
            width: 100%;
          }
        }
      }

      .card-box {
        .item {
          .spacer-49 {
            height: 73px;
          }

          .spacer-30 {
            height: 73px;
          }

          .spacer-41 {
            height: 73px;
          }

          .spacer-40 {
            height: 73px;
          }

          .spacer-35 {
            height: 73px;
          }

          .spacer-15 {
            height: 73px;
          }

          .spacer-10 {
            height: 73px;
          }

          .spacer-21 {
            height: 73px;
          }
        }
      }

      .card-box-lg {
        margin: 0 10px 0 10px;
        padding: 0;

        .item {
          max-width: none;

          .item-inner {
            max-width: 500px;
            margin: 0 auto;
          }

          .title {
            font-size: 28px;
            line-height: 42px;
          }

          &.video {
            .title {
              font-size: 38px;
            }
          }

          .video-wrap {
            margin: 20px -15px 0 0;
          }
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .press-wrap {
    padding-top: 92px !important;

    .inner {
      max-width: 768px;
    }

    .section-heading {
      .inner {
        max-width: 768px;
      }

      h3 {
        margin-bottom: 40px;
      }
    }

    .section-featured-news {
      margin-top: 0;
      margin-bottom: 0;
      padding: 50px 10px 0 10px;

      .inner {
        max-width: 768px;
      }

      .box {
        &:nth-child(2) {
          padding: 80px 10px 80px 10px;
        }
      }

      .section-row {
        .section-col {
          &:first-child {
            width: 100%;
          }

          &:nth-child(2) {
            width: 100%;
          }
        }
      }

      .card-box {
        .item {
          width: 100%;
          background-color: #fff;

          .item-inner {
            background-color: transparent;
          }

          &:hover {
            box-shadow: 0 0 20px 0 rgba(0,0,0,.3);
          }

          .spacer-49 {
            height: 25px;
          }

          .spacer-30 {
            height: 25px;
          }

          .spacer-41 {
            height: 25px;
          }

          .spacer-40 {
            height: 25px;
          }

          .spacer-35 {
            height: 25px;
          }

          .spacer-15 {
            height: 25px;
          }

          .spacer-10 {
            height: 25px;
          }

          .spacer-21 {
            height: 25px;
          }

          .bottom-area {

            > p {
              width: 20%;
            }
            > a {
              width: 80%;
            }
          }
        }
      }

      .card-box-lg {
        .item {
          .title {
            font-size: 30px;
            line-height: 45px;
          }

          &.video {
            .title {
              font-size: 30px;
              line-height: 34px;
              margin-bottom: 20px;
            }
          }

          .download-list {
            li {
              a {
                span {
                  font-size: 16px;
                }
              }
            }
          }

          .video-wrap {
            margin: 0;

            .desc {
              flex-direction: column;

              i {
                margin-bottom: 15px;
              }
            }
          }
        }
      }

      .gallery-wrap {
        .item {
          max-width: calc(50% - 7.5px);
          padding-right: 0;

          &:nth-child(2n-1) {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>

