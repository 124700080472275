<template>
  <div>
    <Header title="About"/>
    <div class="about-wrap">
      <!-- section -->
      <div class="section-heading">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <h3 class="title">About Us</h3>
            </div>
            <div class="section-col">
              <div class="img" id="rotateImage">
                <img src="~@/assets/img/en/About/EnumaGroupPhoto1600-2.jpeg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-info">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <div class="title">Building Foundational Skills for ALL Children</div>
              <div class="desc">
                <p>Team <strong>Kitkit<sup>®</sup></strong> School is a special project team of <strong>Enuma<sup>®</sup></strong>, Inc., a company whose mission is to build technologically exceptional learning tools that help struggling learners gain confidence and independence while building foundational skills. Co-founded in 2012 in Berkeley, California, with offices in Seoul and Beijing.</p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Leader in Digital Early-Learning Solutions</div>
              <div class="desc"><p>The Enuma, Inc. project team behind Kitkit School combines decades of collective experience in education, gaming, engineering, design, and international development with a shared commitment to promoting an inclusive and equitable quality education where all learners can succeed. Our diverse and seasoned staff of developers, designers, and educators has worked tirelessly and intentionally to create digital learning solutions that engage and empower.</p></div>
            </div>
            <div class="section-col">
              <div class="title">Awards & Recognitions for Enuma</div>
              <div class="dotted-list-wrap">
                <ul class="dotted-list">
                  <li>2019 <strong>Global Learning XPRIZE</strong> co-winner</li>
                  <li>2018 Sign On For<strong> Literacy Global Prize Competition</strong> Semi-Finalist</li>
                </ul>
                <ul class="dotted-list">
                  <li>2017 <strong>Global Learning XPRIZE</strong> Finalist</li>
                </ul>
                <ul class="dotted-list">
                  <li>2016 <strong>Google Play Korea Best Apps</strong> of the Year, Family</li>
                </ul>
                <ul class="dotted-list">
                  <li>2016 <strong>SIIA CODiE Awards</strong> Finalist</li>
                </ul>
                <ul class="dotted-list">
                  <li>Winner of a 2015 <strong>Parents’ Choice Gold Award</strong></li>
                </ul>
                <ul class="dotted-list">
                  <li>Best Design Award Winner at <strong>Launch Education &amp; Kids Conference</strong>, 2013</li>
                </ul>
                <ul class="dotted-list">
                  <li>Five-Star Quality Rated on <strong>Common Sense Media</strong></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Technical Excellence & a Truly User-Centered Design</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Team Kitkit School blends its technical excellence with a passion for meeting the unique needs of all children. By incorporating high-quality game design standards and hands-on research in early childhood education, neuroscience, and learning theory, Kitkit School has been engineered to support successful learning outcomes for all children, meeting them wherever they are and encouraging long-term engagement and motivation to learn. </span></p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Driven by Data</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Our team employs leading research in neuroscience and special education, as well as international best-practices in learning and pedagogy. The team also securely collects, processes, and synthesizes large amounts of non-private user-data from our own applications, which we use to improve learning outcomes and usability.</span></p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Commitment to Supporting Marginalized Children</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Enuma’s primary focus has been children with special needs or learning differences, children who lack resources, and children without access to quality education systems. The success of our commercially available apps — including Todo Math, Kid in Story Book Maker, and Visual Schedule for Children with Autism — has proven our dedication to creating the best possible products to serve all children.</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-our-founders">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <div class="title">Our Founders</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Co-founded in 2012 by two game-developer parents who were inspired by their son with special needs, Enuma is powered by its mission of engaging and empowering children with special needs to learn independently.</span></p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="img">
                <img src="~@/assets/img/en/About/founder.jpeg" alt="">
              </div>
              <div class="founders"><p><strong>Sooinn Lee</strong><span
                  style="font-weight: 400;"><small>&nbsp;<small>— </small></small></span><span
                  style="color: #999999;"><small>CEO &amp; Creative Lead</small></span></p>
                <p><strong>Gunho Lee</strong> <span style="color: #999999;"><small><span
                    style="font-weight: 400;"><small>— </small></span>Chief Engineer</small></span></p></div>
            </div>
            <div class="section-col">
              <p class="quote"><em>“To build Kitkit School, we’ve drawn upon decades of education, gaming, and international development experience. We believe children who do not have access to schools, support, and resources or who struggle in schools need the best learning tools.” </em></p>
              <p class="quote-name" style="text-align: right;"><span style="font-weight: 400;"><small>— Sooinn Lee, CEO and Creative Lead of Enuma</small></span></p>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
<!--      <div class="section-partnerships">-->
<!--        <div class="inner">-->
<!--          <div class="title">Partnerships</div>-->
<!--          <div class="desc">-->
<!--            <p><span style="font-weight: 400;">Kitkit School was developed with generous support from the Korea International Cooperation Agency (KOICA) Creative Technology Program (CTS). Our impact story would not have been made possible without the help of our partners.</span></p>-->
<!--          </div>-->
<!--          <div class="partnerships-list">-->
<!--            <div class="list">-->
<!--              <div class="img"><img src="~@/assets/img/en/About/irc.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/ail.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/imagine.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/ce.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/xp.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/ub.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/gn.png" alt=""></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'

export default {
  name: "About",
  head: {
    title: {
      inner: 'AboutUs',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Kitkit School' },
      { name: 'twitter:image', content: require('../assets/img/common/founder.jpeg')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'AboutUs - Kitkit School' },
      { property: 'og:description', content: 'About Us Building Foundational Skills for ALL Children Team Kitkit® School is a special project team of Enuma®, Inc., a company whose mission is to build technologically exceptional learning tools that help struggling learners gain confidence and independence while building foundational skills. Co-founded in 2012 in Berkeley, California, with offices in Seoul and Beijing. Leader […]' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/xprize-logo-black@4x-1024x251.png') },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/aboutus/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      var scrollHeight = document.body.scrollHeight;
      var maxRotate = -18.868;
      var rotateVal = -4.72 + ((document.querySelector('html').scrollTop * maxRotate) / scrollHeight) * 10;
      var rotateImage = document.getElementById("rotateImage");
      rotateImage.style.transform = 'rotateZ('+ rotateVal +'deg)';
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.about-wrap {
  padding-top: 76px;
  overflow: hidden;

  .inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  .section-row {
    display: flex;
  }

  img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  p {
    margin-bottom: 20px;
    line-height: 39.6px
  }

  b, strong {
    font-weight: 600;
  }

  .dotted-list {
    margin: 15px 0 15px 20px;

    li {
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 22px;
      font-weight: 400;
      line-height: 35.2px;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-heading {
    position: relative;
    background-color: #6d684e;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    z-index: 1;

    .inner {
      padding: 10px;
    }

    .section-row {
      .section-col {
        width: 50%;

        &:first-child {
          display: flex;
          align-content: center;
          align-items: center;
          width: 40%;
        }

        &:nth-child(2) {
          width: 60%;
        }

        .title {
          color: #ffe000;
          font-family: "Ubuntu", Lato, Open sans;
          font-size: 6rem;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1.2em;
        }

        .img {
          transform: rotateZ(-4.72deg);

          img {
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            border-color: #ffffff;
            transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
          }
        }
      }
    }
  }

  .section-info {
    background-color: #ffe000;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 150px 0px 100px 0px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.2em;
      letter-spacing: -0.2px;
      margin-bottom: 20px;
    }

    .desc {
      padding: 10px 0px 0px 0px;

      p {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    .section-row {
      &:nth-child(2) {
        margin-top: 100px;
        margin-bottom: 0px;

        .section-col {
          display: flex;
          width: 50%;
          flex-direction: column;

          &:first-child {
            padding: 0px 60px 0px 0px;
          }

          &:nth-child(2) {
            padding: 0 0 0 60px;
          }
        }
      }

      &:nth-child(3) {
        margin-top: 130px;
        margin-bottom: 0px;
      }

      &:nth-child(4), &:nth-child(5) {
        margin-top: 100px;
        margin-bottom: 0px;
      }
    }
  }

  .section-our-founders {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 90px 0px;
    background-color: #e8e8e8;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 52px;
      font-weight: 900;
      letter-spacing: -0.2px;
      margin-bottom: 20px;
    }

    .desc {
      margin: 0px 0px 0px 0px;
      padding: 10px 0px 0px 0px;

      p {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 39.6px;
      }
    }

    .section-row {
      &:first-child {
        .section-col {
          width: 100%;
        }
      }

      &:nth-child(2) {
        margin-top: 20px;
        margin-bottom: 0;

        .section-col {
          .img {
            margin-bottom: 20px;

            img {
              border-style: solid;
              border-width: 8px 8px 8px 8px;
              border-color: #ffffff;
            }
          }

          .founders {
            padding: 0px 0px 0px 10px;

            p {
              color: #333333;
              font-family: "Lato", Lato, Open sans;
              font-size: 22px;
              font-weight: 400;
              line-height: 0.5em;
            }
          }

          .quote {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 26px;
            font-weight: 600;
            line-height: 2em;

            em {
              font-style: italic;
            }
          }

          .quote-name {
            font-size: 26px;

            small {
              font-size: 80%;
            }
          }

          &:first-child {
            width: 40%;
          }

          &:nth-child(2) {
            width: 60%;
            padding: 0px 0px 0px 50px;
          }
        }
      }
    }
  }

  .section-partnerships {
    padding: 100px 0 80px 0;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 52px;
      font-weight: 900;
      letter-spacing: -0.2px;
      margin-bottom: 20px;
    }

    .desc {
      margin: 0px 0px 40px 0px;
      padding: 10px 0px 0px 0px;

      p {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 39.6px;
      }
    }

    .partnerships-list {
      .list {
        display: flex;

        .img {
          img {
            max-width: 85%;
            filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
          }

          &:hover {
            img {
              filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
            }
          }

          &:first-child {
            padding: 0 0 0 5px;
            width: 10%;

            img {
              max-width: 55%;
            }
          }

          &:nth-child(2) {
            padding: 10px;
            width: 16%;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            padding: 10px;
            width: 16%;
          }

          &:nth-child(7) {
            width: 9.589%;

            img {
              max-width: 75%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .about-wrap {
    .inner {
      max-width: 1025px;
    }

    p {
      line-height: 36px
    }

    .dotted-list {
      li {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .section-heading {
      .section-row {
        .section-col {

          &:first-child {
            padding: 0px 20px 0px 0px;
          }

          &:nth-child(2) {
            padding: 0px 0px 0px 20px;
          }

          .title {
            font-size: 40px;
          }
        }
      }
    }

    .section-info {
      padding: 80px 20px 80px 20px;

      .title {
        font-size: 30px;
      }

      .desc {

        p {
          font-size: 20px;
        }
      }

      .section-row {
        &:nth-child(2) {
          margin-top: 50px;
        }

        &:nth-child(3) {
          margin-top: 50px;
        }

        &:nth-child(4), &:nth-child(5) {
          margin-top: 50px;
        }
      }
    }

    .section-our-founders {
      margin-top: 0;
      margin-bottom: 0;
      padding: 60px 20px 40px 20px;

      .title {
        font-size: 30px;
      }

      .desc {
        p {
          font-size: 20px;
          line-height: 36px;
        }
      }

      .section-row {
        &:nth-child(2) {
          .section-col {
            .founders {
              p {
                font-size: 20px;
              }
            }

            .quote {
              font-size: 20px;
            }

            .quote-name {
              font-size: 16px;
              line-height: 40px;
            }
          }
        }
      }
    }

    .section-partnerships {
      padding: 60px 20px 40px 20px;

      .title {
        font-size: 30px;
      }

      .desc {
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .about-wrap {
    padding-top: 90px !important;

    .inner {
      max-width: 768px;
    }

    p {
      margin-bottom: 20px;
      line-height: 39.6px
    }

    .dotted-list {
      li {
        font-size: 16px;
        line-height: 25.6px;
      }
    }

    .section-heading {
      .section-row {
        flex-direction: column;

        .section-col {
          width: 100%;
          padding: 0 !important;

          &:first-child {
            width: 100%;
          }

          &:nth-child(2) {
            width: 100%;
          }

          .img {
            transform: rotateZ(-1.936deg);
          }
        }
      }
    }

    .section-info {
      padding: 50px 20px 30px 20px;

      .desc {
        p {
          font-size: 16px;
          line-height: 28.8px;
        }
      }

      .section-row {
        &:nth-child(2) {
          flex-direction: column;

          .section-col {
            width: 100%;
            padding: 0 !important;
          }
        }
      }
    }

    .section-our-founders {
      .desc {

        p {
          font-size: 16px;
          line-height: 28.8px;
        }
      }

      .section-row {
        flex-direction: column;

        &:nth-child(2) {
          .section-col {
            .img {
              margin-bottom: 10px;
            }

            .founders {
              p {
                font-size: 16px;
                line-height: 28.8px;
                margin-bottom: 0;
              }
            }

            .quote {
              padding-top: 20px;
              font-size: 16px;
              line-height: 32px;

              em {
              }
            }

            .quote-name {
              small {
                font-size: 12.8px;
              }
            }

            &:first-child {
              width: 100%;
              padding: 0 !important;
            }

            &:nth-child(2) {
              width: 100%;
              padding: 0 !important;
            }
          }
        }
      }
    }

    .section-partnerships {

      .title {
        font-size: 30px;
      }

      .desc {
        p {
          font-size: 16px;
          line-height: 28.8px;
        }
      }

      .partnerships-list {
        .list {
          flex-wrap: wrap;

          .img {
            text-align: center;

            &:first-child {
              width: 33%;

              img {
                max-width: 30%;
              }
            }

            &:nth-child(2) {
              width: 33%;
            }

            &:nth-child(3),
            &:nth-child(6) {
              width: 34%;
            }

            &:nth-child(4),
            &:nth-child(5) {
              width: 33%;
            }

            &:nth-child(7) {
              width: 33%;

              img {
                max-width: 40%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
