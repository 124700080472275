<template>
  <div>
    <Header title="legal-kitkit"/>
    <div class="legal-wrap">
      <!-- section -->
      <div class="section-heading">
        <div class="inner">
          <h2 class="heading-title">LEGAL - Kitkit school</h2>
        </div>
      </div>

      <!-- section -->
      <div class="section-content">
        <div class="inner">
          <div class="heading-title">
            <p class="p1">The following is a non-exhaustive list of Enuma’s trademarks registered in the U.S. and other countries. This list also includes suggested term(s) for each trademark.</p>
          </div>
          <div class="box">
            <div class="item">
              <h2 class="heading-title">Enuma’s Trademarks</h2>
              <div class="list">
                <p>Enuma</p>
                <p>Todo</p>
                <p>Todo Math</p>
                <p>Kid in Story</p>
                <p>Kitkit</p>
              </div>
            </div>
            <div class="item">
              <h2 class="heading-title">Generic Terms</h2>
              <div class="list">
                <p>mobile applications, learning tools, apps</p>
                <p>mobile applications, apps, learning tools</p>
                <p>mobile application, app</p>
                <p>mobile application, app</p>
                <p>mobile applications, learning tools, apps</p>
              </div>
            </div>
          </div>

          <div class="heading-title-lg">
            <p class="p1">Software Licenses</p>
          </div>
          <div class="text-box">
            <p class="p1">Kitkit School software is licensed under
            the Apache License 2.0 and the Creative Commons Attribution International 4.0 License (the “Licenses”); you
            may not use Kitkit School except in compliance with the Licenses. You may obtain a copy of the Licenses at
            <a href="https://github.com/XPRIZE/GLEXP-Team-KitkitSchool/blob/newmaster/LICENSE" target="_blank"
               rel="noopener">https://github.com/XPRIZE/GLEXP-Team-KitkitSchool/blob/newmaster/LICENSE</a> and <a
                href="http://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener"><span class="s1">http://creativecommons.org/licenses/by/4.0/</span></a>
            .</p>
            <p class="p1">Unless required by applicable law or agreed to in writing, the software distributed under the
              Licenses is distributed on an “AS IS” BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express
              or implied. See the Licenses for the specific language governing permissions and limitations under the
              Licenses.</p>
            <p class="p1">This open source license of Kitkit School under Apache License, Version 2.0 excludes a license
              of trademark rights.<span class="Apple-converted-space">&nbsp; </span>This license does not grant
              permission to use the trademarks or product names of Enuma or Kitkit School.<span
                  class="Apple-converted-space">&nbsp; </span>See below for guidelines on use of Kitkit School
              trademarks.</p>
          </div>

          <div class="heading-title-lg">
            <p class="p1">Guidelines for Trademarks</p>
          </div>
          <div class="text-box">
            <p class="p1">Kitkit and Enuma are trademarks of Enuma,
            Inc. registered in the United States and other countries. The Kitkit School name and logo are also
            trademarks of Enuma. (All trademarks either separately or in combination are herein referred to as “Kitkit
            School Trademarks.”)</p>
            <p class="p1">Except as provided in these guidelines, you may not use the Kitkit School Trademarks or any
              confusingly similar marks as a name for your product or company or use the Kitkit School Trademarks in any
              other manner that might cause confusion in the marketplace.</p>
            <p class="p1">You may use the Kitkit School Trademarks only nominatively or descriptively to describe your
              derivative product or service, as long as you do not suggest that your product is endorsed, supported by,
              affiliated with or created by Enuma or Kitkit School.<span class="Apple-converted-space">&nbsp; </span>You
              may not apply or use the Kitkit School Trademarks or any marks that are confusingly similar to the Kitkit
              School Trademarks in connection with any of your derivative works.</p>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'
import AOS from 'aos'
import "aos/dist/aos.css"

export default {
  name: "legal-kitkit",
  head: {
    title: {
      inner: 'Legal_kitkit',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Kitkit School' },
      { name: 'twitter:image', content: require('../assets/img/common/header_app_banner_kitkitschool-2.png')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Legal_kitkit - Kitkit School' },
      { property: 'og:description', content: 'LEGAL – Kitkit school The following is a non-exhaustive list of Enuma’s trademarks registered in the U.S. and other countries. This list also includes suggested term(s) for each trademark. Enuma’s Trademarks Enuma Todo Todo Math Kid in Story Kitkit Generic Terms mobile applications, learning tools, apps mobile applications, apps, learning tools mobile application, app mobile […]' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:url', content: 'http://kitkitschool.com/legal-kitkit/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/legal-kitkit/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  },
  created() {
    AOS.init();
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.legal-wrap {
  padding-top: 75px;

  .inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  a {
    color: #333333;

    &:hover {
      color: #13aff0;
    }
  }

  .section-heading {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 350px 0px;
    background-color: #54595f;
    text-align: center;

    .heading-title {
      padding: 10px;
      color: #ff9f36;
      font-size: 38px;
    }
  }

  .section-content {
    margin-top: -250px;
    margin-bottom: 80px;

    .inner {
      background-color: #ffffff;
      box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 13%);
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      padding: 50px;
      border-radius: 6px;
    }

    .heading-title {
      padding-bottom: 20px;
    }

    .p1 {
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      font-size: 100%;
      margin: 0 0 20px;
      color: #333333;
      line-height: 30px;
      font-family: "Lato", Lato, Open sans;
      font-size: 17px;
    }

    .box {
      padding: 0 100px 60px 100px;
      display: flex;
      margin-right: auto;
      margin-left: auto;
      position: relative;

      .item {
        width: 50%;
        padding: 10px;

        .heading-title {
          color: #333333;
          font-family: "Lato", Lato, Open sans;
          font-size: 23px;
          font-weight: 800;
        }

        .list {
          p {
            color: #777777;
            font-family: "Lato", Lato, Open sans;
            font-size: 16.8px;
            font-weight: 500;
            line-height: 1em;
            margin-bottom: 20px;
          }
        }
      }
    }

    .heading-title-lg {
      padding-bottom: 10px;
      text-align: center;

      .p1 {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        font-size: 26px;
        font-weight: 700;
      }
    }

    .text-box {
      padding-bottom: 50px;

      &:last-child {
        padding-bottom: 0;
      }

      .p1 {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        line-height: 30.6px;
        font-size: 17px;
      }
    }
  }
}

@media (max-width: 999px) {
  .legal-wrap {
    .inner {
      max-width: 1025px;
    }

    .section-content {
      .inner {
        padding: 25px 25px 25px 25px;
        width: 1200px;
        max-width: 90%;
        margin: 0 auto;
      }

      .box {
        padding: 0 0 10px 0;
      }
    }
  }
}

@media (max-width: 719px) {
  .legal-wrap {
    padding-top: 90px;

    .inner {
      max-width: 768px;
    }

    .section-content {
      .inner {
        padding: 15px;
        width: auto;
        max-width: 90%;
      }

      .box {
        padding: 0 0 10px 0;
        flex-direction: column;

        .item {
          width: 100%;
        }
      }
    }
  }
}
</style>
