<template>
  <div>
    <Header title="FAQ"/>
    <div class="faq-wrap">
      <!-- section -->
      <div class="section-heading">
        <div class="inner">
          <div class="spacer"></div>
          <h2 class="heading-title">Maswali yaulizwayo</h2>
          <div class="spacer"></div>
        </div>
      </div>

      <!-- section -->
      <div class="section-faq">
        <div class="inner">
          <div class="accordion" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-1>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Kitkit Shule ni nini?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-1" visible accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300">Kitkit Shule ni programu iliyoko kwenye tabuleti ikiwa na mtaala wa kina unaoanzia umri wa awali wa mtoto mpaka elimu ya awali</p>
                  <p><span style="font-weight: 400;">Imeundwa kuleta uzoefu wa hali ya juu wa kujifunza kwa watoto wanaouhitaji zaidi duniani kote, kuwapatia msingi na mazoezi yanayohitajika kujenga ujuzi wa kusoma na kuhesabu pamoja na kujiamini – bila kujali uwezo wao wa awali au upatikanaji wa shule na rasilimali nyingine</span></p>
                  <p><span style="font-weight: 400;">Mwaka 2019 Mwezi mei, Kitkit Shule ilitajwa kama mshindi wa shindano la kujifunza la XPRIZE. Kwa kipindi chote cha majaribio cha miezi 15 yaliyofanywa na UNESCO pamoja na Shirika la Chakula duniani maeneo ya vijijini nchini Tanzania, Kitkit Shule ilitoa matokeo makubwa ya kujifunza, ikiionyesha dunia uwezo wa kujifunza kidijitali</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-2>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Nini kinaitofautisha Kitkit Shule?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-2" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300"><b>Imeundwa kushirikisha na kuwezesha</b><br>Kitkit Shule imejumuisha mazoezi bora na tafiti zinazoongoza kwenye uundaji wa michezo pamoja na elimu ya kusoma, kuandika na kuhesabu kuwasaidia watoto kushiriki na kujifunza. Mfululizo wa kina wa kujifunza – Program yetu ya kujifunza yenye zaidi ya mazoezi 2,400, maktaba iliyoundwa ndani ikiwa na mamia ya vitabu na video, zana za kidijitali kwa ajili ya uchunguzi na kujieleza – kunawapa morali wanafunzi kujifunza kusoma, kuandika na kufanya hesabu za msingi ili kuweza kuwa wanafunzi wanaojitegemea na wenye hamasa ya kujifunza</p>
                  <p><b>Kwa wanafunzi tofauti</b><br>Timu yetu imetoa kipaumbele kwa wanafunzi tofauti, kama vile watoto wenye; uhitaji maalum, changamoto ya lugha, au wale ambao hawana hamasa ya kujifunza. Ikitumia mazoezi bora ya kimataifa kwenye kusoma, kuandika na hesabu ikiwa na muundo sawa wa kanuni za kujifunza, Kitkit Shule imeundwa kumsaidia kila mtoto kufanikiwa kama mwanafunzi huru</p>
                  <p><b>Matokeo yaliyooneshwa</b><br>Kama mshindi mwenza wa shindano la kujifunza la kidunia la XPRIZE, Kitkit Shule ilifikia matokeo ya juu katika washiriki wote watano waliofikia fainali katika shindano la katika shindano hili la kimataifa. Pia tumefanya majaribio kadhaa ambayo yalionyesha matokeo ya kujifunza yenye nguvu. (angalia chini kwa maelezo zaidi) Timu yetu inajivunia kuona Kitkit Shule ikitoa matokeo kwa makundi ambayo yametengwa, kwa kuwa inaonyesha dhamira yetu ya kuleta uzoefu wa kujifuza wenye ubora wa hali ya juu kwa wale wanaohitaji zaidi.</p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-3>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Kuna ushahidi gani kwenye ufanisi wa Kitkit Shule?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-3" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;"
                          src="~@/assets/img/en/FAQ/owl@4x.png" alt=""
                          width="200" height="300"><span>Kitkit Shule imejidhihirisha kuwa na ufanisi mkubwa, inabadili kujifunza na kutoa matokeo makubwa kwa wanafunzi</span>
                  </p>
                  <ul class="dotted-list">
                    <li><span>Kama mshindi wa shindano la kujifunza la kidunia la XPRIZE. Kitkit shule imeonyesha matokeo makubwa ya kujifunza kwenye ujuzi wa kusoma, kuandika na kuhesabu kwa watoto kwenye shindano kwa miezi 15 </span></li>
                    <li><span>Kwenye kambi ya wakimbizi ya Kakuma nchini Kenya, watoto waliotokea kwenye nchi 9 tofauti wakiwa hawana uelewa wowote wa Kingereza kabla walionyesha matokea makubwa kwa kipindi cha waiki 8 </span></li>
                    <li><span>Kwenye shule moja ya kijijini nchini Rwanda, Kitkit shule ilionyesha kusaidia ufanisi binafsi na hamasa ya kujifunza.</span></li>
                  </ul>
                  <p><span style="font-weight: 400;">Tafadhali tembelea ukurasa<span style="color: #00ccff;"> <router-link to="/sw/impact" style="color: #00ccff;"> wetu wa athari</router-link></span></span><span style="font-weight: 400;"><span style="color: #00ccff;">&nbsp;</span>kwa maelezo zaidi.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-4>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Shindano la Kujifunza la kidunia la XPRIZE ni nini?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-4" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300">Likiwa limeanzishwa mwaka 2014, Shindalo la kujifunza la kidunia la XPRIZE huwa linawataka wavumbuzi kutoka pande mbalimbali za dunia kuunda ufumbuzi unaoweza kupanuliwa ambao unawawezesha watoto kujifunza wenyewe kusoma, kuandika na kuhesabu kwa kipindi cha miezi 15. Kwa kushirikiana na UNESCO, Shirika la Chakula duniani, na wizara ya Elimu ya Tanzania, XPRIZE walifanya majaribio kwa watoto 2,700 katika vijiji 170 Tanzania</p>
                  <p><span>Kitkit ilikuwa mmojawapo wa washindi watano waliofikia fainali septemba mwaka 2018 na ikatangazwa mshindi mwenza wa shindano Mei mwaka 2019 kwa kuweza kufikia matokeo makubwa kwenye majaribio</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-6>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Tunaipangilia vipi Kitkit Shule?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-6" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img class="size-medium wp-image-946 alignleft" style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300"><span style="font-weight: 400;">Kwa huduma yetu ya bidhaa iliyokamilika, Kitkit Shule toleo la Pro, tunatoa mafunzo rahisi ya kusakinisha, mkusanyiko wa vifaa na huduma rahisi za kukusaidia, na zana za kimtandao ambazo zimruhusu msimamizi kuzifanyia kazi taarifa za watumiaji na maendeleo yao kutoka sehemu moja. Kitkit Shule pia inapatikana kupitia Github kama msimbo ulio wazi, japokuwa hili toleo halijumuishi sifa zilizoorodheshwa hapo juu. Tafadhali wasiliana na timu ya Kitkit kwenye </span><a href="mailto:kitkit@enuma.com"><span style="font-weight: 400;">kitkit@enuma.com</span></a><span style="font-weight: 400;"> kama ungependa kuanza kutumia Kitkit Shule!</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-7>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Mtaala wa kusoma, kuandika na hesabu unajumuisha nini?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-7" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;"
                          src="~@/assets/img/en/FAQ/owl@4x.png" alt=""
                          width="200" height="300">Programu ya kujifunza ya Kitkit Shule ni mfululizo wa mazoezi ya kujifunza ambayo yanamfanya mtoto kujifunza na kufanya mazoezi kwenye ujuzi wa msingi wa kusoma, kuandika na kuhesabu. Tolea la sasa hivi linajumuisha kozi 22 zinazojumuisha kusoma kuandika na kuhesabu kwa awali ikiwa na takribani mazoezi ya kujifunza 2,400.</p>
                  <p><strong>Maeneo ya kujifunza: Hesabu</strong><br>Utambuzi wa namba/ Kutofautisha kiasi / Kuhesabu / Kuonyesha namba / Kujumlisha / Kutoa / Kutafuta namba inayokosekana / Maumbo / Mafumbo / Kuzidisha</p>
                  <p><strong>Maeneo ya Kujifunza: Kusoma na kuandika</strong><br>Lugha ya mdomo / utambuzi wa maneno yaliyoandikwa / Kanuni za herufi / / ufahamu wa sauti  / Sauti na herufi zilizoandikwa / Utamkaji wa herufi / Muundo wa maneno / Misamiati / Kusoma au kuandika kwa ufasaha / Maneno yaliyoandikwa / Ufahamu wa kusoma</p>
                  <p><span>Tafadhali tazama hati yetu ya Mfumo wa <a target="_blank" href="/doc/Kitkit-Handbook_V3a.pdf">Kujifunza, Usanifu na Mtaala kwa</a> habari zaidi.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-10>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Ni mazingira gani ya kujifunzia ambayo yanafaa zaidi kwa Kitkit Shule?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-10" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img
                      style="margin-left: -230px; margin-top: -40px;"
                      src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200"
                      height="300"><span>Kitkit Shule inaweza kutumika katika vyumba vya madarasa vya kawaida na katika maeneo mengine ya kujifunza yasiyo rasmi. Inawewezasha wanafunzi kujifunza wenyewe muda ambao walimu au vyumba vya madara havipo; Inaweza pia kuunganishwa pamoja na masomo yanayoongozwa na walimu, kama madarasa na mfumo wa shule unavyotaka mazingira ya elimu mchanganyiko. Kitkit imethibitishwa kutoa matokeo chanya katika maeneo mbalimbali kama ifuatavyo:</span>
                  </p>
                  <ul class="dotted-list">
                    <li><strong>Shule za msingi</strong></li>
                    <li><strong>Maeneo ya jamii</strong></li>
                    <li><strong>Vituo vya kujifunzia</strong></li>
                    <li><strong>Mazingira ya mtu mmoja mmoja</strong></li>
                    <li><strong>Makambi ya Wakimbizi</strong></li>
                    <li><strong>Nyumbani</strong></li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-11>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Maendeleo ya mwanafunzi yanafuatiliwaje?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-11" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300"><span>Watoto wanamaliza mazoezi mbalimbali yanayoonyesha kujifunza kwao na wanafungua mazoezi na michezo migumu zaidi ndani ya Kitkit Shule. Haya yanajumuisha:</span></p>
                  <ul class="dotted-list"><li><span>Mazoezi madogo madogo kumsaidia mtoto kuelewa  na kufuatilia maendeleo yake ya kujifunza</span></li><li><span>Majaribio baada ya kozi baada ya mwanafunzi kumaliza vipindi vyote vilivyopo</span></li><li><span>Majaribio ya Kozi maalum mwishoni mwa kozi za yai la mwisho kwenye sehemu za hesabu na kusoma ili kusaidia kupima maendeleo ya mtoto</span></li></ul>
                  <p><span>Pia Kitkit Shule imebeba zana ya kufanya tathmini ya kidijitali ambayo inawaruhusu walimu na wawezeshaji kusimamia tathmini zinayofanana na EGRA/EGMA.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-12>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Ni mahitaji gani ya mfumo yanahitajika kwa ajili ya Kitkit Shule?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-12" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300">Programu ya kujifunzia ya Kitkit Shule imetengenezwa kufanya kazi kwenye kifaa cha tableti. Tafadhali angalia kama una tableti yenye sifa kama zilivyoorodheshwa hapa chini:<br>– Toleo la OS: Kuanzia Android 6.0 hadi 9.0<br>– Uwezo wa ndani: Kuanzia 16GB ikiwa na 6GB ambazo ziko wazi<br>– RAM: Kuanzia 1.7 GB ila inayopendekezwa ni 2.0 GB</p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-14>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Nawezaje kuhushishwa?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-14" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img
                      style="margin-left: -230px; margin-top: -40px;"
                      src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200"
                      height="300">Tafadhali tutumie baruapepe kwenye <a href="mailto:kitkit@enuma.com"><span style="font-weight: 400;">kitkit@enuma.com</span></a></p>
                  <p><strong>Tungependa kusikia zaidi kutoka kwako:</strong></p>
                  <ul class="dotted-list">
                    <li><span>Unasaidia au unaendesha Shirika lisilo la Kiserikali na unaweza ukawa unatamani Kitkit Shule kwenye shirika lako</span>
                    </li>
                    <li><span>Una rasilimali za kuifanya Kitkit ipatikane kwa lugha nyingine</span>
                    </li>
                    <li><span>Ni mtoa misaada ambaye angependa kuona Kitkit Shule inakusaidia kupanua wigo wako</span>
                    </li>
                    <li>Una rasilimali na mawazo ya kuifanya kitkit shule ipatikane zaidi kwa watoto katika nchi zinazoendelea au jamii ambazo zina rasilimali chache</li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-15>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">Swali langu halijajibiwa hapa...</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-15" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;"
                          src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200"
                          height="300"><span style="font-weight: 400;">Kama hujaona majibu ya swali lako hapa, tafadhali jaza fomu yetu ya mawasiliano iliyoko hapo chini au tuandikie barua pepe kwenyte <a href="mailto:kitkit@enuma.com">kitkit@enuma.com</a>!</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../../components/swheader'
import footer from '../../components/swfooter'
import toTop from '../../components/toTop'

export default {
  name: "swFAQ",
  head: {
    title: {
      inner: 'faq',
      separator: '-',
      complement: 'Swahili Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../../assets/img/common/cropped-kitkit-phase3_icon-270x270.png') },
      { name: 'twitter:title', content: 'faq' },
      { name: 'twitter:image', content: require('../../assets/img/common/owl@4x-246x300.png')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'faq - Swahili Kitkit School' },
      { property: 'og:description', content: 'Maswali yaulizwayo Kitkit Shule ni nini? Kitkit Shule ni programu iliyoko kwenye tabuleti ikiwa na mtaala wa kina unaoanzia umri wa awali wa mtoto mpaka elimu ya awali Imeundwa kuleta uzoefu wa hali ya juu wa kujifunza kwa watoto wanaouhitaji zaidi duniani kote, kuwapatia msingi na mazoezi yanayohitajika kujenga ujuzi wa kusoma na kuhesabu pamoja […]' },
      { property: 'og:site_name', content: 'Swahili Kitkit School' },
      { property: 'og:image', content: require('../../assets/img/common/owl@4x-246x300.png') },
      { property: 'og:url', content: 'http://kitkitschool.com/sw/faq/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/sw/faq/' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';
.faq-wrap {
  padding-top: 76px;

  .inner {
    margin: 0 auto;
  }

  .dotted-list {
    margin: 15px 0 15px 20px;

    li {
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-heading {
    .inner {
      max-width: 1140px;
      background-image: url(~@/assets/img/en/FAQ/leaves@4x.png);
      background-position: -20px -135px;
      background-repeat: no-repeat;
      background-size: 35% auto;
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      margin: 0 auto;
      padding: 10px;
    }

    .spacer {
      height: 100px;
      margin-bottom: 20px;
    }

    .heading-title {
      color: #333333;
      font-family: $lato;
      font-size: 42px;
      line-height: 42px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 20px;
      padding-left: 50px;
    }
  }

  .section-faq {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .inner {
      max-width: 1140px;
      padding: 0 0 0 150px;
    }

    .accordion {
      margin-bottom: 80px;

      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: transparent;
        background-clip: border-box;
        border: 0;
        border-radius: 0;
        overflow: visible;

        .card-header {
          padding: 25px 15px 25px 15px;
          margin-bottom: 0;
          background-color: transparent;
          border-bottom: 0;

          .toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: all 0.3s ease;

            i {
              width: 21px;
              font-size: 18px;
            }

            .title {
              font-family: $lato;
              font-size: 24px;
              font-weight: 700;
              line-height: 29px;
              color: #333;
            }

            &.not-collapsed {
              i {
                color: #f6921e;
              }

              .title {
                color: #f6921e;
              }
            }
          }
        }

        .card-body {
          flex: 1 1 auto;
          min-height: 1px;
          border-width: 0;
          border-top-color: #333333;
          padding: 10px 10px 50px 60px;

          p {
            color: #333333;
            font-family: $lato;
            font-size: 18px;
            font-weight: 400;
            line-height: 32.4px;
            margin-bottom: 20px;

            b, strong {
              font-weight: 600;
            }
          }

          a:hover {
            color: #13aff0 !important;
          }

          img {
            height: auto;
            max-width: 100%;
            border: none;
            float: left;
            margin-right: 1.5em;
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .faq-wrap {
    .section-heading {
      .inner {
        max-width: 1025px;
      }
    }
    .section-faq {
      .inner {
        max-width: 1025px;
      }
    }
  }
}

@media (max-width: 719px) {
  .faq-wrap {
    padding-top: 90px !important;

    .section-heading {
      background-position: 0px -62px;
      background-size: 200px auto;

      .inner {
        max-width: 768px;
      }

      .heading-title {
        padding-left: 0;
      }

      .spacer {
        &:last-child {
          height: 30px;
        }
      }
    }

    .section-faq {
      .inner {
        max-width: 768px;
        padding: 0 10px;
      }

      .accordion {
        .card {
          .card-header {
            .toggle {
              i {
                font-size: 15px;
              }
              .title {
                font-size: 18px;
              }
            }
          }

          .card-body {
            padding: 15px;

            p {
              font-size: 16px;
              line-height: 1.5em;
            }
          }
        }
      }

      img {
        display: none;
      }
    }
  }
}
</style>

