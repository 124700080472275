<template>
  <div>
    <Header title="DownloadOverview"/>
    <div class="download-overview-wrap">

      <!-- Download APP -->
      <div class="section download">
        <div class="content">
          <h1 class="title">DOWNLOAD<br>KITKIT SCHOOL</h1>
          <h2 class="subtitle">For Free Through 2021</h2>
          <img class="tablets" src="~@/assets/img/common/overview_tablet@3x.png" alt="tablet image">
        </div>
      </div>

      <!-- Intro -->
      <div class="section intro">
        <div class="content">
          <p class="text">
            <b>The purpose of this initiative is to support organizations and schools that are working to improve educational equity and access for learners in low-resource, hard-to-reach and developing contexts.</b>
          </p>
          <p class="text">
            The global COVID-19 crisis has made it even more challenging for children in need to access quality education.
            To help address growing inequities, Enuma is making Kitkit School available for FREE until the end of 2021. </p>

          <p class="text">
            With support from the KOICA CTS Program, Kitkit School has the potential to help expand access to high-quality
            learning for all.
          </p>

          <p class="text">
            Kitkit School was originally designed for use in countries in Sub-Saharan Africa, and contains culturally relevant content
            and stories for that context. Please carefully review Kitkit School to ensure it is appropriate for your organization’s
            individual needs.
          </p>

        </div>
      </div>

      <div class="btn-area">
        <router-link to="/download-request">
          <button class="btn btn-primary">
            <i class="fal fa-arrow-alt-to-bottom"></i>
            Request to Download
          </button>
        </router-link>
      </div>

      <!-- Feature -->
      <div class="section feature">
        <div class="content">
          <h2 class="subtitle">Key Administrative Features</h2>
          <p class="text">
            The free downloadable version of Kitkit School includes maintenance-friendly features that allow organizations to keep track of multiple learners and assess their achievements.
          </p>
          <div class="list">
            <div class="row">
              <div class="col-50 box">
                <i class="icon fal fa-users-medical"></i>
                <h3 class="box-title">Multiple Users</h3>
                <div class="text">
                  Add multiple users to a single device;<br>
                  Assign users to digital classrooms<br>
                  for profile management
                </div>
              </div>
              <div class="col-50 box">
                <i class="icon fal fa-tasks"></i>
                <h3 class="box-title">Learning Assessments</h3>
                <div class="text">
                  Learning assessments<br>
                  based on Early Grade Reading<br>
                  Assessment (EGRA) and Early Grade<br>
                  Mathematics Assessment (EGMA)
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-50 box">
                <div class="icon img-icon"></div>
                <h3 class="box-title">Offline Functionality</h3>
                <div class="text">
                  Learning application allows<br>
                  offline play and management;<br>
                  internet connection only required<br>
                  for installation and data syncing
                </div>
              </div>
              <div class="col-50 box">
                <i class="icon fal fa-globe"></i>
                <h3 class="box-title">Multiple Languages</h3>
                <div class="text">
                  Current language support includes<br>
                  English and Swahili
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Guide -->
      <div class="section guide">
        <div class="content">
          <div class="product">
            <i class="icon far fa-arrow-circle-right"></i>
            <div class="text">To learn more about our product, <div class="space"></div>visit the <router-link class="link" to="/product">Product</router-link> page</div>
          </div>
        </div>
      </div>

      <!-- Notice -->
      <div class="section notice">
        <div class="content">
          <p class="text">
            With approved access to the free downloadable version, organizations will be able to learn more about these features and utilize our suite of video tutorials and supporting materials.
          </p>
          <p class="text">
            Ready for an engaging digital early learning experience?
          </p>
        </div>
      </div>

      <div class="btn-area">
        <router-link to="/download-request">
          <button class="btn btn-primary">
            <i class="fal fa-arrow-alt-to-bottom"></i>
            Request to Download
          </button>
        </router-link>
      </div>

      <div class="section contact">
        <div class="content">
          <p>
            If you are interested in implementing Kitkit School at a larger scale or in a different language, please
            <a class="email" href="mailto:Kitkitsupport@enuma.com">contact us</a> to discuss a potential partnership.
          </p>
        </div>
      </div>

      <!-- Sponsor -->
      <div class="section sponsor">
        <div class="content">
          <div class="row">
            <div class="col-33">
              <span class="text">Sponsored by</span>
            </div>
            <div class="col-33">
              <img class="img" src="~@/assets/img/common/logo_koica@3x.png" alt="KOICA Logo">
            </div>
            <div class="col-33">
              <img class="img" src="~@/assets/img/common/logo_cts@3x.png" alt="CTS(Creative Technology Service) Logo">
            </div>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'


export default {
  name: "DownloadFAQ",
  head: {
    title: {
      inner: 'Download-Overview',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Download-Overview' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Download-Overview - Kitkit School' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/overview_tablet@3x.png') },
      { property: 'og:url', content: 'http://kitkitschool.com/download-overview/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/download-overview/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components: {
    Header: header,
    Footer: footer,
    toTop: toTop,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.download-overview-wrap {
  padding-top: 76px;

  b, strong {
    font-weight: 600;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin: 0 0 20px;
    color: #333;
    line-height: 1.4;
  }

  .fal, .far {
    font-family: "Font Awesome 5 Pro";
  }

  img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-area {
    text-align: center;

    a {
      box-shadow: none;
      text-decoration: none;
      transition: all 0.3s ease;
      box-shadow: none;
      text-decoration: none;
    }

    .btn {
      &.btn-primary {
        width: 360px;
        height: 60px;
        border-radius: 4px;
        border: none;
        padding: 16px 50px 15px;
        background-color: #f6c221;
        color: #fff;
        font-family: Lato;
        font-size: 24px;
        line-height: 29px;
        font-weight: bold;
      }
    }
  }

  .section {
    .content {
      max-width: 1200px;
      margin: 0 auto;
    }

    &.download {
      background-color: #fed400;
      height: 340px;

      .content {
        padding: 70px 30px 101px;
        position: relative;
        color: #333333;

        .title {
          font-family: "Ubuntu", Lato, "Open Sans", sans-serif;
          font-size: 60px;
          font-weight: bold;
          line-height: 67px;
          margin-bottom: 10px;
        }

        .subtitle {
          font-family: "Ubuntu", Lato, "Open Sans", sans-serif;
          font-size: 40px;
          font-weight: normal;
          line-height: 45px;
        }

        .tablets {
          width: 611px;
          height: 300px;
          position: absolute;
          bottom: -30px;
          right: 0;
        }
      }
    }

    &.intro {
      .content {
        padding: 80px 30px 30px;

        .text {
          font-family: Lato;
          font-size: 22px;
          font-weight: normal;
          line-height: 34px;
          color: #333333;
          margin-bottom: 40px;

          &:last-child {
            margin-bottom: 20px;
          }
        }
      }
    }

    &.feature {
      .content {
        padding: 80px 30px 40px;

        .subtitle {
          font-family: "Ubuntu", Lato, "Open Sans", sans-serif;
          font-size: 48px;
          font-weight: bold;
          line-height: 54px;
          color: #333333;
          margin-bottom: 10px;
        }

        > .text {
          font-family: Lato;
          font-size: 22px;
          font-weight: normal;
          line-height: 1.64;
          color: #333333;
          padding-top: 50px;
          margin-bottom: 0;
        }

        .list {
          padding: 10px 10px 40px;
        }

        .box {
          width: 540px;
          height: 440px;
          border: solid 1px #aaaaaa;
          background-color: #ffffff;
          padding: 60px 75px 40px;
          text-align: center;

          .icon {
            font-size: 80px;
            color: #ff8300;
            margin-bottom: 48px;

            &.img-icon {
              width: 80px;
              height: 80px;
              margin: 0 auto 48px;
              background-size: 100%;
            }
          }

          .box-title {
            font-family: Lato;
            font-size: 36px;
            font-weight: bold;
            line-height: 44px;
            color: #333333;
          }

          .text {
            font-family: Lato;
            font-size: 24px;
            font-weight: normal;
            line-height: 31px;
            color: #333333;
          }
        }

        .row {
          display: flex;
          flex-wrap: wrap;
          margin: 40px 0 0;
          padding: 0;

          .col-50 {
            flex-basis: 0;
            flex-grow: 1;
            max-width: calc(50% - 20px);
            width: calc(50% - 20px);
            text-align: center;

            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }

    &.guide {
      .content {
        padding: 0 30px 80px;

        > div {
          position: relative;
        }

        .icon {
          font-size: 32px;
          color: #333333;
          position: absolute;
          top: 3px;
          left: 0;
        }

        .text {
          font-family: Lato;
          font-size: 30px;
          font-weight: normal;
          line-height: 36px;
          color: #333333;
          margin-left: 42px;

          .space {
            display: inline-block;
          }

          .link {
            color: #0c6290;
            text-decoration: underline;
          }
        }
      }
    }

    &.notice {
      background-color: #ffe000;
      margin-bottom: 80px;

      .content {
        padding: 100px 30px 40px;

        .text {
          font-family: Lato;
          font-size: 22px;
          font-weight: normal;
          padding-bottom: 60px;
          line-height: 27px;
          text-align: left;
          color: #333333;
          margin-bottom: 0;

          &:first-child {
            line-height: 33px;
          }
        }
      }
    }

    &.contact {
      .content {
        padding: 80px 30px;

        > p {
          font-family: Lato;
          font-size: 22px;
          font-weight: normal;
          line-height: 1.64;
          color: #333333;
          padding: 0;
          margin: 0;
        }

        .email {
          color: #0c6290;
          text-decoration: underline;
        }
      }
    }

    &.sponsor {
      background-color: #f2f2f2;

      .content {
        height: 240px;
        padding: 69px 30px;

        .text {
          font-family: "Ubuntu", Lato, "Open Sans",sans-serif;
          font-size: 48px;
          font-weight: bold;
          line-height: 94px;
          color: #333333;
        }

        .row {
          display: flex;
          flex-wrap: wrap;

          .col-33 {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 33%;
            width: 33%;
            text-align: center;

            .img {
              width: 208px;
              height: 102px;
            }

            &:first-child {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .download-overview-wrap {
    .section {
      .content {
        max-width: 720px;
        margin: 0 auto;
        padding: 110px 30px;
      }

      &.download {
        height: 540px;

        .content {
          padding: 80px 30px 320px;

          .tablets {
            bottom: -3px;
            right: 54px;
          }
        }
      }

      &.intro {
        .content {
          padding: 120px 30px 30px;

          .text {
            line-height: 36px;
          }
        }
      }

      &.feature {
        .content {
          padding: 80px 30px;

          > .text {
            margin-bottom: 10px;
          }

          .list {
            padding: 0 60px;
          }

          .row {
            margin: 0;

            .col-50 {
              max-width: calc(100% - 60px);
              width: calc(100% - 60px);
              min-width: 540px;

              &:first-child {
                margin: 40px auto;
              }
            }
          }
        }
      }

      &.guide {
        .content {
          padding: 0 30px 80px;

          .product {
            margin-bottom: 0;
          }

          .text {
            .space {
              display: inline-block;
              width: 140px;
            }
          }
        }
      }

      &.notice {
        .content {
          padding: 100px 30px 40px;

          .text {
            &:first-child {
              padding-right: 22px;
            }
          }
        }
      }

      &.contact {
        .content {
          padding: 80px 30px;
        }
      }

      &.sponsor {
        .content {
          height: 417px;
          padding: 70px 30px 80px;

          .row {
            .col-33 {
              max-width: 50%;
              width: 50%;
              min-width: 33%;

              .img {
                width: 230px;
                height: 102px;
              }

              &:first-child {
                min-width: 100%;
                margin-bottom: 60px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .download-overview-wrap {
    padding-top: 90px !important;

    .btn-area {
      .btn {
        &.btn-primary {
          width: 320px;
          height: 53px;
          padding: 14px 48px 14px 45px;
          font-size: 21px;
          line-height: 25px;
        }
      }
    }

    .section {
      .content {
        max-width: 360px;
        min-width: 360px;
        margin: 0 auto;
        padding: 60px 20px;
      }

      &.download {
        height: 280px;

        .content {
          padding: 30px 20px 148px;

          .title {
            font-size: 30px;
            line-height: 34px;
          }

          .subtitle {
            font-size: 20px;
            line-height: 23px;
          }

          .tablets {
            width: 292px;
            height: 142px;
            bottom: -22px;
            right: 34px;
          }
        }
      }

      &.intro {
        .content {
          padding: 74px 20px 20px;

          .text {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 40px;
          }
        }
      }

      &.feature {
        .content {
          padding: 60px 20px;

          .subtitle {
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 15px;
          }

          > .text {
            padding-top: 5px;
          }

          .list {
            padding: 0;
          }

          .box {
            width: 320px;
            height: 260px;
            padding: 35px 45px 30px;

            .icon {
              font-size: 46px;
              margin-bottom: 30px;

              &.img-icon {
                width: 58px;
                height: 58px;
                margin: 0 auto 30px;
                background: url(~@/assets/img/common/ico_offline.png) no-repeat 50% 50%;
                background-size: 100%;
              }
            }

            .box-title {
              font-size: 20px;
              line-height: 24px;
            }

            .text {
              font-size: 13px;
              line-height: 19px;
            }
          }

          .row {
            margin: 0;

            .col-50 {
              max-width: 100%;
              width: 100%;
              min-width: 320px;
              margin-top: 25px;

              &:first-child {
                margin: 30px 0 0 0;
              }
            }
          }
        }
      }

      &.guide {
        .content {
          padding: 0 20px 60px;

          .icon {
            font-size: 24px;
            top: 4px;
          }

          .text {
            font-size: 18px;
            line-height: 30px;
            margin-left: 34px;

            .space {
              width: 0;
            }
          }
        }
      }

      &.notice {
        .content {
          padding: 60px 20px;

          .text {
            font-size: 18px;
            line-height: 36px;
            margin-bottom: 0;
            padding-bottom: 0;

            &:first-child {
              padding-bottom: 40px;
              line-height: 36px;
              padding-right: 0;
            }
          }
        }
      }

      &.contact {
        .content {
          padding: 60px 20px;

          > p {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.67;
          }
        }
      }

      &.sponsor {
        .content {
          height: 210px;
          padding: 32px 20px;

          .text {
            font-size: 30px;
            line-height: 50px;
          }

          .row {
            .col-33 {
              max-width: 50%;
              width: 50%;
              min-width: 33%;

              .img {
                width: 130px;
                height: 64.1px;
              }

              &:first-child {
                margin-bottom: 22px;
                min-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>





























