<template>
  <div>
    <Header title="DownloadFAQ"/>
    <div class="download-faq-wrap">
      <div class="section-faq">
        <div class="inner">
          <div class="heading-title">FAQ</div>

          <div class="accordion-wrap">
            <div class="accordion-header">
              <i class="icon far fa-dot-circle"></i>
              <span>Installation Process</span>
            </div>
            <div class="accordion" role="tablist">
              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-1>
                    <span class="title"> Can anyone sign up to use Kitkit School for free? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-1" accordion="faq-accordion-1" role="tabpanel" >
                  <b-card-body>
                    <p class="answer">Yes. Anyone can sign up for an account to download Kitkit School software until 2021. Please refer to the instructions provided in the <a class="link" href="#">Sign Up</a> page.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-2>
                    <span class="title"> How do I sign up to get access to Kitkit School? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-2" accordion="faq-accordion-2" role="tabpanel">
                  <b-card-body>
                    <p class="answer text1-2">Go to our <a class="link" href="#">Sign Up</a> page and fill out the form to create an account. Wait for us to activate your account and send you a confirmation email. This may take up to 5 business days to activate your account.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-3>
                    <span class="title"> What do I do after receiving the confirmation email? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-3" accordion="faq-accordion-3" role="tabpanel">
                  <b-card-body>
                    <p class="answer">You can now access the admin page to download Kitkit School software. Follow the detailed instructions provided in the confirmation email.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-4>
                    <span class="title"> What is the admin page? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-4" accordion="faq-accordion-4" role="tabpanel">
                  <b-card-body>
                    <p class="answer">This is where you can download Kitkit School software from. After we process your account activation, our team will send you an email with your account information and instructions on how to access the admin page. You will also be able to access video tutorials and installation manuals from here.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-5>
                    <span class="title"> How do I install the software? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-5" accordion="faq-accordion-5" role="tabpanel">
                  <b-card-body>
                    <p class="answer">Download the software from the admin page and refer to the video tutorials and installation manuals also in the admin page. Make sure you have (1) a PC download the install package and (2) a tablet that meets our minimum specification requirements.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-6>
                    <span class="title"> What equipment do I need to install the software? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-6" accordion="faq-accordion-6" role="tabpanel">
                  <b-card-body>
                    <p class="answer">You need to prepare (1) a PC to download the install package and (2) a tablet device with minimum device specifications.</p>
                    <div class="answer" style="padding-top: 4px;">Please check the minimum device specifications below:
                      <ul class="dotted-list" style="margin-bottom: 0;">
                        <li>Minimum OS version: <span class="yale">Android 6.0</span></li>
                        <li>Storage: <span class="yale">16GB</span> with <span class="yale">6GB</span> available space</li>
                        <li>RAM: <span class="yale">2.0GB (minimum 1.7GB)</span></li>
                        <li>You check your device spec in the Settings app, under About Tablet and Storage menu</li>
                      </ul>
                    </div>
                    <p class="answer" style="padding-top: 4px;">Kitkit School cannot be installed on devices that do not meet the requirements of the specification.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-7>
                    <span class="title"> What are the minimum specification requirements for the tablet to install Kitkit School? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-7" accordion="faq-accordion-7" role="tabpanel">
                  <b-card-body>
                    <div class="answer">Please make sure if your tablet device meets the minimum device specifications outlined below:
                      <ul class="dotted-list">
                        <li>Minimum OS version: <span class="yale">Android 6.0</span></li>
                        <li>Storage: <span class="yale">16GB</span> with <span class="yale">6GB</span> available space</li>
                        <li>RAM: <span class="yale">2.0GB (minimum 1.7GB)</span></li>
                      </ul>
                    </div>
                    <p class="answer" style="padding-top: 4px;">Kitkit School does not work on mobile phones.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="accordion-wrap">
            <div class="accordion-header">
              <i class="icon far fa-dot-circle"></i>
              <span>Using Kitkit School</span>
            </div>
            <div class="accordion" role="tablist">
              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-8>
                    <span class="title">What is the license?</span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-8" accordion="faq-accordion-8" role="tabpanel" >
                  <b-card-body>
                    <p class="answer">The license is to activate all contents and functions after you install the Kitkit School to your device. The license is required per device and has an expiration date, set to December 31, 2021. Enter the number of licenses you need when signing up. You may leave comments if you wish to discuss further on the expiration date.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-9>
                    <span class="title">How many users can be added to one tablet?</span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-9" accordion="faq-accordion-9" role="tabpanel">
                  <b-card-body><p class="answer">You can add up to 100 users per tablet with Kitkit School installed.</p></b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-10>
                    <span class="title">What is the admin page?</span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-10" accordion="faq-accordion-10" role="tabpanel">
                  <b-card-body>
                    <p class="answer">After you sign up for an account, our team will send you an email that your account is now activated. With the email, you will be able to access the admin page to sign in and download Kitkit School. You will also be able to access resources for installation instructions. Our team is also preparing an update on the functionality to view usage data from this admin page.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-11>
                    <span class="title">How is the learning curriculum structured?</span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-11" accordion="faq-accordion-11" role="tabpanel">
                  <b-card-body>
                    <p class="answer">Kitkit School was designed to build basic literacy and numeracy skills for early grade learners from pre-primary to primary students in second to third grades. For more information, please visit our <router-link class="yale" to="/product">Product</router-link> page.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>

          <div class="accordion-wrap">
            <div class="accordion-header">
              <i class="icon far fa-dot-circle"></i>
              <span>Troubleshooting</span>
            </div>
            <div class="accordion" role="tablist">
              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-12>
                    <span class="title"> I lost my ID and Password and I cannot access the admin page. </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-12" accordion="faq-accordion-12" role="tabpanel">
                  <b-card-body>
                    <p class="answer">Please send your account information (your name, email address, and organization) to <a class="email" href="mailto:kitkitsupport@enuma.com">kitkitsupport@enuma.com</a> and we will respond to you with the ID and Password.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-13>
                    <span class="title"> Kitkit School does not work on my tablet device </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-13" accordion="faq-accordion-13" role="tabpanel">
                  <b-card-body>
                    <p class="answer">After you sign up, you will have access to the admin page. You can find the Support menu with troubleshooting steps on the admin page.</p>
                    <div class="answer" style="padding-top: 4px;">Please make sure that your device meets below minimum device specification requirements:
                      <ul class="dotted-list">
                        <li>Minimum OS version: <span class="yale">Android 6.0</span></li>
                        <li>Storage: <span class="yale">16GB</span> with <span class="yale">6GB</span> available space</li>
                        <li>RAM: <span class="yale">2.0GB (minimum 1.7GB)</span></li>
                      </ul>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-14>
                    <span class="title"> Can I reinstall the Kitkit School or install it on another device? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-14" accordion="faq-accordion-14" role="tabpanel">
                  <b-card-body>
                    <p class="answer">You can see the instruction video tutorials and manuals on the admin page.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body>
                <b-card-header header-tag="header" role="tab">
                  <div class="toggle" v-b-toggle.faq-15>
                    <span class="title"> Further queries? </span>
                  </div>
                </b-card-header>
                <b-collapse id="faq-15" accordion="faq-accordion-15" role="tabpanel">
                  <b-card-body>
                    <p class="answer">Please send us an email with a description of the issue, in addition to your account information (your name, ID, email address, and organization) to <a class="email" href="mailto:kitkitsupport@enuma.com">kitkitsupport@enuma.com</a>.</p>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
        <div class="contact-wrap">
          <div class="contact">
            <div class="contact-inner">
              <div class="box">
                <h3 class="subtitle"><i class="icon far fa-dot-circle"></i>Contact Us</h3>
                <a class="email" href="mailto:kitkitsupport@enuma.com">kitkitsupport@enuma.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'

export default {
  name: "DownloadFAQ",
  head: {
    title: {
      inner: 'Download-FAQ',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Download-FAQ' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Download-FAQ - Kitkit School' },
      { property: 'og:description', content: 'FAQ Installation Process Can anyone sign up to use Kitkit School for free? Yes. Anyone can sign up for an account to download Kitkit School software until 2021. Please refer to the instructions provided in the Sign Up page. How do I sign up to get access to Kitkit School? Go to our Sign Up […]' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:url', content: 'http://kitkitschool.com/download-faq/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/download-faq/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.download-faq-wrap {
  padding-top: 76px;

  .inner {
    padding: 10px;
  }

  .collapsing {
    transition: none;
  }

  .dotted-list {
    padding-left: 10px;

    li {
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
      margin-top: 15px;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-faq {
    max-width: 1200px;
    margin: 0 auto;

    .inner {
      margin: 0 30px 110px;
    }

    .heading-title {
      font-family: $ubuntu;
      padding-top: 110px;
      font-size: 60px;
      font-weight: 600;
      line-height: 67px;
      color: #333333;
      margin-bottom: 20px;
    }

    .accordion-wrap {
      margin-top: 30px;
      padding-left: 63px;
    }

    .accordion-header {
      padding-top: 50px;
      margin-bottom: 30px;
      font-size: 32px;
      font-weight: 900;
      line-height: 39px;
      color: #333333;

      i {
        margin-right: 10px;
      }
    }

    .accordion {
      margin-left: 39px;
      margin-right: 100px;

      .card {
        border-left: 0;
        border-right: 0;

        &:last-child {
          border-bottom: 0;
        }
      }
      .card-header {
        padding: 19px 0 22px;
        font-family: $lato;
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
        background-color: transparent;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;

        .toggle {
          display: flex;
          align-items: center;

          .title {
            margin-left: 38px;
          }

          &.not-collapsed {
            .title {
              color: #f6921e;
            }
          }
        }
      }
      .card-body {
        padding: 0;

        .answer {
          padding: 24px 34px 31px;
          margin-bottom: 0;
          font-family: $lato;
          font-size: 20px;
          font-weight: normal;
          line-height: 32px;
          color: #333333;

          .email {
            color: #0c6290;
            text-decoration: underline;
          }

          .yale {
            color: #0c6290;
          }

          .link {
            color: #0c6290;
            text-decoration: underline;
          }
        }
      }
    }

    .contact-wrap {
      margin-bottom: 25px;

      .contact {
        width: 100%;
        background-color: #f2f2f2;

        .box {
          max-width: 1200px;
          padding: 0 30px;
          margin: 0 auto;
        }

        .contact-inner {
          padding: 70px 60px;

          .subtitle {
            font-family: $lato;
            font-size: 32px;
            font-weight: 900;
            font-style: normal;
            line-height: 39px;
            color: #333333;
            margin-bottom: 20px;

            i {
              margin-right: 10px;
            }
          }

          .email {
            font-family: $lato;
            font-size: 24px;
            font-weight: bold;
            font-style: normal;
            line-height: 29px;
            color: #0c6290;
            margin-left: 42px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .download-faq-wrap {
    .section-faq {
      .heading-title {
        padding-top: 97px;
      }

      .accordion-wrap {
        padding-left: 0;
      }

      .accordion {
        margin-left: 39px;
        margin-right: 30px;

        .card-body {
          .answer {
            line-height: 30px;
          }
        }
      }

      .contact-wrap {
        .contact {
          .contact-inner {
            padding: 70px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .download-faq-wrap {
    .section-faq {
      .inner {
        margin: 0 20px;
      }

      .heading-title {
        font-size: 30px;
        line-height: 34px;
        padding-top: 60px;
        margin-bottom: 10px;
      }

      .accordion-wrap {
        margin-top: 0;
      }

      .accordion-header {
        padding-top: 30px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 22px;
      }

      .accordion {
        margin-left: 0;
        margin-right: 0;

        .card-header {
          padding: 9px 0 11px;
          font-size: 16px;
          line-height: 19px;

          .toggle {
            .title {
              margin-left: 22px;
            }
          }
        }

        .card-body {
          .answer {
            font-size: 14px;
            padding: 0 26px 16px;
            line-height: 19px;
          }
        }
      }

      .contact-wrap {
        margin-top: 40px;

        .contact {
          .contact-inner {
            padding: 50px 0;

            .subtitle {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 8px;
            }

            .email {
              font-size: 14px;
              font-weight: bold;
              line-height: 17px;
              margin-left: 22px;
            }
          }
        }
      }
    }
  }
}
</style>

