<template>
  <div class="footer">
    <div class="row-wrap">
      <div class="inner">
        <div class="column-wrap">
          <div class="widget-wrap">
            <div class="widget-image">
              <div class="image">
                <a href="http://enuma.com" target="_blank">
                  <img width="916" height="231" src="~@/assets/img/common/logoenumawhite@2x.png" alt="">
                </a>
              </div>
            </div>
            <div class="widget-heading">
              <h2 class="heading-title">Kwa taarifa zaidi tafadhali tuandikie kwenye</h2>
              <h2 class="heading-title-bold">kitkit@enuma.com</h2>
            </div>
            <div class="social-icons">
              <div class="social-icons-wrapper">
                <div class="grid-item">
                  <a class="icon social-icon" href="https://twitter.com/kitkitschool" target="_blank">
                    <font-awesome-icon :icon="['fab', 'twitter']" />
                  </a>
                </div>
                <div class="grid-item">
                  <a class="icon social-icon" href="https://facebook.com/kitkitschool" target="_blank">
                    <font-awesome-icon :icon="['fab', 'facebook-f']" />
                  </a>
                </div>
                <div class="grid-item">
                  <a class="icon social-icon" href="https://www.youtube.com/watch?v=HG2BvtjKGcA&amp;list=PLKGu8D2CSCIVFKZTP3-_BfOV35MP1o2Jc" target="_blank">
                    <font-awesome-icon :icon="['fab', 'youtube']" />
                  </a>
                </div>
              </div>
            </div>
            <div class="btn-wrap">
              <router-link :to="{ path: goSW() }" replace class="btn-lang">English</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="link-area">
      <div class="inner">
        <p class="heading-title"> © 2019 Kitkit-Shule. Haki zote zimehifadhiwa.</p>
        <ul>
          <li><a href="http://enuma.com/tos/" target="_blank" class="button-link" role="button">Masharti ya huduma</a></li>
          <li><router-link class="button-link" target="_blank" to="/legal-kitkit">Kisheria</router-link></li>
          <li><a href="http://enuma.com/policy/" target="_blank" class="button-link" role="button">Sera ya faragha</a></li>
        </ul>
      </div>
    </div>
    <cookie-law name="cookie-law" buttonText="Accept" message="This website uses cookies to improve your experience. We'll assume you're ok with this, but you can opt-out if you wish. "></cookie-law>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter, faFacebookF, faYoutube } from "@fortawesome/free-brands-svg-icons";
import CookieLaw from 'vue-cookie-law'

library.add(faTwitter, faFacebookF, faYoutube);

export default {
  name: "Footer",
  methods: {
    goSW() {
      if(this.$route.path === '/sw') {
        return '/'
      }
      else if(this.$route.path === '/sw/product') {
        return '../product'
      }
      else if(this.$route.path === '/sw/impact') {
        return '../impact'
      }
      else if(this.$route.path === '/sw/aboutus') {
        return '../aboutus'
      }
      else if(this.$route.path === '/sw/press') {
        return '../press'
      }
      else if(this.$route.path === '/legal-kitkit') {
        return '../legal-kitkit'
      }
      else {
        return '../faq'
      }
    }
  },
  myIcon: {
    faTwitter,
    faFacebookF,
    faYoutube
  },
  components : {
    FontAwesomeIcon : FontAwesomeIcon,
    CookieLaw
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/common';

.footer {
  .row-wrap {
    width: 100%;
    background-color: #353437;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 30px 0 30px 0;

    .inner {
      position: relative;
      max-width: 1140px;
      display: flex;
      margin-right: auto;
      margin-left: auto;
      justify-content: space-between;

      .column-wrap {
        position: relative;
        width: 45%;
        display: flex;
        min-height: 1px;
        padding: 10px;

        .widget-wrap {
          .widget-image {
            .image {
              width: 173px;
              height: 44px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .widget-heading {
            margin-top: 30px;
            margin-bottom: 20px;

            .heading-title {
              color: #ffffff;
              font-family: $lato;
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 10px;
            }
            .heading-title-bold {
              color: #ffffff;
              font-family: $lato;
              font-size: 16px;
              font-weight: 800;
            }
          }

          .social-icons {
            margin-bottom: 20px;

            .social-icons-wrapper {
              .grid-item {
                display: inline-block;
                word-break: break-word;
                margin-right: 17px;

                &:last-child {
                  margin-right: 0;
                }

                .social-icon {
                  color: rgba(255,255,255,0.45);
                  font-size: 20px;
                  padding: 0.4em;

                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
          }

          .btn-wrap {
            .btn-lang {
              display: inline-block;
              font-family: $lato;
              font-size: 14px;
              font-weight: 700;
              color: #ffffff;
              background-color: rgba(255,255,255,0);
              border-radius: 0;
              padding: 8px 35px 8px 35px;
              border-style: solid;
              border-width: 2px;
              border-color: #ffffff;
              transition: all 0.3s ease;
              cursor: pointer;
              text-align: center;
              letter-spacing: 0.1em;
              line-height: 1;
              text-transform: uppercase;
              margin: 0;

              &:hover {
                background-color: #fff;
                color: #ff8922;
              }
            }
          }

          &.widget-contact-us {
            width: 100%;

            .widget-heading {
              .heading-title {
                color: #ff8922;
                font-family: $ubuntu;
                font-size: 30px;
                font-weight: 500;
              }
            }
            .widget-form {
              .elementor-form {
                .form-fields {
                  .field-type-text {
                    margin-bottom: 11px;

                    input, textarea {
                      display: inline-block;
                      min-height: 40px;
                      width: 100%;
                      max-width: 100%;
                      line-height: 1.8;
                      padding: 6px 12px;
                      vertical-align: middle;
                      -webkit-box-flex: 1;
                      -ms-flex-positive: 1;
                      flex-grow: 1;
                      color: #ffffff;
                      font-size: 14px;
                      font-weight: 300;
                      background-color: rgba(255,255,255,0.09);
                      border-width: 0;
                      border-radius: 0;
                      transition: all 0.3s ease;
                      -webkit-appearance: none;
                      font-family: $openSans;
                      font-weight: 300;
                    }
                  }

                  .field-label {
                    position: absolute;
                    top: -10000em;
                    width: 1px;
                    height: 1px;
                    margin: -1px;
                    padding: 0;
                    overflow: hidden;
                    clip: rect(0,0,0,0);
                    border: 0;
                  }

                  .field-type-checkbox {
                    margin-bottom: 15px;

                    .acceptance-field {
                      line-height: 0;
                      width: 16px;
                      min-width: 16px;
                      height: 16px;
                      margin: -3px 10px 0 0;
                      outline: 0;
                      text-align: center;
                      vertical-align: middle;
                      clear: none;
                      cursor: pointer;
                      background-color: rgba(255,255,255,0.09);
                      border-width: 0;
                      border-radius: 0;
                      color: #ffffff;
                      display: inline-block;
                      -webkit-appearance: none;
                      box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
                      transition: 0.05s border-color ease-in-out;
                      font-size: 14px;
                      font-weight: 300;
                      padding-top: 1px;

                      &:checked {
                        &::before {
                          content: '\f00c';
                          margin-bottom: -1px;
                          color: #13aff0;
                          font-weight: 600;
                          display: inline-block;
                          vertical-align: middle;
                          width: 16px;
                          line-height: 14px;
                          font-family: 'Font Awesome 5 Free';
                        }
                      }
                    }
                    label {
                      display: inline;
                      color: #ffffff;
                      font-size: 14px;
                      font-family: $openSans;
                      font-weight: 300;
                      opacity: .5;
                    }
                  }

                  .e-form-buttons {
                    .btn-bg-primary {
                      display: block;
                      width: 100%;
                      height: 40px;
                      line-height: 40px;
                      font-family: $lato;
                      font-size: 15px;
                      font-weight: 800;
                      text-transform: uppercase;
                      border-radius: 0;
                      min-height: 40px;
                      letter-spacing: 1px;
                      transition: all 0.3s ease;

                      &:hover {
                        background-color: #ffffff;
                        color: #ff8922;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .link-area {
    width: 100%;
    height: 50px;
    background-color: #19181b;

    .inner {
      width: 1140px;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;

      .heading-title {
        width: 55%;
        color: rgba(255,255,255,0.86);
        font-family: $lato;
        font-size: 14px;
        font-weight: 500;
        padding: 10px;
      }

      ul {
        width: 45%;
        height: 100%;
        display: flex;
        padding: 10px;

        li {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 33.3333%;
          height: 100%;

          &:last-child {
            &::after {
              content: none;
            }
          }

          &:after {
            content: '';
            position: absolute;
            width: 1px;
            height: 14px;
            right: 0;
            top: 50%;
            background-color: #FFFFFF;
            transform: translateY(-50%);
          }

          a {
            display: block;
            font-family: $lato;
            font-size: 14px;
            font-weight: 600;
            color: #FFFFFF;
            transition: all 0.3s ease;

            &:hover {
              color: #ff8922;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .footer {
    .row-wrap {
      padding: 50px 15px 50px 15px;

      .inner {
        .column-wrap {
          width: 41%;

          &:nth-child(2) {
            width: 55%;
          }
        }
      }
    }
    .link-area {
      .inner {
        width: auto;
        padding: 0 20px;

        .heading-title {
          width: 50%;
        }

        ul {
          width: 50%;

          li {
            &:first-child {
              width: 40%;
            }
            &:nth-child(2) {
              width: 30%;
            }
            &:nth-child(3) {
              width: 30%;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .footer {
    .row-wrap {
      padding: 40px 15px 40px 15px;

      .inner {
        display: block;

        .column-wrap {
          width: 100% !important;

          .widget-wrap {
            .widget-image {
              .image {
                width: auto;
                height: auto;

                img {
                  max-width: 47%;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }

    .link-area {
      height: auto;

      .inner {
        padding: 10px 15px 10px 15px;
        flex-direction: column;

        .heading-title {
          width: 100%;
          justify-content: flex-start;
        }

        ul {
          width: 100%;

          li {
            width: 33.333% !important;
            &:nth-child(3) {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 999px) and (max-width: 1140px) {
  .footer {
    .link-area {
      .inner {
        width: auto;
      }
    }
  }
}
</style>
