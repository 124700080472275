<template>
  <div class="header" id="header">
    <div class="navbar">
      <h1 class="logo">
        <router-link to="/">
          <img src="~@/assets/img/en/Home/kitkitlogo_eng.png" alt="kitkit school">
        </router-link>
      </h1>
      <div class="gnb">
        <ul class="menu">
          <router-link tag="li" class="link" exact-active-class="on" to="/"><a><span>Home</span></a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/product"><a><span>Product</span></a></router-link>
<!--          <li class="link">-->
<!--            <div class="link-inner">-->
<!--              <span>Download</span>-->
<!--              <ul class="sub-menu">-->
<!--                <router-link tag="li" class="sub-link" active-class="on" to="/download-overview"><a><span>overview</span></a></router-link>-->
<!--                <router-link tag="li" class="sub-link" active-class="on" to="/download-request"><a><span>Request</span></a></router-link>-->
<!--                <router-link tag="li" class="sub-link" active-class="on" to="/download-faq"><a><span>FAQ</span></a></router-link>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </li>-->
          <router-link tag="li" class="link" exact-active-class="on" to="/impact"><a><span>Impact</span></a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/aboutus"><a><span>About</span></a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/press"><a><span>Press</span></a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/faq"><a><span>faq</span></a></router-link>
        </ul>
      </div>
      <div class="social-icon-wrap">
        <a class="btn-icon btn-twitter" href="https://twitter.com/kitkitschool" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" /></a>
        <a class="btn-icon btn-facebook" href="https://www.facebook.com/kitkitschool/"><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>
      </div>
    </div>
    <div class="mob-navbar">
      <h1 class="logo">
        <router-link to="/">
          <img src="~@/assets/img/en/Home/kitkitlogo_eng.png" alt="kitkit school">
        </router-link>
      </h1>
      <div class="gnb">
        <a class="menu-toggle" v-b-toggle href="#menu-collapse" @click.prevent><i class="eicon-menu-bar"></i></a>
      </div>
      <b-collapse class="menu-2depth" id="menu-collapse" style="top: 90px;">
        <ul class="menu">
          <router-link tag="li" class="link" exact-active-class="on" to="/"><a>Home</a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/product"><a>Product</a></router-link>
          <li class="link link-2depth">
            <a v-b-toggle href="#menu-download" @click.prevent>Download</a>
            <b-collapse class="menu-3depth" id="menu-download">
              <ul class="sub-menu">
                <router-link tag="li" class="link" exact-active-class="on" to="/download-overview"><a>Overview</a></router-link>
                <router-link tag="li" class="link" exact-active-class="on" to="/download-request"><a>Request</a></router-link>
                <router-link tag="li" class="link" exact-active-class="on" to="/download-faq"><a>FAQ</a></router-link>
              </ul>
            </b-collapse>
          </li>
          <router-link tag="li" class="link" exact-active-class="on" to="/impact"><a>Impact</a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/aboutus"><a>About</a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/press"><a>Press</a></router-link>
          <router-link tag="li" class="link" exact-active-class="on" to="/faq"><a>faq</a></router-link>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitter , faFacebookF } from "@fortawesome/free-brands-svg-icons";

library.add(faTwitter, faFacebookF);

export default {
  name: "Header",
  data() {
    return {
      myIcon: {
        faTwitter,
        faFacebookF,
      }
    }
  },
  components: {
    FontAwesomeIcon
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';
.header {
  position: fixed;
  width: 100%;
  height: 76px;
  background-color: #fff;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #D3D3D3;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 30;

  .navbar {
    max-width: 1140px;
    display: flex;
    position: relative;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0;

    .logo {
      display: flex;
      width: 15%;
      align-content: center;
      align-items: center;

      a {
        display: inline-block;
        width: 103px;
        height: 53px;

        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
          display: inline-block
        }
      }
    }

    .gnb {
      display: flex;
      width: 70.692%;

      .menu {
        flex-wrap: wrap;
        display: flex;
        padding: 0;
        justify-content: flex-end;
        margin-left: auto;
        -webkit-box-pack: end;

        .link {
          display: block;
          list-style: none;
          margin: 0 12px 0 0;
          padding: 0;
          line-height: normal;
          -webkit-tap-highlight-color: rgba(0,0,0,0);

          &:last-child {
            margin-right: 0;

            > a {
              margin-right: 0;
            }
          }

          > a , .link-inner {
            position: relative;
            display: flex;
            padding: 28px 20px;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: $lato;

            > span {
              transition: .4s;
              color: #54595f;
            }

            &:hover {
              > span {
                color: $primary-color;
                transform: scale(1.2);
              }
              .sub-menu {
                display: block;
              }
            }
          }

          &:hover {
            > a {
              span {
                color: $primary-color;
                transform: scale(1.2);
              }
            }
          }

          &.on {
            > a , .link-inner {
              > span {
                color: $primary-color;
              }
            }
          }

          .sub-menu {
            display: none;
            position: absolute;
            top: 76px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #fff;
            border-left: 1px solid #d1d1d1;
            border-right: 1px solid #d1d1d1;
            -webkit-tap-highlight-color: rgba(0,0,0,0);

            .sub-link {
              width: 128px;
              height: 46px;
              border-bottom: 1px solid #d1d1d1;
              display: flex;
              list-style: none;
              margin: 0;
              padding: 0;
              line-height: normal;

              > a {
                position: relative;
                font-family: $lato;
                font-size: 15px;
                font-weight: 600;
                text-transform: uppercase;
                color: #333333;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                transition: .4s;

                &:hover {
                  color: #ff9800;
                }
              }
              &.on {
                > a {
                  color: #ff9800;
                }
              }
            }
          }
        }
      }
    }

    .social-icon-wrap {
      display: flex;
      width: 10.234% + 4.074%;
      align-items: center;
      justify-content: flex-end;

      .btn-icon {
        border-radius: 50%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        transition: all .3s;
        font-size: $icon-size;
        line-height: $icon-size;
        width: 34px;
        height: 34px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        margin-right: 18px;

        &:last-child {
          margin-right: 0;
        }

        &.btn-twitter {
          background-color: #1da1f2;
          color: #fff;
        }

        &.btn-facebook {
          background-color: #3b5998;
          color: #fff;
        }

        &.btn-search {
          font-size: 20px;
        }
      }
    }
  }

  .mob-navbar {
    display: none;
  }
}

@media (min-width: 719px) and (max-width: 999px) {
  .header {
    .navbar {
      .gnb {
        .menu {
          .link {
            > a {
              padding: 28px 7px;
            }
          }
        }
      }
      .social-icon-wrap {
        .btn-icon {
          margin-right: 3px;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .header .navbar .gnb .menu .link {
    margin: 0 5px 0 0;
  }
  .header .navbar .gnb .menu .link > a ,
  .header .navbar .gnb .menu .link .link-inner {
    padding: 28px 10px;
  }
}

@media (max-width: 999px) {
  .header {
    .navbar {
      .logo {
        width: 15%;
      }
      .logo {
        a {
          width: 89px;
          height: 47px;
        }
      }
      .gnb {
        .menu {
          .link {
            > a , .link-inner {
              font-size: 13px;
              padding: 30px 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 760px) {
  .header .navbar .gnb .menu .link {
    margin: 0;
  }
  .header .navbar .gnb .menu .link > a ,
  .header .navbar .gnb .menu .link > div {
  }
}

@media (max-width: 719px) {
  .header {
    height: 90px;

    .navbar {
      display: none;
    }

    .mob-navbar {
      position: relative;
      display: flex;
      width: 100%;
      height: 90px;
      padding: 12px 20px 10px 20px;

      .logo {
        display: flex;
        width: 50%;
        align-items: center;
        
        img {
          width: 200px;
          max-width: 65%;
        }
      }

      .gnb {
        display: flex;
        width: 50%;

        .menu-toggle {
          margin-left: auto;
          background-color: rgba(0,0,0,0);
          font-size: 30px;
          padding: .25em;
          cursor: pointer;
          border: 0;
          justify-content: center;
          align-items: center;
          -webkit-box-pack: center;
          display: flex;
          -webkit-box-align: center;

          &:hover {
            color: #ea9041;
          }

          &.not-collapsed {
            i {
              &::before {
                content: "\e87f";
              }
            }
          }
        }
      }
      .menu-2depth {
        width: 100%;
        border: solid 1px #d1d1d1;
        background-color: #ffe300;
        transform: scaleY(1);
        max-height: 100vh;
        position: absolute;
        z-index: 9997;
        transform-origin: top;
        font-size: 13px;
        left: 0;

        .menu {
          display: flex;
          width: 100%;
          flex-direction: column;

          .link {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            border-bottom: 1px solid #d1d1d1;

            &:last-child {
              border-bottom: 0;
            }

            a {
              width: 100%;
              padding: 18px 20px;
              line-height: 20px;
              font-family: $lato;
              font-size: 17px;
              font-weight: 600;
              text-transform: uppercase;
              color: #333333;
            }
            &:hover, &.on {
              a {
                background-color: #353437;
                color: #ff8922;
              }
            }
            &.link-2depth {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              &:hover, &.on {
                a {
                  background-color: transparent;
                  color: #333;
                }
              }

              a.not-collapsed {
                background-color: #353437;
                color: #ff8922;
              }

              .menu-3depth {
                display: flex;
                width: 100%;

                .sub-menu {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  background-color: #fff;

                  .link {
                    &:hover, &.on {
                      a {
                        background-color: #fff;
                        color: #ff9800;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
