<template>
  <div class="to-top" id="btnToTop">
    <button class="btn-to-top" @click="toTop"><font-awesome-icon :icon="['fa', 'angle-up']" /></button>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

library.add(faAngleUp);

export default {
  name: "ToTop",
  data() {
    return {
      myIcon: {
        faAngleUp
      },
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("btnToTop").style.display = "block";
      } else {
        document.getElementById("btnToTop").style.display = "none";
      }
    },

    toTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  },
  components: {
    FontAwesomeIcon
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';
.to-top {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: rgba(0,0,0,0.4);
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  text-align: center;
  z-index: 10;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  .btn-to-top {
    display: block;
    width: 100%;
    height: 100%;
  }

  .fa-angle-up {
    color: #fff;
    font-size: 18px;
  }

  &:hover {
    background-color: rgba(0,0,0,0.8);
  }
}
</style>
