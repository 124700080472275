<template>
  <div>
    <Header title="Product"/>
    <div class="product-wrap">
      <!-- section -->
      <div class="section-bg">
        <div class="inner">
          <div class="img">
            <img width="754" height="425"
                 src="~@/assets/img/en/Product/logo_kitkitphase3@0.5x.png" alt=""
                 srcset="~@/assets/img/en/Product/logo_kitkitphase3@0.5x.png 754w,
                 ~@/assets/img/en/Product/logo_kitkitphase3@0.5x-300x169.png 300w"
                 sizes="(max-width: 754px) 100vw, 754px">
          </div>
          <h2 class="heading-title">A Comprehensive Early Learning<br>Solution for ALL Children</h2>
        </div>
      </div>

      <!-- section -->
      <div class="section-video">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <div class="video-wrap">
                <div class="video" id="player">
                  <youtube class="video-iframe" :video-id="videoId" ref="youtube" @playing="playing" />
                </div>
                <div class="img-overlay" id="overlay" :style="{'background-image': 'url(' + require('../assets/img/en/Product/kitkitmaindp@4x.png') + ')'}">
                  <button class="play-button" @click="playVideo"><i class="eicon-play" aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
            <div class="section-col">
              <div class="img">
                <img width="1024" height="680" src="~@/assets/img/en/Product/kitkitnumber@3x.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-banner">
        <div class="inner">
          <div class="text-box">
            <p>We created Kitkit School to bring high-quality learning experiences to the children who need them the
              most around the globe. Named for the word “to think” in Thai, Kitkit School is a tablet-based application
              with a comprehensive curriculum that spans early childhood through early elementary. It is designed to
              provide children with the foundations and practice needed to build fundamental skills in literacy and
              numeracy <span>along with opportunities to explore other subjects including music and art —</span>
              regardless of access to school or resources.</p>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-whats-inside">
        <div class="inner">
          <div class="heading-area">
            <h2 class="heading-title">What's Inside</h2>
            <div class="desc">
              <p><span>Kitkit School is designed as a comprehensive learning platform that includes:</span></p>
              <p><strong>1. Game-based Kitkit Learning App&nbsp; &nbsp; &nbsp; &nbsp;2. Library&nbsp; &nbsp; &nbsp; &nbsp;3. Tools</strong></p>
            </div>
          </div>
          <div class="item-box">
            <div class="title-area">
              <div class="heading-title-row">
                <h2 class="heading-title">1. Learning App</h2>
              </div>
              <div class="desc">
                <p><span>The Kitkit Learning App is a suite of interactive educational activities that let children learn and practice foundational </span><b>literacy</b><span> and </span><b>math</b><span> skills. The current version of Kitkit School includes </span><b>22
                  egg courses </b><span>covering early childhood literacy and numeracy with about </span><b>2,400
                  learning activities.</b></p>
              </div>
            </div>
            <div class="item-box-row">
              <div class="item">
                <div class="img"><img width="551" height="362" src="~@/assets/img/en/Product/learnapp_tablet1@2x.png" alt=""></div>
                <div class="desc">
                  <ul class="dotted-list">
                    <li><span>Provides foundational digital literacy and early childhood skills necessary for later literacy and numeracy learning</span>
                    </li>
                    <li><span>A Sequenced Progression of core literacy skills, from letter recognition to phonics and print awareness. Sequential courses introduce new skills and reinforce previously covered concepts at more difficult levels</span>
                    </li>
                    <li><span>Key Learning Areas: Oral language / Print awareness / Alphabetic principle / Phonemic awareness / Phonics / &nbsp;Spelling / Syntax / Vocabulary / Fluency / Written expression / Reading comprehension</span>
                    </li>
                    <li><span>11 Egg Courses / About 280 Sessions / About 1,200 Learning Activities</span></li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <div class="img"><img width="551" height="362" src="~@/assets/img/en/Product/learningapp_tablet2.png" alt=""></div>
                <div class="desc">
                  <ul class="dotted-list">
                    <li><span>Built on the team’s experience creating Todo Math, an award-winning, comprehensive Pre-K – 2nd Grade math learning suite with more than 6 million downloads worldwide&nbsp;</span></li>
                    <li>Uses multiple visual representations and digital manipulatives that help children
                      develop a deep, tactile understanding of math concepts
                    </li>
                    <li>Universal math curriculum that spans number tracing through multiplication</li>
                    <li><span>11 Egg Courses / About 260 Sessions / About 1,200 Learning Activities</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="item-box-row">
              <div class="item">
                <div class="title-area">
                  <div class="heading-title-row">
                    <h2 class="heading-title">2. Library</h2>
                  </div>
                </div>
                <div class="img">
                  <img width="551" height="362" src="~@/assets/img/en/Product/library_tablet.png" alt="">
                </div>
                <div class="desc">
                  <ul class="dotted-list">
                    <li><b>Multi-Leveled Books: </b>Features over 130<span>&nbsp;culturally appropriate books at seven different reading levels to provide “just right” reading opportunities</span></li>
                    <li><b>Read Aloud Feature: </b><span>By tapping a speaker icon, children can hear pages book read aloud. Children can select individual words to hear them read aloud, increasing engagement and comprehension</span></li>
                    <li><b>Variety of Genres &amp; Engaging Topics: </b><span>Expose children to a variety of genres, including fiction, nonfiction, folk tales, and biographies. Cover a variety of engaging topics, including animals, science, friendship, and role models</span></li>
                    <li><b>Culturally Appropriate Content: </b><span>Designed to be culturally appropriate for children in developing countries. Feature a balance of male and female characters to promote a diversity of positive gender role models. Developed by Enuma<sup>®</sup> or in collaboration with Ubongo, a Tanzania-based Children’s Media Company</span></li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <div class="title-area">
                  <div class="heading-title-row">
                    <h2 class="heading-title">3. Tools</h2>
                  </div>
                </div>
                <div class="img"><img width="977" height="641" src="~@/assets/img/en/Product/tooldashboard@0.5x.png" alt=""></div>
                <div class="desc">
                  <ul class="dotted-list">
                    <li><span>Help children build digital competency and </span><span>offer children important opportunities for self-expression through art and music</span></li>
                    <li><span>Children select among eight learning tools that support self-confidence in their ideas and abilities</span></li>
                    <li><span>Include virtual instruments, a black-board to practice writing, and drawing and coloring tools to create unique artworks</span></li>
                    <li><span>Our field-testing to date has demonstrated children’s engagement with these tools, which allow them the chance to develop and showcase their creativity</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-why-kitkit-school">
        <div class="inner">
          <h4 class="heading-title">Why Kitkit School</h4>
          <div class="item-box">
            <div class="item">
              <figure class="img"><img width="312" height="318" src="~@/assets/img/en/Product/foundskill@4x.png" class="elementor-animation-grow attachment-full size-full" alt="" sizes="(max-width: 312px) 100vw, 312px"></figure>
              <h3 class="image-box-title">Foundational Skills</h3>
              <p class="image-box-description">Learning is scaffolded to support cognitive development and self-exploration as students learn reading, writing, and math</p>
            </div>
            <div class="item">
              <figure class="img"><img width="240" height="312" src="~@/assets/img/en/Product/learning@4x.png" class="elementor-animation-grow attachment-full size-full" alt="" sizes="(max-width: 240px) 100vw, 240px"></figure>
              <h3 class="image-box-title">Independent Learning</h3>
              <p class="image-box-description">A truly child-centered, highly intuitive interface ensures that every child has the ability to succeed</p>
            </div>
            <div class="item">
              <figure class="img"><img width="324" height="287" src="~@/assets/img/en/Product/accessible@4x.png" class="elementor-animation-grow attachment-full size-full" alt="" sizes="(max-width: 324px) 100vw, 324px"></figure>
              <h3 class="image-box-title">Accessibility</h3>
              <p class="image-box-description">Nature-themed graphics and accessibility functions engage and empower the world's diverse learners</p>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-more-about-kitkit">
        <div class="inner">
          <h1 class="heading-title">Learn More About Kitkit School’s Learning Philosophy, Design Approach, and Curricular Framework</h1>
          <div class="desc-wrap">
            <div class="left">
              <div class="img"><img width="712" height="792" src="~@/assets/img/en/Product/kitkithandbookcover.png" alt=""></div>
              <div class="btn-wrap">
                <a class="btn-border-white" href="/doc/Kitkit-Handbook_V3a.pdf" target="_blank" >LEARN MORE</a>
              </div>
            </div>
            <div class="right">
              <p><span>Kitkit School combines </span><b>international best practices</b><span> in literacy and math education with </span><b>Universal Design for Learning</b><span> principles to help every child succeed as an independent learner. Kitkit School’s flexible learning architecture comes to life with an intuitive and engaging user experience that overcomes cultural boundaries and creates positive educational outcomes for all the world’s diverse children. </span>You can learn more about our learning philosophy, design, and research used to create Kitkit School by downloading our <strong>Kitkit School Learning and Design Document</strong><b>&nbsp;</b>here.</p>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
<!--      <div class="section-coming-soon">-->
<!--        <div class="inner">-->
<!--          <div class="section-row">-->
<!--            <div class="section-col">-->
<!--              <div class="img-coming-soon" id="imageOpacity">-->
<!--                <img src="~@/assets/img/en/Product/comingsoon@2x.png" alt="">-->
<!--              </div>-->
<!--              <h3>Maintenance Made Simple!</h3>-->
<!--              <ul class="dotted-list">-->
<!--                <li>Centrally manage everything from Chrome web browser</li>-->
<!--                <li>Track learner progress and collect insightful user data</li>-->
<!--                <li>Automatic data sync</li>-->
<!--                <li>Bite-sized video tutorials for administrators</li>-->
<!--              </ul>-->
<!--            </div>-->
<!--            <div class="section-col">-->
<!--              <div class="img"><img width="1219" height="971" src="~@/assets/img/en/Product/analysis@4x.png" alt=""></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!-- section -->
      <div class="section-supported">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <div class="image-box-wrapper">
                <figure class="img"><img src="~@/assets/img/en/Product/hardware@2x.png" alt=""></figure>
                <div class="image-box-content">
                  <h3 class="image-box-title">Supported HARDWARE</h3>
                  <p class="image-box-description">Minimum Tablet Specifications</p>
                </div>
              </div>
              <ul class="dotted-list">
                <li>Supported OS version:&nbsp;<b>Android 6.0 ~ 9.0</b></li>
                <li><span>RAM: <strong>2.0 GB</strong> (minimum 1.7 GB) </span></li>
                <li><span>Storage:<strong>&nbsp;16GB</strong> with 6GB available space</span></li>
                <li><span>Screen resolution <strong>1280 x 800</strong></span></li>
                <li><span>Continuous internet connection is not required</span></li>
              </ul>
            </div>
<!--            <div class="section-col">-->
<!--              <div class="image-box-wrapper">-->
<!--                <figure class="img">-->
<!--                  <img src="~@/assets/img/en/Product/language_1.png" alt="">-->
<!--                </figure>-->
<!--                <div class="image-box-content">-->
<!--                  <h3 class="image-box-title">Supported LANGUAGE</h3>-->
<!--                  <p class="image-box-description">Currently Available in Swahili and English</p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <ul class="dotted-list">-->
<!--                <li><span>Kitkit School is a globally-minded product that can be easily adapted for use in other languages and contexts.</span></li>-->
<!--              </ul>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'

export default {
  name: "Product",
  head: {
    title: {
      inner: 'Product',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Kitkit School' },
      { name: 'twitter:image', content: require('../assets/img/common/header_app_banner_kitkitschool-2.png')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Product - Kitkit School' },
      { property: 'og:description', content: 'PRESS Featured News |  Resources & Press Releases  |  Videos  |  Photos Featured News Photos' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/logo_kitkitphase3@0.5x.png') },
      { property: 'og:url', content: 'http://kitkitschool.com/product/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/product/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  data() {
    return {
      videoId: 'ZaB3FE2eRJk',
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleOnOff(){
      this.activeMovie = !this.activeMovie;
    },
    handleScroll () {
      var scrollHeight = document.body.scrollHeight;
      var currentYOffset = window.pageYOffset;

      const partScrollStart = 0.8 * scrollHeight;
      const partScrollEnd = 0.2 * scrollHeight;
      const partScrollHeight = partScrollEnd - partScrollStart;
      let opacityValue = ( currentYOffset - partScrollStart ) / partScrollHeight * 1 + 0.7;

      var target = document.getElementById("imageOpacity");
      if(currentYOffset > 4300 && currentYOffset < 4900) {
        target.style.opacity = 1;
        target.style.transition = "all 2s;";
        target.style.opacity = opacityValue;
      }

      else {
        target.style.opacity = 0;
        target.style.transition = "all 2s";
      }
    },
    playVideo() {
      this.player.playVideo()
    },
    playing() {
      var overlay = document.getElementById("overlay");
      overlay.style.display = "none";
    },
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.product-wrap {
  padding-top: 76px;

  img {
    vertical-align: middle;
    display: inline-block;
    height: auto;
  }

  .inner {
    margin: 0 auto;
  }

  .dotted-list {
    padding: 0 0 0 20px;

    li {
      margin: 15px 0 15px 20px;
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5em;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-bg {
    background-image: url(~@/assets/img/en/Product/header_app_banner_kitkitschool-2.png);
    background-position: bottom center;
    background-size: cover;

    .inner {
      display: flex;
      max-width: 800px;
      min-height: 450px;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;

      .img {
        text-align: center;
        margin-bottom: 20px;

        img {
          max-width: 40%;
        }
      }

      .heading-title {
        text-align: center;
        color: #ffffff;
        font-family: $lato;
        font-size: 34px;
        line-height: 1.6em;
      }
    }
  }

  .section-video {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    padding: 80px 0 0 0;
    z-index: 9;

    .inner {
      max-width: 1140px;
      display: flex;
      margin-right: auto;
      margin-left: auto;
      position: relative;
    }

    .section-row {
      display: flex;
      width: 100%;

      .section-col {
        &:first-child {
          width: 60%;
        }

        &:nth-child(2) {
          width: 40%;
          padding: 0 0 0 50px;
        }
      }
    }

    .video-wrap {
      position: relative;
      border-radius: 15px 15px 15px 15px;
      border-style: solid;
      border-width: 10px 30px 10px 30px;
      border-color: #000000;
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

      .video {
        position: relative;
        padding-bottom: 56.25%;
        .video-iframe {
          // common.scss 에 아래 소스 이동 - 2021.11.3
          //position: absolute;
          //top: 0;
          //left: 0;
          //height: 100%;
          //border: 0;
          //max-width: 100%;
          //width: 100%;
          //margin: 0;
          //line-height: 1;
          //border: none;
        }
      }

      .img-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-background-size: cover;
        background-size: cover;
        background-position: 50%;
        padding-bottom: 56.25%;

        .play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          i {
            opacity: .8;
            text-shadow: 1px 0 6px rgba(0, 0, 0, .3);
            -webkit-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
            color: #ffffff;
            font-size: 90px;
          }
        }
      }
    }

    .img {
      width: 100%;
      text-align: center;

      img {
        max-width: 100%;

      }
    }
  }

  .section-banner {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: -40px;
    margin-bottom: 0;
    background-color: #5555a3;

    .inner {
      max-width: 1140px;
      padding: 80px 100px 20px 100px;

      .text-box {
        padding: 10px;
        text-align: center;

        p {
          color: #ffffff;
          font-family: $lato;
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .section-whats-inside {
    padding: 80px 0 0 0;
    text-align: center;

    .inner {
      max-width: 1140px;
      padding: 10px;
    }

    .heading-area {
      .heading-title {
        color: #333333;
        font-family: $ubuntu;
        font-size: 52px;
        font-weight: 600;
        margin-bottom: 20px;
        padding: 0 0 20px 0;
      }

      .desc {
        padding: 8px 0 0 0;

        p {
          color: #333333;
          font-family: $lato;
          font-size: 22px;
          line-height: 1em;
          margin-bottom: 20px;

          strong {
            font-weight: 600;
          }
        }
      }
    }

    .item-box {
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      padding: 60px 0 80px 0;
      text-align: left;

      .title-area {
        .heading-title-row {
          display: flex;
          margin-bottom: 20px;

          .heading-title {
            width: 50%;
            color: #333333;
            font-family: $ubuntu;
            font-size: 38px;
            font-weight: 800;
            line-height: 48px;
            text-align: left;

            &:nth-child(2) {
              padding-left: 50px;
            }
          }
        }

        .desc {
          p {
            color: #333333;
            font-family: $lato;
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 20px;
            line-height: 36px;

            b {
              font-weight: 600;
            }
          }
        }
      }

      .item-box-row {
        display: flex;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

        &:nth-child(2) {
          padding: 0 0 80px 0;
        }

        .item {
          width: 50%;

          &:first-child {
            padding-right: 50px;
          }

          &:nth-child(2) {
            padding-left: 50px;
          }

          .img {
            width: 100%;
            margin-bottom: 20px;

            img {
              vertical-align: middle;
              display: inline-block;
              height: auto;
              max-width: 100%;
              border: none;
            }
          }
        }
      }
    }
  }

  .section-why-kitkit-school {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0 100px 0;
    background-color: #f7f7f7;
    text-align: center;

    .inner {
      max-width: 1140px;
    }

    .heading-title {
      color: #333333;
      font-family: $ubuntu;
      font-size: 52px;
      font-weight: 600;
      line-height: 72px;
      vertical-align: top;
      margin-bottom: 20px;
    }

    .item-box {
      display: flex;
      padding-top: 20px;

      .item {
        width: 33.33333333%;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, .09);
        transition: background 0.3s, border 2s, border-radius 2s, box-shadow 2s;
        padding: 35px;
        background-color: #ffffff;

        &:first-child {
          margin: 0 20px 0 0;
        }

        &:nth-child(2) {
          margin: 0 10px;

          .img {
            width: 19%;
          }
        }

        &:nth-child(3) {
          margin: 0 0 0 20px;

          .img {
            width: 28%;
          }
        }

        .img {
          width: 25%;
          margin: 0 auto 25px;

          img {
            height: auto;
            max-width: 100%;
            border: none;
            -webkit-border-radius: 0;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            display: block;
            margin: 0 auto;
            transition-duration: 0.3s;

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .image-box-title {
          color: #333333;
          font-family: $lato;
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 20px;
        }

        .image-box-description {
          color: #777777;
          font-family: $lato;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.6em;
        }
      }
    }
  }

  .section-more-about-kitkit {
    background-color: #7179c9;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 80px 0 60px 0;

    .inner {
      max-width: 1140px;
      padding: 10px;
    }

    .heading-title {
      text-align: center;
      color: #ffffff;
      font-family: $ubuntu;
      font-size: 40px;
      font-weight: 600;
      line-height: 56px;
    }

    .desc-wrap {
      display: flex;

      .left {
        width: 35%;
        padding-top: 40px;
        text-align: center;

        .img {
          margin-bottom: 20px;
          width: 100%;
          text-align: center;

          img {
            max-width: 70%;
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            border-color: #ffffff;
            vertical-align: middle;
            display: inline-block;
            height: auto;
          }
        }

        .btn-border-white {
          display: inline-block;
          line-height: 1;
          -webkit-border-radius: 3px;
          text-align: center;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
          font-family: $lato;
          font-weight: 600;
          border-color: #fff;
          font-size: 1.8rem;
          text-transform: uppercase;
          background-color: transparent;
          color: #fff;
          border-style: solid;
          border-width: 3px 3px 3px 3px;
          border-radius: 25px 25px 25px 25px;
          padding: 15px 45px 15px 45px;

          &:hover {
            color: #7179c9;
            background-color: #ffffff;
          }
        }
      }

      .right {
        width: 65%;
        padding: 30px 0 0 50px;

        p {
          color: #ffffff;
          font-family: $lato;
          font-size: 22px;
          line-height: 2em;
          font-weight: 400;
          margin-bottom: 20px;

          b {
            font-weight: 600;
          }
        }
      }
    }
  }

  .section-coming-soon {
    padding: 100px 0 80px 0;

    .inner {
      max-width: 1140px;
    }

    .section-row {
      display: flex;

      .section-col {
        width: 50%;

        &:first-child {
          padding: 0 10% 0 0;
        }
      }
    }

    .img-coming-soon {
      margin: -120px 0px 0px -10px;

      img {
        max-width: 50%;
      }
    }

    h3 {
      color: #4054b2;
      font-family: $ubuntu;
      font-size: 48px;
      font-weight: 700;
      line-height: 1.2em;
      margin-bottom: 20px;
    }

    .img {
      width: 100%;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 80%;
      }
    }
  }

  .section-supported {
    background-color: #f2f2f2;

    .inner {
      max-width: 1140px;
    }

    .section-row {
      display: flex;

      .section-col {
        width: 50%;

        &:first-child {
          padding: 80px 50px 60px 0;
        }

        &:nth-child(2) {
          padding: 80px 0 60px 50px;

          .image-box-wrapper {
            .img {
              width: 15%;
            }
          }
        }
      }
    }

    .image-box-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: left;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin-bottom: 20px;

      .img {
        display: inline-block;
        width: 20%;
        margin-right: 15px;
        margin-bottom: 0;

        img {
          max-width: 100%;
          transition-duration: 0.3s;
          height: auto;
        }
      }

      .image-box-content {
        width: 100%;
        display: flex;
        flex-direction: column;

        .image-box-title {
          font-family: $lato;
          font-size: 24px;
          line-height: 33px;
        }

        .image-box-description {
          color: #999999;
          font-family: $lato;
          font-size: 17px;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }

    .dotted-list {
      padding-left: 0;

      li {
        color: #333333;
        font-family: $lato;
        font-size: 16px;
        line-height: 19px;

        b, strong {
          font-weight: 600;
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .product-wrap {
    .section-bg {
      .inner {
        min-height: 450px;
      }
    }

    .section-video {
      .inner {
        max-width: 1025px;
      }
    }

    .section-banner {
      padding: 10px;

      .inner {
        max-width: 1025px;

        .text-box {
          padding: 0;
        }
      }
    }

    .section-whats-inside {
      .inner {
        max-width: 1025px;
      }
    }

    .section-why-kitkit-school {
      padding: 80px 25px 100px 25px;

      .inner {
        max-width: 1025px;
      }

      .item-box {
        .item {
          &:first-child {
            margin: 0 10px 0 0;

            .img {
              width: 32%;
            }
          }

          &:nth-child(2) {
            margin: 0 10px 0 0;

            .img {
              width: 25%;
            }
          }

          &:nth-child(3) {
            margin: 0 10px 0 0;

            .img {
              width: 37%;
            }
          }

          .img {
            width: 32%;
          }

          .image-box-description {
            font-size: 14px;
            line-height: 22.4px;
          }
        }
      }
    }

    .section-more-about-kitkit {
      .inner {
        max-width: 1025px;
      }

      .heading-title {
        font-size: 38px;
      }

      .desc-wrap {
        .left {
          width: 30%;
          padding-top: 20px;

          .img {
            img {
              max-width: 85%;
            }
          }

          .btn-border-white {
            font-size: 16px;
            padding: 12px 30px 12px 30px;
          }
        }

        .right {
          width: 70%;
          margin: 0;
          padding: 10px 0 0 20px;

          p {
            margin: 0;
            padding: 0;
            font-size: 18px;
            line-height: 1.9em;

            b {
              font-weight: 600;
            }
          }
        }
      }
    }

    .section-coming-soon {
      padding: 60px 25px 50px 25px;

      .inner {
        max-width: 1025px;
      }

      .section-row {
        display: flex;

        .section-col {
          width: 50%;

          &:first-child {
            padding: 0 5% 0 0;
          }
        }
      }

      .img-coming-soon {
        margin: -75px 0 0 0;
      }

      h3 {
        font-size: 35px;
      }
    }

    .section-supported {
      .inner {
        max-width: 1025px;
      }

      .section-row {
        .section-col {
          &:first-child {
            padding: 50px 10px 0 20px;
          }

          &:nth-child(2) {
            padding: 50px 20px 0 10px;
          }
        }
      }

      .dotted-list {
        padding-bottom: 20px;
        margin-bottom: 20px;

        li {
          line-height: 28px;
          margin: 0 0 0 20px;

          b, strong {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .product-wrap {
    .section-bg {
      background-position: -30px 0px;

      .inner {
        min-height: 400px;

        .img {
          img {
            max-width: 60%;
          }
        }

        .heading-title {
          font-size: 22px;
        }
      }
    }

    .section-video {
      padding: 50px 15px 0 15px;

      .inner {
        max-width: 768px;

        .section-row {
          flex-direction: column;

          .section-col {
            width: 100%;

            &:first-child {
              -webkit-box-ordinal-group: 11;
              -ms-flex-order: 10;
              order: 10;
            }

            &:nth-child(2) {
              -webkit-box-ordinal-group: 10;
              -ms-flex-order: 9;
              order: 9;
              padding-left: 0;
            }
          }
        }

        .img {
          padding: 0 10px 20px 10px;
        }
      }
    }

    .section-banner {
      padding: 20px 20px 40px 20px;

      .inner {
        max-width: 768px;
        padding: 0;

        .text-box {
          padding: 50px 0 0 0;

          p {
            font-size: 18px;
          }
        }
      }
    }

    .section-whats-inside {
      padding: 0;

      .inner {
        max-width: 768px;
      }

      .heading-area {
        padding: 50px 15px 0 15px;

        .heading-title {
          font-size: 34px;
        }

        .desc {
          p {
            font-size: 20px;
            line-height: 1.5em;
          }
        }
      }

      .item-box {
        padding: 60px 10px 50px 10px;

        > .title-area {
          .desc {
            p {
              margin-bottom: 0;
            }
          }
        }

        .title-area {
          .heading-title-row {
            .heading-title {
              width: 100%;
              font-size: 28px;

              &:nth-child(2) {
                padding-left: 0;
              }
            }
          }

          .desc {
            p {
              font-size: 18px;
              line-height: 32.4px;
            }
          }
        }

        .item-box-row {
          flex-direction: column;

          &:nth-child(2) {
            padding: 0 0 80px 0;
          }

          &:nth-child(3) {
            .item {
              .img {
                padding-top: 0;
              }

              &:nth-child(2) {
                margin-top: 50px;
              }
            }
          }

          .item {
            width: 100%;

            &:first-child {
              padding-right: 0;
            }

            &:nth-child(2) {
              padding-left: 0;
            }

            .img {
              width: 100%;
              text-align: center;
              margin-top: 0;
              margin-bottom: 38px;
              padding-top: 50px;
            }
          }
        }
      }
    }

    .section-why-kitkit-school {
      .inner {
        max-width: 1025px;
      }

      .item-box {
        padding: 25px 0 0 0;
        flex-direction: column;

        .item {
          width: 100%;
          padding: 35px;

          &:first-child {
            margin: 0;
          }

          &:nth-child(2) {
            margin: 20px 0 0 0;

            .img {
              width: 25%;
            }
          }

          &:nth-child(3) {
            margin: 20px 0 0 0;

            .img {
              width: 37%;
            }
          }

          .img {
            width: 32%;
          }

          .image-box-description {
            font-size: 14px;
            line-height: 22.4px;
          }
        }
      }
    }

    .section-more-about-kitkit {
      margin-top: 0;
      margin-bottom: 0;
      padding: 70px 10px 50px 10px;

      .inner {
        max-width: 768px;
        padding: 0;
        margin: 0;
      }

      .heading-title {
        font-size: 28px;
      }

      .desc-wrap {
        flex-direction: column;

        .left {
          width: 100%;
          padding-top: 20px;
          -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
          order: 10;

          .img {
            img {
              max-width: 85%;
            }
          }

          .btn-border-white {
            padding: 15px 92px 15px 92px;
            margin-bottom: 20px;
          }
        }

        .right {
          width: 100%;
          margin: 0 0 20px;
          padding: 20px 10px 0;
          -webkit-box-ordinal-group: 10;
          -ms-flex-order: 9;
          order: 9;
        }
      }
    }

    .section-coming-soon {
      padding: 50px 20px 50px 20px;

      .inner {
        max-width: 768px;
      }

      .section-row {
        flex-direction: column;

        .section-col {
          width: 100%;

          &:first-child {
            padding: 0 5% 0 0;
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;
          }

          &:nth-child(2) {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
          }
        }
      }

      .img-coming-soon {
        margin: -50px 0 20px -10px;
        padding: 0;

        img {
          width: 50%;
        }
      }
    }

    .section-supported {
      padding: 0 15px 30px 15px;

      .inner {
        max-width: 768px;
      }

      .section-row {
        flex-direction: column;

        .section-col {
          width: 100%;

          &:first-child {
            padding: 0;
          }

          &:nth-child(2) {
            padding: 0;

            .dotted-list {
              margin-bottom: 20px;
            }
          }
        }
      }

      .image-box-wrapper {
        padding-top: 50px;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        .img {
          margin: 0 auto 10px;
        }
      }

      .dotted-list {
        margin-bottom: 0;
      }
    }
  }
}
</style>
