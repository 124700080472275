<template>
  <div>
    <Header title="Home"/>
    <div class="home">
      <div class="inner">

        <!-- section -->
        <div class="main-slider" id="mainSlider">
          <b-carousel id="carousel" fade :interval="4000">
            <b-carousel-slide>
              <div class="bg"></div>
              <div class="img">
                <img class="img-tablet" src="~@/assets/img/en/Home/header-slide-1-720@2x.png" alt="">
                <img class="img-mobile" src="~@/assets/img/en/Home/header-slide-1-m@2x.png" alt="">
              </div>
              <div class="inner">
                <div class="heading-title">
                  Kitkit School Enables Children<br/>
                  to Learn Early Grade Skills<br/>
                  Wherever They May Be
                </div>
                <div class="desc">
                  More than 350 organizations in over 50 countries used<br/>
                  Kitkit School, making continuous learning possible.
                </div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <div class="bg"></div>
              <div class="img">
                <img class="img-tablet" src="~@/assets/img/en/Home/header-slide-720-2@2x.png" alt="">
                <img class="img-mobile" src="~@/assets/img/en/Home/header-slide-2-m@2x.png" alt="">
              </div>
              <div class="inner">
                <div class="img-paper-plane"></div>
                <div class="heading-title">
                  Tablet-Based Learning<br/>
                  in Tanzanian Public Schools
                </div>
                <div class="desc">
                  Twiga Primary School models an after-school digital<br/>
                  learning program run by teachers with Camara Education.
                </div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <div class="bg"></div>
              <div class="img">
                <img class="img-tablet" src="~@/assets/img/en/Home/Header_Slide_3_720@2x.png" alt="">
                <img class="img-mobile" src="~@/assets/img/en/Home/Header_Slide_3_m@2x.png" alt="">
              </div>
              <div class="inner">
                <div class="img-paper-plane"></div>
                <div class="heading-title">
                  Literacy Education<br/>
                  in Refugee Camps At Scale
                </div>
                <div class="desc">
                  190,000 Rohingya children in Cox's Bazar, Bangladesh,<br/>
                  in partnership with International Rescue Committee
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>

        <!-- section -->
        <div class="section-sponsored">
          <div class="inner">
            <div class="sponsored-by">
              <div class="img-wrap">
                <div class="img img-1"><img src="~@/assets/img/common/koica.png" alt=""></div>
                <div class="img img-2"><img src="~@/assets/img/common/GLEXP Logo.png" alt=""></div>
                <div class="img img-3"><img src="~@/assets/img/common/UNSTI Winner Logo.png" alt=""></div>
                <div class="img img-4"><img src="~@/assets/img/common/LEAD Innovation Logo.png" alt=""></div>
                <div class="img img-5"><img src="~@/assets/img/common/irc.png" alt=""></div>
                <div class="img img-6"><img src="~@/assets/img/common/ub.png" alt=""></div>
                <div class="img img-7"><img src="~@/assets/img/common/gn.png" alt=""></div>
                <div class="img img-8"><img src="~@/assets/img/common/imagine.png" alt=""></div>
                <div class="img img-9"><img src="~@/assets/img/common/xp.png" alt=""></div>
                <div class="img img-10"><img src="~@/assets/img/common/ail.png" alt=""></div>
                <div class="img img-11"><img src="~@/assets/img/common/ce.png" alt=""></div>
              </div>
              <div class="img-wrap img-wrap-tablet">
                <div class="img img-1"><img src="~@/assets/img/common/koica.png" alt=""></div>
                <div class="img img-2"><img src="~@/assets/img/common/GLEXP Logo.png" alt=""></div>
                <div class="img img-3"><img src="~@/assets/img/common/UNSTI Winner Logo.png" alt=""></div>
                <div class="img img-5"><img src="~@/assets/img/common/irc.png" alt=""></div>
                <div class="img img-4"><img src="~@/assets/img/common/LEAD Innovation Logo.png" alt=""></div>
                <div class="img img-6"><img src="~@/assets/img/common/ub.png" alt=""></div>
                <div class="img img-8"><img src="~@/assets/img/common/imagine.png" alt=""></div>
                <div class="img img-9"><img src="~@/assets/img/common/xp.png" alt=""></div>
                <div class="img img-7"><img src="~@/assets/img/common/gn.png" alt=""></div>
                <div class="img img-10"><img src="~@/assets/img/common/ail.png" alt=""></div>
                <div class="img img-11"><img src="~@/assets/img/common/ce.png" alt=""></div>
              </div>
              <div class="img-wrap img-wrap-mobile">
                <div class="img img-1"><img src="~@/assets/img/common/koica.png" alt=""></div>
                <div class="img img-2"><img src="~@/assets/img/common/GLEXP Logo.png" alt=""></div>
                <div class="img img-3"><img src="~@/assets/img/common/UNSTI Winner Logo.png" alt=""></div>
                <div class="img img-4"><img src="~@/assets/img/common/LEAD Innovation Logo.png" alt=""></div>
                <div class="img img-7"><img src="~@/assets/img/common/gn.png" alt=""></div>
                <div class="img img-8"><img src="~@/assets/img/common/imagine.png" alt=""></div>
                <div class="img img-9"><img src="~@/assets/img/common/xp.png" alt=""></div>
                <div class="img img-5"><img src="~@/assets/img/common/irc.png" alt=""></div>
                <div class="img img-10"><img src="~@/assets/img/common/ail.png" alt=""></div>
                <div class="img img-6"><img src="~@/assets/img/common/ub.png" alt=""></div>
                <div class="img img-11"><img src="~@/assets/img/common/ce.png" alt=""></div>
              </div>
            </div>
          </div>
        </div>

        <!-- section -->
        <div class="section-video">
          <div class="inner">
            <div class="video-wrap">
              <div class="video-container">
                <div class="video" v-if="activeMovie" data-src="https://www.youtube.com/embed/HqAR5BK78RM?feature=oembed&amp;rel=0&amp;mute=0&amp;loop=0&amp;controls=1" style="background: rgb(0, 0, 0);">
                  <iframe src="https://www.youtube.com/embed/HqAR5BK78RM?feature=oembed&amp;rel=0&amp;mute=0&amp;loop=0&amp;controls=1&amp;autoplay=1" frameborder="0" allowfullscreen="1" allow="autoplay;encrypted-media;"></iframe>
                </div>
                <a class="video-link" @click="toggleOnOff">
                  <img src="~@/assets/img/en/Home/intro-movie2@2x.png" alt="">
                  <span class="btn-play" v-if="!activeMovie"><font-awesome-icon :icon="['fas', 'play']" /></span>
                </a>
              </div>
            </div>
            <div class="text-box">
              <h4 class="heading-title">Kitkit School<br> at a Glance</h4>
              <p class="desc">Discover what sets Kitkit School apart, from our <strong>learning approach</strong> to what educators are saying about us in the field.</p>
            </div>
          </div>
        </div>

        <!-- section -->
        <div class="section-our-product">
          <div class="inner">
            <div class="text-box">
              <h4 class="heading-title">Our Product</h4>
              <h5 class="heading-title-sm">Kitkit School</h5>
              <p class="text">Kitkit School is an exceptional learning tool built to <strong>teach all children early reading, writing and math</strong>. Kitkit School uses best practices and research in game design and literacy and numeracy education to support children’s engagement and learning.</p>
              <router-link class="btn" to="/product">Read More</router-link>
            </div>
            <div class="thumb-slider">
              <!-- swiper1 -->
              <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitkitimg1@4x.png') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitkitimg2@4x.png') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitimg6.jpg') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitimg7.jpg') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitkitmaindp@4x.png') + ')'}"></swiper-slide>
              </swiper>
              <!-- swiper2 Thumbs -->
              <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitkitimg1@4x.png') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitkitimg2@4x.png') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitimg6.jpg') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitimg7.jpg') + ')'}"></swiper-slide>
                <swiper-slide :style="{'background-image': 'url(' + require('../assets/img/en/Home/kitkitmaindp@4x.png') + ')'}"></swiper-slide>
              </swiper>
            </div>
          </div>
        </div>

        <!-- section -->
        <div class="section-banner">
          <div class="box">
            <div class="img" :style="{'background-image': 'url(' + require('../assets/img/en/Home/impact_bg3.jpg') + ')'}"></div>
            <div class="text-box-wrap">
              <div class="text-box">
                <h4 class="heading-title">Our Impact</h4>
                <h5 class="heading-title-sm">Powerful Results, Tangible Impact</h5>
                <p class="desc">The Enuma, Inc. team is committed to promoting an inclusive and equitable quality education where all
                  learners can succeed. Since its launch, Kitkit School is transforming learning and delivering powerful
                  results for children across the globe, regardless of their initial capacity and access to
                  resources. </p>
                <router-link class="btn-border-gray" to="/impact">Read More</router-link>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="text-box-wrap">
              <div class="text-box">
                <h4 class="heading-title">Our Team</h4>
                <h5 class="heading-title-sm">Who We Are</h5>
                <p class="desc">
                  Enuma is a leader in digital early learning solutions that are accessible to all children. Founded in 2012 with the mission of empowering children with special needs to learn independently, Enuma has worked to set new standards in user experience and accessible design for early learners.
                </p>
                <router-link class="btn-border-white" to="/aboutus">Read More</router-link>
              </div>
            </div>
            <div class="img" :style="{'background-image': 'url(' + require('../assets/img/en/Home/EnumaGroupPhoto1600.jpg') + ')'}"></div>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay , faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

library.add(faPlay, faMicrophone, faTwitter);

export default {
  name: "Home",
  head: {
    title: {
      inner: 'Home',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Kitkit School' },
      { name: 'twitter:description', content: 'Kitkit School is a comprehensive early learning solution built for ALL children.'},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Home - Kitkit School' },
      { property: 'og:description', content: 'Making Kitkit School Accessible to More Children Kitkit School is now available for free download in light of the global COVID-19 crisis. Learn More Making Kitkit School Accessible to More Children Kitkit School is now available for free download in light of the global COVID-19 crisis. Learn More Making Kitkit School Accessible to More Children […]' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/xprize-logo-black@4x-1024x251.png') },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  myIcon: {
    faPlay,
    faMicrophone,
    faTwitter
  },
  data() {
    return {
      thumbsSwiper: null,
      swiperOptionTop: {
        loop: true,
        autoplay : {
          delay : 3000,
          disableOnInteraction : false,
        },
        loopAdditionalSlides : 1,
        loopedSlides: 5,
        spaceBetween: 0,
        on : {
          init : function () {
            // console.log('swiper 초기화');
          },
          activeIndexChange: function () {
            // console.log(this.realIndex);
          }
        },
      },
      swiperOptionThumbs: {
        loop: true,
        autoplay : {
          delay : 15000,
          disableOnInteraction : false,
        },
        loopAdditionalSlides : 1,
        loopedSlides: 5,
        spaceBetween: 10,
        centeredSlides: false,
        slidesPerView: 5,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        breakpoints: {
          320: {
            slidesPerView: 3,
          },
          719: {
            slidesPerView: 4,
          },
          999: {
            slidesPerView: 5,
          }
        }
      },
      activeMovie: false
    };
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
    toggleOnOff(){
      this.activeMovie = !this.activeMovie;
    }
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
    FontAwesomeIcon : FontAwesomeIcon,
    Swiper,
    SwiperSlide
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
      const swiperThumbs = this.$refs.swiperThumbs.$swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.home {
  padding-top: 76px;

  .section-sponsored {
    width: 100%;

    .inner {
      width: 1000px;
      margin: 0 auto;
      padding-top: 50px;
      padding-bottom: 50px;

      .sponsored-by {
        .img-wrap {
          overflow: hidden;

          &.img-wrap-tablet {
            display: none;
          }

          &.img-wrap-mobile {
            display: none;
          }

          .img {
            display: inline-block;
            margin-right: 90px;

            &:hover {
              img {
                filter: grayscale(0);
              }
            }

            img {
              width: 100%;
              height: auto;
              filter: grayscale(100%);
            }

            &.img-1 {
              width: 121px;
              margin-bottom: 24px;
            }

            &.img-2 {
              width: 182px;
              margin-bottom: 24px;
              transform: translateY(-5px);
            }

            &.img-3 {
              width: 166px;
              margin-bottom: 24px;
              transform: translateY(-5px);
            }

            &.img-4 {
              width: 122px;
              margin-bottom: 24px;
              transform: translateY(-5px);
            }

            &.img-5 {
              width: 44px;
              margin-right: 0;
              margin-bottom: 24px;
            }

            &.img-6 {
              width: 140px;
              margin-right: 56px;
              transform: translateY(-5px);
            }

            &.img-7 {
              width: 65px;
              margin-right: 55px;
            }

            &.img-8 {
              width: 115px;
              margin-right: 56px;
            }

            &.img-9 {
              width: 113px;
              margin-right: 56px;
              transform: translateY(-5px);
            }

            &.img-10 {
              width: 126px;
              margin-right: 55px;
            }

            &.img-11 {
              width: 160px;
              margin-right: 0;
              transform: translateY(-5px);
            }
          }
        }
      }
    }
  }

  .section-video {
    background-color: #FCF2D2;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0;

    .inner {
      width: $min-width;
      margin: 0 auto;

      &::after {
        content: '';
        display: block;
        clear: both;
      }

      .video-wrap {
        float: left;
        width: 50%;

        .video-container {
          position: relative;
          width: 100%;
          height: 320px;
          box-shadow: 5px 5px 7px 5px rgba(0, 0, 0, .2);
          overflow: hidden;
          top: 0;
          left: 0;
          z-index: 2;
          -webkit-transition: opacity .8s ease-in-out;
          transition: opacity .8s ease-in-out;
          cursor: pointer;
          border-radius: 15px;

          .video-link {
            display: block;
            width: 100%;
            height: 100%;
          }

          .video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
          }

          .btn-play {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 92px;
            height: 92px;
            color: #EC7015;
            font-size: 26px;
            transition: all .3s ease-in-out;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .text-box {
        float: left;
        width: 50%;
        padding: 0 0 0 70px;

        .heading-title {
          font-size: 48px;
          font-weight: 600;
          line-height: 48px;
          margin-bottom: 35px;
          color: #ec7015;
        }

        .desc {
          font-family: $openSans;
          font-size: 20px;
          line-height: 32px;
          color: #333;

          strong {
            font-weight: 600;
          }
        }
      }
    }
  }

  .section-our-product {
    .inner {
      width: $min-width;
      margin: 0 auto;
      padding: 100px 25px 100px 0;
      overflow: hidden;

      .text-box {
        float: left;
        width: 50%;
        padding-right: 25px;

        .heading-title {
          font-size: 45px;
          color: #ec7015;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .heading-title-sm {
          font-size: 24px;
          color: #54595f;
          font-family: $lato;
          margin-bottom: 30px;
          font-weight: 600;
          letter-spacing: .8px;
        }

        .text {
          font-family: $openSans;
          font-size: 20px;
          line-height: 32px;
          color: #333333;
          letter-spacing: -.3px;
          margin-bottom: 40px;

          strong {
            font-weight: 600;
          }
        }

        .btn {
          display: inline-block;
          line-height: 1;
          -webkit-border-radius: 3px;
          text-align: center;
          -webkit-transition: all .3s;
          -o-transition: all .3s;
          transition: all .3s;
          background-color: #ec7015;
          border-color: #ec7015;
          font-family: $openSans;
          font-weight: 700;
          font-size: 1.8rem;
          text-transform: uppercase;
          color: #ec7015;
          background-color: rgba(0, 0, 0, 0);
          border-style: solid;
          border-width: 3px 3px 3px 3px;
          border-radius: 25px 25px 25px 25px;
          padding: 15px 45px 15px 45px;

          &:hover {
            color: #ffffff;
            background-color: #ec7015;
            border-color: #ec7015;
          }
        }
      }

      .thumb-slider {
        float: left;
        width: 50%;
        padding-left: 25px;
      }

      .swiper-slide {
        background-size: cover;
        background-position: center;
      }

      .gallery-top {
        height: 320px;
        width: 100%;
        margin-bottom: 10px;
      }

      .gallery-thumbs {
        height: 54px;
      }

      .gallery-thumbs .swiper-slide {
        width: 25%;
        height: 54px;
      }
    }
  }

  .section-banner {
    .box {
      overflow: hidden;
      display: flex;
      height: 560px;

      .img {
        float: left;
        width: 50%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      }

      .text-box-wrap {
        float: left;
        width: 50%;

        .text-box {
          height: 100%;
          width: 100%;
          transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
          padding: 0 20% 85px 10%;

          .heading-title {
            color: #ec7015;
            font-size: 48px;
            font-weight: 600;
            margin-bottom: 10px;
            padding-top: 85px;
          }

          .heading-title-sm {
            color: #54595f;
            font-family: $lato;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 35px;
          }

          .desc {
            font-family: $openSans;
            color: #333333;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.5px;
            margin-bottom: 40px;
          }

          .btn-border-gray {
            display: inline-block;
            line-height: 1;
            -webkit-border-radius: 3px;
            text-align: center;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            font-family: $openSans;
            font-weight: 600;
            border-color: #353437;
            font-size: 1.8rem;
            text-transform: uppercase;
            background-color: transparent;
            color: #353437;
            border-style: solid;
            border-width: 3px 3px 3px 3px;
            border-radius: 25px 25px 25px 25px;
            padding: 15px 45px 15px 45px;

            &:hover {
              color: #ffffff;
              background-color: #ec7015;
              border-color: #ec7015;
            }
          }

          .btn-border-white {
            display: inline-block;
            line-height: 1;
            -webkit-border-radius: 3px;
            text-align: center;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            font-family: $openSans;
            font-weight: 600;
            border-color: #fff;
            font-size: 1.8rem;
            text-transform: uppercase;
            background-color: transparent;
            color: #fff;
            border-style: solid;
            border-width: 3px 3px 3px 3px;
            border-radius: 25px 25px 25px 25px;
            padding: 15px 45px 15px 45px;

            &:hover {
              color: #ffffff;
              background-color: #ec7015;
              border-color: #ec7015;
            }
          }
        }
      }

      &:first-child {
        .text-box {
          background-color: #f2e3c4;
        }
      }

      &:nth-child(2) {
        .text-box {
          background-color: #353437;
          padding: 0 10% 85px 15%;

          .heading-title-sm {
            color: #e0e0e0;
          }

          .desc {
            color: #ffffff;
          }
        }
      }
    }
  }

  .section-social {
    padding: 100px 0;

    .inner {
      width: $min-width;
      margin: 0 auto;

      .box {
        display: flex;
        width: 100%;

        .item {
          display: flex;
          width: 45%;
          box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, .2);
          padding: 0 0 20px 0;
          align-content: flex-start;

          .item-inner {
            flex-wrap: wrap;
            display: flex;
            width: 100%;

            .item-header {
              display: flex;
              width: 100%;
              height: 92px;
              padding: 20px 0 22px 15px;
              margin-bottom: 20px;
              align-items: center;

              i {
                display: inline-flex;
                justify-content: center;
                -webkit-box-flex: 0;
                width: 50px;
                height: 50px;
                flex: 0 0 auto;
                font-size: 50px;
                text-align: center;
                margin-right: 15px;

                svg {
                  color: #fff;
                  font-size: 50px;
                  transition: all .3s;
                }
              }

              span {
                font-family: $ubuntu;
                font-size: 32px;
                font-weight: 600;
                line-height: 0.1em;
                color: #fff;
              }
            }

            .item-body {
              height: calc(100% - 92px);
              padding: 0 20px;

              .text-area {
                .text-editor {
                  &:hover {
                    text-decoration: underline;
                  }

                  p {
                    font-family: $lato;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 20px;

                    a {
                      font-family: $openSans;
                      color: #333;

                      &:hover {
                        color: #13aff0;
                      }
                    }

                    strong {
                      font-weight: 600;
                    }
                  }
                }
              }

              .player-box {
                margin-bottom: 20px;

                .video-player {
                  position: relative;
                  padding-bottom: 56.25%;

                  iframe {
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                }
              }

              .button-wrap {
                text-align: center;
                margin: 0 0 20px;
                padding-top: 20px;

                .btn-border-gray {
                  width: auto;
                  transition: all .3s;
                  display: inline-block;
                  line-height: 1;
                  font-size: 1.8rem;
                  font-family: $openSans;
                  font-weight: bold;
                  text-transform: uppercase;
                  fill: #353437;
                  color: #353437;
                  background-color: rgba(0, 0, 0, 0);
                  border-style: solid;
                  border-width: 3px 3px 3px 3px;
                  border-radius: 25px 25px 25px 25px;
                  padding: 15px 45px 15px 45px;

                  &:hover {
                    color: #ffffff;
                    background-color: #ffc600;
                    border-color: #ffc600;
                  }
                }
              }

              .feed_dynamic_class {
                overflow: auto;

                .fts-tweeter-wrap {
                  .tweeter-info {
                    padding: 14px 20px 8px;
                    background: 0 0;
                    box-shadow: 0 0 0 #555;
                    border-bottom: 1px solid #eee;
                    word-wrap: break-word;
                    font-family: $openSans;
                    clear: both;
                    font-size: 14px;
                    color: #929292;
                    line-height: 13px;
                    -webkit-transition: background 0;
                    -webkit-transition: all 0s ease-in-out;
                    -moz-transition: all 0s ease-in-out;
                    -o-transition: all 0s ease-in-out;
                    transition: all 0s ease-in-out;

                    &:hover {
                      background: 0 0 !important;
                      box-shadow: 0 0 0 #222 !important;
                    }

                    .fts-twitter-image {
                      margin: 0;
                      float: left;
                      border-radius: 50px;
                    }

                    .fts-right {
                      float: none !important;
                      padding: 0 7px 0 71px;
                      line-height: 15px !important;
                    }

                    .fts-uppercase {
                      margin: 0 5px 0 0;
                      font-size: 13px;
                      font-weight: 700;
                      text-align: left;
                      display: inline-block;
                    }

                    .fts-right .fts-uppercase, .fts-twitter-full-width .fts-uppercase {
                      display: inline-block;
                    }

                    a {
                      transition: all .3s ease-in-out;
                      color: #0084b4;

                      &.fts-twitter-at-name {
                        font-size: 12px !important;
                        color: #999 !important;
                        font-weight: 400 !important;
                      }
                    }

                    .time {
                      font-size: 12px;
                    }

                    .fts-twitter-text {
                      padding: 4px 0 0;
                      display: block;
                      line-height: 19px;
                    }

                    .fts-twitter-caption, blockquote.twitter-video {
                      display: none;
                    }

                    .fts-twitter-quoted-text-wrap {
                      border: 1px solid #efe8e8 !important;
                      margin-top: 10px;
                      font-size: 13px;

                      a {
                        font-size: 13px !important;
                      }
                    }

                    .fts-twitter-quoted-image {
                      padding: 8px;

                      .fts-twitter-quoted-text {
                        padding: 8px 2px 2px !important;
                      }
                    }

                    img {
                      max-width: 100%;
                      box-shadow: none !important;
                      border-radius: 0;
                      height: auto;

                      &.fts-twitter-description-image {
                        margin: 10px 0 0 !important;
                      }
                    }

                    .fts-twitter-no-margin-left {
                      margin-left: 71px;
                      clear: both;
                    }

                    .fts-twitter-reply-wrap {
                      float: left;

                      a {
                        color: rgba(144, 147, 148, .71);
                      }
                    }

                    .fts-twitter-reply-wrap {
                      font-size: 16px;
                      margin-right: 0;
                      margin-top: 10px;
                      font-style: normal !important;

                      .fts-share-wrap {
                        margin-top: 10px;
                        padding: 0;
                      }
                    }

                    .fts-share-wrap {
                      padding: 0;
                      float: left;
                      width: auto;
                      margin: 9px 0 0;
                      display: inline-block;
                      position: relative;
                      z-index: 1;
                    }

                    .fts-share-wrap a.ft-gallery-link-popup {
                      margin: 0 !important;
                      font-size: 12px;
                    }

                    .fts-twitter-reply-wrap-left {
                      float: right;
                      padding: 10px 10px 0;
                      margin-top: 10px;
                    }

                    .fts-mashup .icon-thumbs-up:before, .fts-single-pin-social-meta-wrap, .fts-tweet-reply-left {
                      margin-left: 5px !important;
                    }

                    .fts-tweet-reply-left {
                      float: left;
                      display: inline-block;
                    }

                    .fts-tweet-others-right {
                      margin-left: 65px;

                      a {
                        display: inline-block;

                        i {
                          margin-right: 5px;
                        }
                      }
                    }

                    .fts-twitter-favorites-wrap {
                      margin-left: 20px;
                    }

                    .fts-twitter-favorites, .fts-twitter-reply, .fts-twitter-retweet {
                      font-size: 13px !important;
                    }

                    .fts-twitter-reply {
                      .fa-share {
                        transform: scaleX(-1);
                      }
                    }

                    .fts-clear {
                      clear: both;
                    }
                  }
                }

                a.fts-powered-by-text {
                  display: block;
                  padding-top: 8px !important;
                  width: 100%;
                  transition: all .3s ease-in-out;
                  color: #0084b4;
                  font-family: $openSans;
                  font-size: 14px;
                  text-align: center;
                }
              }
            }
          }

          &:first-child {
            .item-inner {
              .item-header {
                background-color: #ffc600;
              }
            }
          }

          &:nth-child(3) {
            .item-inner {
              .item-header {
                background-color: #38a1f3;
              }
            }
          }

          &.item-empty {
            width: 10%;
            box-shadow: none;
            padding: 0;
            align-content: flex-start;
          }

          &.item-twitter {
            .item-body {
              padding: 0;

              .inner {
                display: block !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 999px) and (max-width: 1140px) {
  .home {
    .section-video {
      padding: 100px 0 100px 0;

      .inner {
        width: auto;
        max-width: 1140px;

        .video-wrap {
          .video-container {
            height: 266px;
          }
        }

        .text-box {
          padding: 0 0 0 20px;

          .heading-title {
            font-size: 45px;
          }
        }
      }
    }

    .section-our-product {
      .inner {
        width: 100%;
        padding: 100px 0 100px 0;
      }
    }

    .section-social {
      .inner {
        width: auto;
        max-width: 1025px;

        .box {
          max-width: 1025px;

          .item {
            width: 48%;

            &.item-empty {
              width: 2%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1140px) {
  .home {
    .main-slider {
      .inner {
        width: 100%;
      }
    }
  }
}

@media (max-width: 999px) {
  .home {
    .section-sponsored {
      .inner {
        width: 661px;
        padding-top: 60px;
        padding-bottom: 46px;

        .sponsored-by {
          .img-wrap {
            display: none;

            &.img-wrap-tablet {
              display: block;
            }

            .img {
              margin-right: 25px;
              margin-bottom: 34px;

              &.img-1 {
                margin-bottom: 34px;
              }

              &.img-2 {
                margin-right: 58px;
                transform: translateY(0);
                margin-bottom: 34px;
              }

              &.img-3 {
                margin-right: 60px;
                transform: translateY(0);
                margin-bottom: 34px;
              }

              &.img-4 {
                margin-right: 56px;
                margin-bottom: 24px;
              }

              &.img-5 {
                margin-right: 0;
              }

              &.img-6 {
                margin-right: 57px;
                margin-bottom: 24px;
              }

              &.img-7 {
                margin-right: 113px;
                transform: translateY(-6px);
              }

              &.img-8 {
                margin-right: 57px;
                margin-bottom: 24px;
                transform: translateY(-6px);
              }

              &.img-9 {
                margin-right: 0;
                margin-bottom: 24px;
                transform: translateY(-9px);
              }

              &.img-10 {
                margin-right: 70px;
                transform: translateY(-3px);
              }
            }
          }
        }
      }
    }

    .section-video {
      padding: 100px 25px 100px 25px;

      .inner {
        width: auto;
        max-width: 1140px;

        .video-wrap {
          .video-container {
            height: 266px;
          }
        }

        .text-box {
          padding: 0 0 0 20px;

          .heading-title {
            font-size: 45px;
          }
        }
      }
    }

    .section-our-product {
      .inner {
        width: auto;
        max-width: 1025px;
        padding: 100px 25px 100px 25px;

        .text-box {
          .heading-title {
            margin-bottom: 20px;
            line-height: 45px;
            padding-top: 0;
          }

          .heading-title-sm {
            font-size: 12px;
            letter-spacing: 2px;
          }

          .text {
            letter-spacing: -.2px;
          }
        }

        .thumb-slider {
          padding-left: 0;
        }

        .gallery-top {
          height: 320px;
        }

        .gallery-thumbs {
          height: 62px;
        }

        .gallery-thumbs .swiper-slide {
          height: 62px;
        }
      }
    }

    .section-banner {
      .box {
        width: 100%;
        flex-wrap: wrap;
        height: 500px;

        .img {
          background-position: center center;
          background-size: cover;
        }

        .text-box-wrap {
          float: none;
          width: 50%;

          .text-box {
            height: 100%;
            width: 100%;
            padding: 0 0 0 20px;

            .heading-title {
              font-size: 45px;
              line-height: 45px;
              padding-top: 80px;
            }

            .heading-title-sm {
              font-size: 14px;
              letter-spacing: 2px;
            }

            .desc {
              font-size: 18px;
              line-height: 1.5em;
              margin-bottom: 20px;
            }
          }
        }

        &:nth-child(2) {
          .text-box {
            padding: 0 20px 0 20px;
          }
        }
      }
    }

    .section-social {
      padding: 50px 0 50px 0;

      .inner {
        width: auto;
        max-width: 1025px;

        .box {
          max-width: 1025px;

          .item {
            width: 48%;

            &.item-empty {
              width: 2%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .home {
    padding-top: 90px !important;

    .section-sponsored {
      .inner {
        width: 312px;
        padding-top: 40px;
        padding-bottom: 13px;

        .sponsored-by {
          .img-wrap {
            display: none;

            &.img-wrap-tablet {
              display: none;
            }

            &.img-wrap-mobile {
              display: block;
            }

            .img {
              float: none;
              display: inline-block;
              margin-right: 35px;
              margin-bottom: 20px !important;

              &.img-1 {
                width: 57px;
              }

              &.img-2 {
                width: 91px;
                margin-right: 42px;
                transform: translateY(0);
              }

              &.img-3 {
                width: 83px;
                margin-right: 0;
                transform: translateY(0);
              }

              &.img-4 {
                width: 61px;
                margin-right: 34px;
                transform: translateY(0);
              }

              &.img-5 {
                width: 20px;
                margin-right: 27px;
              }

              &.img-6 {
                width: 70px;
                margin-right: 27px;
                transform: translateY(0);
              }

              &.img-7 {
                width: 30px;
                margin-right: 34px;
                transform: translateY(0);
              }

              &.img-8 {
                width: 57px;
                margin-right: 34px;
                transform: translateY(0);
              }

              &.img-9 {
                width: 56px;
                margin-right: 0;
                transform: translateY(0);
              }

              &.img-10 {
                width: 59px;
                margin-right: 27px;
                transform: translateY(0);
              }

              &.img-11 {
                width: 80px;
                transform: translateY(0);
              }
            }
          }
        }
      }
    }

    .section-video {
      padding: 50px 0 30px 0;

      .inner {
        width: auto;
        max-width: 1140px;

        .video-wrap {
          float: none;
          width: 100%;
          padding: 0 20px;

          .video-container {
            height: auto;
          }
        }

        .text-box {
          float: none;
          width: 100%;
          padding: 25px 20px 0 20px;

          .heading-title {
            font-size: 45px;
            margin-bottom: 20px;
          }

          .desc {
            margin-bottom: 20px;
            padding-right: 0;
          }
        }
      }
    }

    .section-our-product {
      .inner {
        display: flex;
        max-width: 768px;
        padding: 0;
        -webkit-box-ordinal-group: 10;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .text-box {
          float: none;
          width: 100%;
          padding: 25px 20px 50px 20px;
          -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
          order: 10;

          .heading-title-sm {
            font-size: 12px;
            letter-spacing: 2px;
          }

          .text {
            letter-spacing: -.2px;
          }
        }

        .thumb-slider {
          float: right;
          width: 100%;
          padding-left: 0;
          -webkit-box-ordinal-group: 10;
          -ms-flex-order: 9;
          order: 9;
        }

        .gallery-top {
          width: 100%;
          height: 230px;
        }

        .gallery-thumbs {
          height: 124px;
        }

        .gallery-thumbs .swiper-slide {
          width: 100%;
          height: 124px;
        }
      }
    }

    .section-banner {
      .box {
        height: auto;

        .img {
          float: none;
          width: 100%;
          height: 350px;
        }

        .text-box-wrap {
          width: 100%;

          .text-box {
            padding: 50px 20px 50px 20px;

            .heading-title {
              font-size: 45px;
              line-height: 45px;
              padding-top: 0;
            }

            .heading-title-sm {
              font-size: 15px;
            }

            .desc {
              margin-bottom: 40px;
            }
          }
        }

        &:nth-child(2) {
          flex-wrap: wrap;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-ordinal-group: 11;
          -ms-flex-order: 10;
          order: 10;

          .text-box-wrap {
            -webkit-box-ordinal-group: 11;
            -ms-flex-order: 10;
            order: 10;

            .text-box {
              padding: 50px 20px 50px 20px;
            }
          }

          .img {
            -webkit-box-ordinal-group: 10;
            -ms-flex-order: 9;
            order: 9;
          }
        }
      }
    }

    .section-social {
      padding: 0;

      .inner {
        width: auto;
        max-width: 768px;

        .box {
          max-width: 768px;
          flex-direction: column;

          &:last-child {
            margin-bottom: 20px;
          }

          .item {
            width: 100%;
            box-shadow: none;

            &.item-empty {
              width: auto;
            }
          }
        }
      }
    }
  }
}
</style>
