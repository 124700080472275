import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import Product from "@/views/Product"
import DownloadOverview from "@/views/DownloadOverview"
import DownloadRequest from "@/views/DownloadRequest"
import DownloadFAQ from "@/views/DownloadFAQ"
import Impact from "@/views/Impact"
import About from "@/views/About"
import Press from "@/views/Press"
import FAQ from "@/views/FAQ"
import LegalKitkit from "@/views/LegalKitkit"
import GlobalLearningXprizeCoWinner from "@/views/GlobalLearningXprizeCoWinner"

// sw
import swHome from "@/views/sw/swHome"
import swProduct from "@/views/sw/swProduct"
import swImpact from "@/views/sw/swImpact"
import swAbout from "@/views/sw/swAbout"
import swPress from "@/views/sw/swPress"
import swFAQ from "@/views/sw/swFAQ"
import swLegalKitkit from "@/views/sw/swLegalKitkit"
import swGlobalLearningXprizeCoWinner from "@/views/sw/swGlobalLearningXprizeCoWinner"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/product",
        name: "Product",
        component: Product
    },
    {
        path: "/download-overview",
        name: "DownloadOverview",
        component: DownloadOverview
    },
    {
        path: "/download-request",
        name: "DownloadRequest",
        component: DownloadRequest
    },
    {
        path: "/download-faq",
        name: "DownloadFAQ",
        component: DownloadFAQ
    },
    {
        path: "/impact",
        name: "Impact",
        component: Impact
    },
    {
        path: "/aboutus",
        name: "About",
        component: About
    },
    {
        path: "/press",
        name: "Press",
        component: Press
    },
    {
        path: "/faq",
        name: "FAQ",
        component: FAQ
    },
    {
        path: "/legal-kitkit",
        name: "LegalKitkit",
        component: LegalKitkit
    },
    {
        path: "/global-learning-xprize-co-winner",
        name: "GlobalLearningXprizeCoWinner",
        component: GlobalLearningXprizeCoWinner
    },
    // sw
    {
        path: "/sw",
        name: "swHome",
        component: swHome
    },
    {
        path: "/sw/product",
        name: "swProduct",
        component: swProduct
    },
    {
        path: "/sw/impact",
        name: "swImpact",
        component: swImpact
    },
    {
        path: "/sw/aboutus",
        name: "swAbout",
        component: swAbout
    },
    {
        path: "/sw/press",
        name: "swPress",
        component: swPress
    },
    {
        path: "/sw/faq",
        name: "swFAQ",
        component: swFAQ
    },
    {
        path: "/sw/legal-kitkit",
        name: "swLegalKitkit",
        component: swLegalKitkit
    },
    {
        path: "/sw/global-learning-xprize-co-winner",
        name: "swGlobalLearningXprizeCoWinner",
        component: swGlobalLearningXprizeCoWinner
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return{ x: 0, y: 0}
        }
    }
});

export default router
