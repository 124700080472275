<template>
  <div class="page-not-found">
    <div class="page-header">
      <div class="page-header-inner">
        <h1 class="page-header-title" itemprop="headline">404: Page Not Found</h1>
        <nav aria-label="Breadcrumbs" class="site-breadcrumbs" itemprop="breadcrumb">
          <ol class="trail-items" itemscope="" itemtype="http://schema.org/BreadcrumbList">
            <li class="trail-item trail-begin" itemprop="itemListElement" itemscope=""
                itemtype="https://schema.org/ListItem">
              <router-link to="/sw">
                  <span itemprop="name"><i class="fal fa-home"></i><span
                      class="breadcrumb-home has-icon"></span></span>
              </router-link>
              <span class="breadcrumb-sep">&gt;</span>
            </li>
            <li class="trail-item trail-end" itemprop="itemListElement" itemscope=""
                itemtype="https://schema.org/ListItem"><a span="" itemprop="item" href="#"><span itemprop="name"><span
                class="breadcrumb-error">404 Not Found</span></span></a>
              <meta content="2" itemprop="position">
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="page-container">
      <div class="content-area">
        <div class="site-content">
          <div class="entry">
            <div class="error404-content">
              <h2 class="error-title">This page could not be found!</h2>
<!--              <p class="error-text">We are sorry. But the page you are looking for is not available.<br>Perhaps you can try a new search.</p>-->
<!--              <form role="search" method="get" class="searchform" action="http://kitkitschool.com/sw/">-->
<!--                <label for="ocean-search-form-1">-->
<!--                  <span class="screen-reader-text">Search for:</span>-->
<!--                  <input type="search" id="ocean-search-form-1" class="field" autocomplete="off" placeholder="Search" name="s">-->
<!--                </label>-->
<!--              </form>-->
              <div class="btn-wrap">
                <router-link to="/sw" class="btn">Back To Homepage</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageNotFound",
}
</script>
<style  lang="scss" scoped>
@import '@/assets/scss/common';

.page-not-found {
  padding-top: 76px;

  .page-header {
    position: relative;
    background-color: #f5f5f5;
    padding: 34px 0;

    .page-header-inner {
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
      font-size: 100%;
      position: relative;
      width: 1134px;
      max-width: 90%;
      margin: 0 auto;
    }


    .page-header-title {
      display: block;
      font-size: 24px;
      line-height: 1.4em;
      font-weight: 400;
      margin: 0;
      color: #333;
    }

    .site-breadcrumbs {
      position: absolute;
      right: 0;
      top: 50%;
      max-width: 50%;
      height: 32px;
      line-height: 32px;
      margin-top: -16px;
      font-size: 13px;
      color: #c6c6c6;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;

      ol {
        li {
          display: inline-block;

          a {
            color: #333;

            &:hover {
              color: #13aff0;
            }
          }

          .breadcrumb-sep {
            margin: 0 6px;
            color: rgb(198, 198, 198);
          }
        }
      }
    }
  }

  .page-container {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 1200px;
    max-width: 90%;
    margin: 0 auto;

    .site-content {
      .entry {
        .error404-content {
          position: relative;
          margin: 0 auto;
          text-align: center;

          .error-title {
            font-size: 46px;
            margin: 0 0 25px;
            font-weight: 600;
            color: #333;
            font-family: $openSans;
            line-height: 1.4;
          }
          .btn-wrap {
            text-align: center;

            .btn {
              display: inline-block;
              align-items: center;
              font-family: $openSans;
              font-size: 2rem;
              font-weight: 900;
              border-style: solid;
              border-width: 3px 3px 3px 3px;
              border-color: rgba(255, 255, 255, 0);
              border-radius: 45px 45px 45px 45px;
              transition: all 0.3s ease;
              fill: #ffffff;
              color: #ffffff;
              background-color: #ec7015;
              padding: 15px 50px 15px 50px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .page-not-found {
    .page-header {
      padding: 34px 50px;

      .page-header-inner {
        max-width: 100%;
        width: 100%;
      }

      .site-breadcrumbs {
        position: inherit;
        top: auto;
        left: auto;
        right: auto;
        max-width: 100%;
        margin-top: 0;
      }
    }

    .page-container {
      width: auto;

      .site-content {
        .entry {
          .error404-content {
            position: relative;
            margin: 0 auto;
            text-align: center;
          }
          .btn-wrap {
            .btn {
              transform: scale(0.7);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .page-not-found {
    padding-top: 90px;

    .page-header {
      padding: 34px 20px;
    }
  }
}
</style>
