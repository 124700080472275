<template>
  <div>
    <Header title="FAQ"/>
    <div class="faq-wrap">
      <!-- section -->
      <div class="section-heading">
        <div class="inner">
          <div class="spacer"></div>
          <h2 class="heading-title">Frequently Asked Questions</h2>
          <div class="spacer"></div>
        </div>
      </div>

      <!-- section -->
      <div class="section-faq">
        <div class="inner">
          <div class="accordion" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-1>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title"> What is Kitkit School?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-1" visible accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300">Kitkit School is a tablet-based application with a comprehensive curriculum that spans early childhood through early elementary.</p>
                  <p><span>It is designed to bring high-quality learning experiences to children who need it most around the world, providing them with the foundations and practice needed to build critical reading and math skills as well as confidence — regardless of their initial capacity or access to school and other resources.</span></p>
                  <p><span>In May 2019, Kitkit School was named a grand prize winner of the Global Learning XPRIZE competition. Over the course of the groundbreaking 15-month-long field test conducted by UNESCO and the World Food Programme in remote villages across Tanzania, Kitkit School delivered significant learning gains, demonstrating to the world the power of digital learning.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-2>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">What distinguishes Kitkit School?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-2" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300"><b>Designed to engage and empower.</b><br>Kitkit School combines
                    best practices and leading research in game design as well as literacy and numeracy education to
                    support children’s engagement and learning. The comprehensive learning suite – our learning app
                    with over 2,400 activities, built-in library with hundreds of books and videos, and array of
                    digital tools for exploration and self-expression – encourages students learning how to read,
                    write, and perform basic math to become independent and motivated learners.</p>
                  <p><b>For diverse learners.</b><br>Our team has prioritized accessibility for diverse learners, such
                    as children with special needs, language challenges, or those who are less motivated to learn.
                    Utilizing international best practices in literacy and math education with Universal Design for
                    Learning principles, Kitkit School is designed to help every child succeed as an independent
                    learner.</p>
                  <p><b>Demonstrated results.</b><br>As a co-winner of the Global Learning XPRIZE competition, Kitkit
                    School achieved the highest gains of all finalists in an unprecedented international competition.
                    We have also conducted a number of field tests and case studies that demonstrated powerful
                    learning gains. (See below for more details.) Our team is especially proud to see Kitkit School
                    delivering results for marginalized subgroups, as it reflects our commitment to bringing the
                    highest-quality learning experiences to those who need it most.</p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-3>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">What evidence exists around Kitkit’s effectiveness?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-3" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;"
                          src="~@/assets/img/en/FAQ/owl@4x.png" alt=""
                          width="200" height="300"><span>Kitkit School has proven to be highly effective, transforming learning and delivering significant gains for students.</span>
                  </p>
                  <ul class="dotted-list">
                    <li><span>As the grand prize winners of the Global Learning XPRIZE, Kitkit School demonstrated the greatest learning gains in numeracy and literacy skills for children in the competition’s 15-month field test.&nbsp;</span></li>
                    <li><span>In Kenya’s Kakuma refugee camp, children who came from 9 different countries without any prior knowledge of English demonstrated remarkable learning gains in 8 short weeks.&nbsp;</span></li>
                    <li><span>In a rural school in Rwanda, Kitkit school proved to support children’s self-efficacy and motivation to learn.</span></li>
                  </ul>
                  <p><span>Please visit our <span style="color: #00ccff;"><router-link style="color: #00ccff;" to="/impact">Impact page</router-link></span>&nbsp;for more details.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-4>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">What is the Global Learning XPRIZE competition?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-4" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300">Launched in 2014, the Global Learning XPRIZE challenged innovators around the globe to develop scalable solutions that enable children to teach themselves basic reading, writing and arithmetic within 15 months. In partnership with UNESCO, World Food Programme, and the Tanzanian Ministry of Education, XPRIZE conducted a field test with 2,700 children across 170 remote villages in Tanzania.</p>
                  <p><span>Kitkit School was named one of the top-five finalists in September 2018 and was announced the co-winner of the grand prize in May 2019 for achieving the highest gains in the field test.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-6>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">How do I set-up Kitkit School?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-6" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300">For our full-service product, Kitkit School Pro, we offer simple installation tutorials, a range of hands-on and virtual support services, and easy-to-access web-based tools that allows administrators to centrally manage user information and data on student progress. Kitkit School is also available via GitHub as open-source code, though this version does not include the supports and features listed above. Please contact Team Kitkit at&nbsp;<a class="autolinked" href="mailto:kitkit@enuma.com" target="_blank" rel="noopener noreferrer" data-behavior="truncate">kitkit@enuma.com</a>&nbsp;if you are interested in getting started with Kitkit School!</p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-7>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">What does the literacy and math curriculum cover?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-7" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;"
                          src="~@/assets/img/en/FAQ/owl@4x.png" alt=""
                          width="200" height="300">The Kitkit School Learning App is a suite of interactive
                    educational activities that lets children learn and practice foundational literacy and math
                    skills. The current version of Kitkit School includes 22 egg courses covering early childhood
                    literacy and numeracy with about 2,400 learning activities.</p>
                  <p><strong>Key Learning Areas: Math</strong><br>Number identification / Quantity discrimination /
                    Counting / Representation of numbers / Addition / Subtraction / Finding missing numbers / Shapes /
                    Word problems / Multiplication</p>
                  <p><strong>Key Learning Areas: Literacy</strong><br>Oral language / Print awareness / Alphabetic
                    principle / Phonemic awareness / Phonics / Spelling / Syntax / Vocabulary / Fluency / Written
                    expression / Reading comprehension</p>
                  <p><span>Please see our <a target="_blank" href="/doc/Kitkit-Handbook_V3a.pdf">Learning, Design, and Curriculum Framework document</a> for more information.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-10>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">What learning environments are best suited for Kitkit School?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-10" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img
                      style="margin-left: -230px; margin-top: -40px;"
                      src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200"
                      height="300"><span>Kitkit School can be used in traditional classrooms and other informal learning spaces. It enables children to learn independently when teachers and classrooms are unavailable; it can also be easily integrated with teacher-led lessons, as classrooms and school systems seek blended learning environments. Kitkit has proved to deliver positive results in a range of settings such as:</span>
                  </p>
                  <ul class="dotted-list">
                    <li><strong>primary schools</strong></li>
                    <li><strong>community spaces</strong></li>
                    <li><strong>learning centers</strong></li>
                    <li><strong>1:1 environments</strong></li>
                    <li><strong>refugee camps</strong></li>
                    <li><strong>homes</strong></li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-11>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">How is student progress tracked?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-11" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300"><span>Children complete a variety of assessments to demonstrate their learning and unlock more challenging games and activities within Kitit School. This includes:</span></p>
                  <ul class="dotted-list"><li><span>Mini-Quizzes to help children understand and track their learning progress</span></li><li><span>Post-Course Tests after learners have completed all available Sessions</span></li><li><span>Special Course Assessments at the end of the last Egg Courses in the Math and Literacy sections to help evaluate children’s learning progress</span></li></ul>
                  <p><span>Kitkit School also contains a digital assessment tool that allows teachers and facilitators to administer assessments similar to the EGRA/EGMA.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-12>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">What are the system requirements for Kitkit School?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-12" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;" src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200" height="300"><span>Kitkit School is compatible with Android 6.0 ~ 9.0, and requires a tablet with 2.0 GB (minimum 1.7 GB) Memory and 16GB Storage with 6GB available space. Kitkit School does not require internet access.</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-14>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">How do I get involved?</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-14" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img
                      style="margin-left: -230px; margin-top: -40px;"
                      src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200"
                      height="300">Please send us an email at kitkit@enuma.com.</p>
                  <p><strong>We would love to hear more especially if you:</strong></p>
                  <ul class="dotted-list">
                    <li><span>are helping or running an NGO and might be interested in bringing Kitkit School to your sites</span>
                    </li>
                    <li><span>have resources to localize Kitkit School to be made available in a new language</span>
                    </li>
                    <li><span>are a philanthropist who would like to see Kitkit School help to advance your cause</span>
                    </li>
                    <li>have resources and ideas to make Kitkit School more accessible for children in developing
                      countries or under-resourced communities
                    </li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div class="toggle" v-b-toggle.faq-15>
                  <i class="fas fa-chevron-right"></i>
                  <span class="title">My question is not answered here....</span>
                </div>
              </b-card-header>
              <b-collapse id="faq-15" accordion="faq-accordion" role="tabpanel">
                <b-card-body>
                  <p><img style="margin-left: -230px; margin-top: -40px;"
                          src="~@/assets/img/en/FAQ/owl@4x.png" alt="" width="200"
                          height="300"><span>If you don’t see an answer to your question, please fill out the contact request form at the bottom of the page or email us at <a
                      href="mailto:kitkit@enuma.com" style="color: #333">kitkit@enuma.com</a>!</span></p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'

export default {
  name: "FAQ",
  head: {
    title: {
      inner: 'faq',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Kitkit School' },
      { name: 'twitter:image', content: require('../assets/img/common/leaves@4x.png')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'faq - Kitkit School' },
      { property: 'og:description', content: 'Frequently Asked Questions What is Kitkit School? Kitkit School is a tablet-based application with a comprehensive curriculum that spans early childhood through early elementary. It is designed to bring high-quality learning experiences to children who need it most around the world, providing them with the foundations and practice needed to build critical reading and math […]' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/owl@4x-246x300.png') },
      { property: 'og:url', content: 'http://kitkitschool.com/faq/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/faq/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';
.faq-wrap {
  padding-top: 76px;

  .inner {
    margin: 0 auto;
  }

  .dotted-list {
    margin: 15px 0 15px 20px;

    li {
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-heading {
    .inner {
      max-width: 1140px;
      background-image: url(~@/assets/img/en/FAQ/leaves@4x.png);
      background-position: -20px -135px;
      background-repeat: no-repeat;
      background-size: 35% auto;
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      margin: 0 auto;
      padding: 10px;
    }

    .spacer {
      height: 100px;
      margin-bottom: 20px;
    }

    .heading-title {
      color: #333333;
      font-family: $lato;
      font-size: 42px;
      line-height: 42px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 20px;
      padding-left: 50px;
    }
  }

  .section-faq {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .inner {
      max-width: 1140px;
      padding: 0 0 0 150px;
    }

    .accordion {
      margin-bottom: 80px;

      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: transparent;
        background-clip: border-box;
        border: 0;
        border-radius: 0;
        overflow: visible;

        .card-header {
          padding: 25px 15px 25px 15px;
          margin-bottom: 0;
          background-color: transparent;
          border-bottom: 0;

          .toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            transition: all 0.3s ease;

            i {
              width: 21px;
              font-size: 18px;
            }

            .title {
              font-family: $lato;
              font-size: 24px;
              font-weight: 700;
              line-height: 29px;
              color: #333;
            }

            &.not-collapsed {
              i {
                color: #f6921e;
              }

              .title {
                color: #f6921e;
              }
            }
          }
        }

        .card-body {
          flex: 1 1 auto;
          min-height: 1px;
          border-width: 0;
          border-top-color: #333333;
          padding: 10px 10px 50px 60px;

          p {
            color: #333333;
            font-family: $lato;
            font-size: 18px;
            font-weight: 400;
            line-height: 32.4px;
            margin-bottom: 20px;

            b, strong {
              font-weight: 600;
            }
          }

          a:hover {
            color: #13aff0 !important;
          }

          img {
            height: auto;
            max-width: 100%;
            border: none;
            float: left;
            margin-right: 1.5em;
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .faq-wrap {
    .section-heading {
      .inner {
        max-width: 1025px;
      }
    }
    .section-faq {
      .inner {
        max-width: 1025px;
      }
    }
  }
}

@media (max-width: 719px) {
  .faq-wrap {
    padding-top: 90px !important;

    .section-heading {
      background-position: 0px -62px;
      background-size: 200px auto;

      .inner {
        max-width: 768px;
      }

      .heading-title {
        padding-left: 0;
      }

      .spacer {
        &:last-child {
          height: 30px;
        }
      }
    }

    .section-faq {
      .inner {
        max-width: 768px;
        padding: 0 10px;
      }

      .accordion {
        .card {
          .card-header {
            .toggle {
              i {
                font-size: 15px;
              }
              .title {
                font-size: 18px;
              }
            }
          }

          .card-body {
            padding: 15px;

            p {
              font-size: 16px;
              line-height: 1.5em;
            }
          }
        }
      }

      img {
        display: none;
      }
    }
  }
}
</style>

