<template>
  <div>
    <Header title="DownloadRequest"/>
    <div class="download-request-wrap">
      <div class="inner">
        <div class="section-wrap">
          <div class="section process">
            <div class="content">
              <h1 class="title">Request to Download Kitkit School</h1>
              <!-- Step1 -->
              <div class="step step1"><i class="icon fas fa-user-circle"></i>
                <div class="text">Request for an account</div>
              </div>
              <div class="ellipsis">
                <div class="dot">&nbsp;</div>
                <div class="dot">&nbsp;</div>
                <div class="dot">&nbsp;</div>
              </div>
              <!-- Step2 -->
              <div class="step step2"><i class="icon fas fa-envelope-open-text"></i>
                <div class="text">Receive a notification email with your login credentials after we review your application</div>
              </div>
              <div class="ellipsis">
                <div class="dot">&nbsp;</div>
                <div class="dot">&nbsp;</div>
                <div class="dot">&nbsp;</div>
              </div>
              <!-- Step3 -->
              <div class="step step3">
                <i class="icon fas fa-desktop"></i>
                <div class="text">Log into an admin page to access the Kitkit School installation package</div>
              </div>
              <div class="ellipsis">
                <div class="dot">&nbsp;</div>
                <div class="dot">&nbsp;</div>
                <div class="dot">&nbsp;</div>
              </div>
              <!-- Step4 -->
              <div class="step step4"><i class="icon"></i>
                <div class="text">Follow the instructions to download and install Kitkit School on your tablet device</div>
              </div>
            </div>
          </div>
          <div class="section precedence">
            <div class="content">
              <h2 class="title">Before Submitting the Request</h2>
              <p class="text subtitle"><b>Please double check that: </b></p>
              <div class="text detail">
                <div class="num">(1)</div>
                <div class="sentence">You will have access to a computer to download the installation package.</div>
              </div>
              <div class="text detail">
                <div class="num">(2)</div>
                <div class="sentence">You have a tablet device that meets the minimum device specifications:</div>
              </div>
              <ul class="spec">
                <li class="item"><i class="icon fas fa-circle"></i>
                  <div class="text">Supported OS version : <span class="yale">Android 6.0 – Android 9.0</span></div>
                </li>
                <li class="item"><i class="icon fas fa-circle"></i>
                  <div class="text">Storage : <span class="yale">16GB</span> with <span class="yale">6GB</span> available space</div>
                </li>
                <li class="item"><i class="icon fas fa-circle"></i>
                  <div class="text">RAM : <span class="yale"><span class="yale">2.0GB</span></span>&nbsp;(minimum 1.7GB)</div>
                </li>
                <li class="item"><i class="icon fas fa-circle"></i>
                  <div class="text">You can check your device specs in the Settings app. Please refer to the manual provided by the device manufacturer to learn how to check specifications of your device.</div>
                </li>
              </ul>
              <div class="text detail">
                <div>If you have further questions about installing and using Kitkit School, please visit our
                  <router-link class="link" to="/download-faq" target="_blank">FAQ</router-link> page.</div>
              </div>
            </div>
          </div>
          <iframe style="margin-top: 50px;" src="https://share.hsforms.com/1JpBc-U7bR02wzQwuhrq__A3i6o8" width="640" height="2300" frameborder="0" marginwidth="0" marginheight="0">loading…</iframe>
        </div>
      </div>
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../components/header'
import footer from '../components/footer'
import toTop from '../components/toTop'


export default {
  name: "DownloadFAQ",
  head: {
    title: {
      inner: 'Download-Request',
      separator: '-',
      complement: 'Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Download-Request' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Download-Request - Kitkit School' },
      { property: 'og:site_name', content: 'Kitkit School' },
      { property: 'og:image', content: require('../assets/img/common/download_bg@3x.png') },
      { property: 'og:url', content: 'http://kitkitschool.com/download-request/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/download-request/' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components: {
    Header: header,
    Footer: footer,
    toTop: toTop,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.download-request-wrap {
  padding-top: 76px;

  > .inner {
    padding: 10px 10px 34px;
  }

  .section-wrap {
    background-color: #f4f8fa;
  }

  b, strong {
    font-weight: 600;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin: 0 0 20px;
    color: #333;
    line-height: 1.4;
  }

  .fal, .far {
    font-family: "Font Awesome 5 Pro";
  }

  .yale {
    color: #0c6290;
    font-weight: bold;
  }

  iframe {
    height: 2324px;
  }

  img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .section {
    &.process {
      height: 900px;
      background: url(~@/assets/img/common/download_bg.png) no-repeat 50% 50% #fed400;

      .content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 110px 30px;

        .title {
          font-family: "Ubuntu", Lato, "Open Sans", sans-serif;
          font-size: 60px;
          font-weight: bold;
          line-height: 67px;
          color: #333333;
        }

        .ellipsis {
          padding-left: 35px;
          margin: 14px 0;
          width: 92px;

          .dot {
            background-color: #be8720;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            margin: 0 39.6px 6px 44.4px;
            padding-bottom: 3px;
          }
        }

        .step {
          position: relative;
          min-height: 80px;
          padding: 0 35px;
          font-family: Lato;
          font-size: 32px;
          font-weight: normal;
          line-height: 39px;
          color: #333333;

          .icon {
            position: absolute;
            font-size: 80px;
          }

          .text {
            margin-left: 133px;
          }

          &.step1 {
            margin-top: 78px;

            .icon {
              margin-left: 6.4px;
            }

            .text {
              padding-top: 19.5px;
            }
          }

          &.step2 {
            .icon {
              margin-left: 5.4px;
            }

            .text {
              padding-top: 19.5px;
            }
          }

          &.step3 {
            .icon {
              margin-left: 0.4px;

              &.icon-inner {
                font-size: 40px;
                top: 12px;
                left: 65px;
              }
            }
          }

          &.step4 {
            .icon {
              background: url(~@/assets/img/common/ico_device@3x.png) no-repeat 50% 50%;
              background-size: 100%;
              width: 92px;
              height: 66px;
              margin: 7px 1px;
            }

            .text {
              padding-right: 100px;
            }
          }
        }
      }
    }

    &.precedence {
      background-color: #f2f2f2;

      .content {
        max-width: 1200px;
        margin: 0 auto;
        padding: 110px 30px;

        .title {
          font-family: "Ubuntu", Lato, "Open Sans", sans-serif;
          font-size: 48px;
          font-weight: bold;
          line-height: 54px;
          color: #333333;
        }

        .text {
          font-family: Lato;
          color: #333;
          font-weight: normal;
          margin-top: 40px;
          margin-left: 21px;

          &.subtitle {
            font-family: Lato;
            color: #333;
            font-size: 36px;
            font-weight: bold;
            line-height: 44px;
            margin-top: 60px;
          }

          &.detail {
            position: relative;

            > div {
              font-size: 30px;
              line-height: 39px;
            }

            .link {
              color: #0c6290;
              text-decoration: underline;
            }

            .num {
              position: absolute;
              top: 0;
              left: 0;
            }

            .sentence {
              margin-left: 43px;
            }
          }
        }

        .spec {
          font-family: Lato;
          color: #333;
          margin-top: 30px;
          padding-left: 43px;
          list-style-type: none;

          .item {
            font-family: Lato;
            position: relative;
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: normal;
            line-height: 29px;
            color: #333333;

            .icon {
              position: absolute;
              top: 11px;
              font-size: 10px;
            }

            .text {
              margin-top: 0;
              margin-left: 26px;
              padding-left: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .download-request-wrap {
    .section-wrap {
      height: 4286px;
    }
    .section {
      &.process {
        height: 887px;

        .content {
          min-width: 720px;
          margin: 0 auto;
          padding: 97px 30px;

          .ellipsis {
            padding-left: 33.4px;
            margin: 0;

            .dot {
              margin: 0 38.6px 6px 44.4px;
            }
          }

          .step {
            min-height: 117px;
            padding: 0 10px 0 33.6px;

            .icon {
              margin-top: 18.5px;
            }

            &.step1 {
              .icon {
                margin-left: 7.4px;
              }

              .text {
                padding-top: 39.5px;
              }
            }

            &.step2 {
              .icon {
                margin-left: 6.4px;
              }

              .text {
                padding-top: 22.5px;
              }
            }

            &.step3 {
              .icon {
                margin-left: 2.4px;

                &.icon-inner {
                  left: 63px;
                }
              }
              .text {
                padding-top: 5px;
              }
            }

            &.step4 {
              min-height: 114px;

              .text {
                padding-right: 0;
                padding-top: 0;
              }
            }
          }
        }
      }

      &.precedence {
        min-width: 720px;
      }
    }
  }
}

@media (max-width: 720px) {
  .download-request-wrap {
    .section-wrap {
      height: 3530px;
    }
    .section {
      &.process {
        height: 565px;
        max-width: 720px;
        background: url(~@/assets/img/common/download_bg_360.png) no-repeat 50% 50% #fed400;

        .content {
          width: 100%;
          min-width: 360px;
          padding: 60px 20px;

          .title {
            font-size: 30px;
            line-height: 34px;
          }

          .ellipsis {
            margin: 3px 0;
            width: 28.4px;
            padding-left: 0;

            .dot {
              background-color: #be8720;
              width: 4px;
              height: 4px;
              border-radius: 2px;
              margin: 4px 0 0 29.4px;
            }
          }

          .step {
            min-height: 60px;
            padding: 0 6px;
            font-size: 18px;
            line-height: 22px;

            .icon {
              font-size: 46px;
            }

            .text {
              margin-left: 70px;
              line-height: 19px;
            }

            &.step1 {
              margin-top: 40px;

              .icon {
                margin-left: 2.4px;
                margin-top: 0;
              }

              .text {
                padding-top: 10px;
              }
            }

            &.step2 {
              .icon {
                margin-left: 2.4px;
                margin-top: 7px;
              }

              .text {
                padding-top: 9.5px;
              }
            }

            &.step3 {
              .icon {
                margin-left: 0.4px;
                margin-top: 7px;

                &.icon-inner {
                  font-size: 22px;
                  top: 8px;
                  left: 23px;
                }
              }

              .text {
                padding-top: 10px;
              }
            }

            &.step4 {
              .icon {
                background-size: 100%;
                width: 51.3px;
                height: 36.1px;
                margin-top: 7px;
              }

              .text {
                padding-top: 6px;
              }
            }
          }
        }
      }

      &.precedence {
        min-width: 360px;

        .content {
          max-width: 720px;
          padding: 60px 20px;

          .title {
            font-size: 30px;
            line-height: 34px;
          }

          .text {
            font-size: 18px;
            line-height: 22px;
            margin-top: 20px;
            padding-left: 0;

            b {
              font-size: 22px;
              line-height: 27px;
              margin-top: 40px;
            }

            &.subtitle {
              margin-top: 40px;
            }

            &.detail {
              > div {
                font-size: 18px;
                line-height: 22px;
              }

              .sentence {
                margin-left: 24px;
              }
            }
          }

          .spec {
            margin-top: 20px;
            padding-left: 24px;

            .item {
              margin-bottom: 20px;
              font-size: 14px;
              line-height: 17px;

              .icon {
                position: absolute;
                top: 6px;
                font-size: 6px;
              }

              .text {
                font-size: 14px;
                line-height: 17px;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
