<template>
  <div>
    <Header title="GlobalLearningXprizeCoWinner"/>
    <pageNotFound/>
    <!--
    <div class="global-learning-xprize-co-winner-wrap">
      <div class="section-heading">
        <div class="inner">
          <div class="section-row">
            <div class="section-col"><h3 class="title">Global Learning XPRIZE <br>Co-Winner</h3></div>
            <div class="section-col">
              <div class="img">
                <img src="~@/assets/img/en/Home/Global-Learning-XPRIZE.v2.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-content">
        <div class="inner">
          <div class="section-row">
            <div class="heading-title">Enuma’s Kitkit School Named<br>Grand Prize Co-Winner of<br>$15M Global Learning XPRIZE</div>
            <div class="heading-title-sm">Tablet-Based Software Enables Children Without Access to Schools to Teach Themselves Reading, Writing, and Arithmetic</div>
            <div class="heading-title-xs">After a 15-month long field test with over 3,000 children in remote villages in Tanzania, Enuma and UK-based nonprofit demonstrated the highest learning gains among five finalists.</div>
            <p><span><span>On May 15, 2019, </span><a href="http://www.xprize.org/"><span>XPRIZE</span></a><span>, the world’s leader in designing and managing incentive competitions to solve humanity’s biggest challenges, announced </span><a style="color: #000000; text-decoration: underline;" href="http://www.enuma.com/"><span>Enuma</span></a><span> Inc.’s </span><b>Kitkit School</b><span> tablet-based learning system</span><span> one of the two co-winners of the $10 million grand prize at the Global Learning XPRIZE. The competition challenged teams to develop an open-source, scalable software solution to enable children in developing countries without access to schools, to teach themselves reading, writing, and arithmetic. Kitkit School is a child-driven learning system that includes high-quality learning games, books, videos, and creative tools.</span></span></p>
            <p><span>After a 15-month field test with 3,000 7-to-11-year-old children in over 170 remote villages in Tanzania, Kitkit School, and onebillion demonstrated the greatest proficiency gains in reading, writing, and arithmetic. Elon Musk announced the winners. As part of the competition’s rules, Enuma Inc. will open-source all code and content for Kitkit School, allowing anyone to build on the learning software.</span></p>
            <div class="blockquote-wrap">
              <blockquote class="blockquote">
                <p>
                  “When testing began, only 2% of the children could read as much as a sentence in their native Swahili. Three-quarters had never attended school and many had to be shown how to swipe their finger across a tablet’s screen just to power it up. But 15 months later, 30% of the children had acquired basic reading skills. Children were able to learn an equivalent of a full year, with just playing 1 hour a day.”
                </p>
                <footer>
                  <cite class="blockquote-cite">Emily Church, Executive Director of the Global Learning XPRIZE</cite>
                </footer>
              </blockquote>
            </div>
            <p><span>Named for the Thai word ‘kit,’ which means to think, Kitkit School is a tablet-based personalized learning system, ideal for self-directed early learning in low-resource communities in developing countries. It combines best practices in literacy and numeracy education using the Universal Design for Learning framework, and is designed to empower every child to be an independent learner. Kitkit School’s flexible learning architecture includes a suite of mini-games, books, videos and quizzes along with a free-reign library that allows children to practice reading, writing, counting, and math, as well as music and art.</span></p>
            <div class="blockquote-wrap">
              <blockquote class="blockquote">
                <p>
                  “Our unique strength lies in our sharp focus on the most struggling learners. Our vision is to create solutions that can empower struggling learners to become independent learners. We believe that our unique approach that lowers our users’ barriers while maximizing their success, inspired by our own experience as parents raising different learners, helps us tackle the hardest problems of education.”			</p>
                <footer>
                  <cite class="blockquote-cite">Enuma Inc. CEO and founder Sooinn Lee</cite>
                </footer>
              </blockquote>
            </div>
            <p><span>In building the product, Enuma conducted multiple extensive field tests in Tanzania and Kenya to observe users, gather feedback, and evaluate children’s learning progress. This development is made possible by partnerships with KOICA (Korea International Cooperation Agency), Good Neighbors, and Xavier Project.</span></p>
          </div>
        </div>
      </div>
    </div>
    -->
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../../components/header'
import footer from '../../components/footer'
import toTop from '../../components/toTop'

export default {
  name: "swGlobalLearningXprizeCoWinner",
  head: {
    title: {
      inner: 'Global Learning XPRIZE Co-Winner',
      separator: '-',
      complement: 'Swahili Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../../assets/img/common/cropped-owl@4x-270x270.png') },
      { name: 'twitter:title', content: 'Global Learning XPRIZE Co-Winner' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Global Learning XPRIZE Co-Winner - Swahili Kitkit School' },
      { property: 'og:site_name', content: 'Swahili Kitkit School' },
      { property: 'og:url', content: 'http://kitkitschool.com/global-learning-xprize-co-winner/' },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/global-learning-xprize-co-winner/' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-owl@4x-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-owl@4x-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../../assets/img/common/cropped-owl@4x-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.global-learning-xprize-co-winner-wrap {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 76px 0 0 0;
  overflow: hidden;

  .inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  .section-heading {
    padding: 10px 0;
    background-color: #f2af3c;

    .section-row {
      display: flex;

      .section-col {
        width: 50%;

        .title {
          color: #000000;
          font-family: "Ubuntu", Lato, Open sans;
          font-size: 60px;
          font-weight: 900;
          line-height: 72px;
        }

        &:first-child {
          position: relative;
          display: flex;
          align-content: center;
          align-items: center;
          padding: 0 10% 0 0;
        }

        &:nth-child(2) {
          padding: 10px 10px 10px 10px;

          .img {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            transform: rotateZ(-11deg);
            margin-top: 20px;
            align-items: center;
            justify-content: center;

            img {
              vertical-align: middle;
              display: inline-block;
              max-width: 85%;
              border-style: solid;
              border-width: 10px 10px 10px 10px;
              border-color: #ffffff;
            }
          }
        }
      }
    }
  }

  .section-content {
    .heading-title {
      margin: -3% 0% -3% 0%;
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 38px;
      font-weight: 600;
      line-height: 38px;
      text-align: center;
      padding: 100px 10px 60px 10px;
    }

    .heading-title-sm {
      margin: -2% 0 40px 0%;
      font-size: 23px;
      font-weight: 600;
      font-family: $openSans;
      color: #333;
      line-height: 23.8px;
      padding: 10px;
      text-align: center;
    }

    .heading-title-xs {
      font-family: $openSans;
      font-weight: 600;
      margin: 0 0 30px;
      color: #333;
      text-align: center;
      line-height: 23.8px;
    }

    p {
      padding: 10px 10px 0;
      font-size: 14px;
      font-family: $openSans;
      line-height: 25.2px;
      margin-bottom: 10px;

      &:last-child {
        padding-bottom: 30px;
      }
    }

    a {
      color: #000000;
      text-decoration: underline;
    }

    b, strong {
      font-weight: 600;
    }

    .blockquote-wrap {
      padding: 35px 10px 0;
    }

    .blockquote {
      border: 0;
      border-left: 7px #e6e9ec;
      border-style: solid;
      margin: 0 0 10px 0;
      padding: 0 0 0 20px;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      background: transparent;
      quotes: none;
      font-style: normal;
      color: #55595c;

      p {
        font-family: $openSans;
        font-size: 14px;
        padding-top: 0;
        margin: 0;
        outline: 0;
        vertical-align: baseline;
        background: transparent;
        quotes: none;
        border: 0;
        font-style: normal;
        color: #55595c;
      }

      footer {
        margin-top: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .blockquote-cite {
          position: relative;
          display: block;
          font-family: $openSans;
          padding: 10px 0 0 20px;
          color: #555;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .global-learning-xprize-co-winner-wrap {
    .inner {
      max-width: 1025px;
    }

    .section-heading {
      padding: 10px 25px 10px 25px;

      .section-row {
        .section-col {
          .title {
            font-size: 35px;
            line-height: 42px;
          }

          &:nth-child(2) {
            .img {
              transform: rotateZ(-8.547deg);
            }
          }
        }
      }
    }

    .section-content {
      .inner {
        max-width: 90%;
        padding: 0 10px;
      }
      .heading-title {
        padding: 100px 25px 60px 25px;
      }

      .heading-title-sm {
        margin-top: 35px;
        margin-bottom: 30px;
        line-height: 32.2px;
      }
    }
  }
}

@media (max-width: 719px) {
  .global-learning-xprize-co-winner-wrap {
    padding-top: 90px;

    .inner {
      max-width: 768px;
    }

    .section-heading {
      padding: 20px;

      .section-row {
        flex-direction: column;

        .section-col {
          width: 100%;

          &:nth-child(2) {
            padding: 0;

            .img {
              transform: rotateZ(-1.87deg);

              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    .section-content {
      .inner {
        max-width: 100%;
        padding: 0;
      }
      .heading-title {
        padding: 50px 20px 30px 20px;
        font-size: 32px;
        line-height: 32px;
        text-align: left;
      }

      .heading-title-sm {
        margin-top: 0;
        padding: 0 30px 20px;
      }

      .heading-title-xs {
        padding: 0 48px;
        font-size: 17px;
      }

      p {
        padding: 0 50px;
      }

      .blockquote-wrap {
        padding: 30px 50px 10px;
      }

      .blockquote {
        p {
          padding: 0;
        }

        footer {
          .blockquote-cite {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
