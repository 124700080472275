<template>
  <div>
    <Header title="About"/>
    <div class="about-wrap">
      <!-- section -->
      <div class="section-heading">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <h3 class="title">Kuhusu sisi</h3>
            </div>
            <div class="section-col">
              <div class="img" id="rotateImage">
                <img src="~@/assets/img/en/About/EnumaGroupPhoto1600-2.jpeg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-info">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <div class="title">Kujenga Ujuzi wa Msingi kwa watoto WOTE</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Timu ya Kitkit™ Shule ni timu maalum ya mradi ya Kampuni ya Enuma, Kampuni ambayo lengo lake ni kutengeneza zana za kiteknolojia ambazo zinatawasaidia wanafunzi walioko katika mazingira magumu kujifunza kupata kujiamini na kujifunza wenyewe huku wakijipatia ujuzi wa msingi. Ikiwa imeundwa mwaka 2012 Berkeley, California, na ikiwa na ofisi Seoul na Beijing</span>.</p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Kiongozi kwenye Ubunifu wa kujifunza elimu ya mapema kidijitali</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Kampuni ya Enuma. Timu nyuma ya mradi wa Kitkit Shule imeunganisha miongo ya uzoefu wa pamoja kwenye elimu, michezo, uhandisi, muundo, na maendeleo ya kimataifa na kujitolea kwa pamoja kukuza elimu shirikishi na yenye usawa ambapo wanafunzi wote wanaweza wakafanikiwa.</span></p>
                <p><span style="font-weight: 400;">Wafanyakazi wetu ambao ni waunda programu, wabunifu, na waelimishaji wanafanya kazi bila kuchoka na kwa lengo la kutengeneza ufumbuzi wa kujifunza kwa njia ya kidijitali ambao utashirikisha na kuwezesha</span>.</p>
              </div>
            </div>
            <div class="section-col">
              <div class="title">Awards & Recognitions for Enuma</div>
              <div class="dotted-list-wrap">
                <ul class="dotted-list">
                  <li>2019 <span style="font-weight: 400;">Mshindi wa shindalo la kujifunza la X-prize</span></li>
                  <li>2018 <span style="font-weight: 400;">Nusu fainali kwenye Tuzo ya Kidunia ya Saini kwa ajili ya kusoma na kuandika</span>
                  </li>
                </ul>
                <ul class="dotted-list">
                  <li>2017 <span style="font-weight: 400;">Mmoja wa washindi watano waliofika fainali kwenye shindalo la kujifunza la X-Prize</span>
                  </li>
                </ul>
                <ul class="dotted-list">
                  <li>2016 <span style="font-weight: 400;">Program bora ya mwaka ya Google Play Korea kwenye kipengele cha Familia</span>
                  </li>
                </ul>
                <ul class="dotted-list">
                  <li>2016 <span style="font-weight: 400;">Tuzo ya fainali ya SIIA CODiE</span></li>
                </ul>
                <ul class="dotted-list">
                  <li><span style="font-weight: 400;">Mshindi Tuzo ya Dhahabu mwaka 2015 Chaguo la wazazi</span></li>
                </ul>
                <ul class="dotted-list">
                  <li><span style="font-weight: 400;">Tuzo ya muundo bora katika Anzisha Elimu &amp; Kongamano la Watoto mwaka 2013</span>
                  </li>
                </ul>
                <ul class="dotted-list">
                  <li><span
                      style="font-weight: 400;">Ubora wa Nyota tano uliopimwa kwenye vyombo vya milango ya fahamu</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Ubora wa kiufundi & muundo unaomlenga mtumiaji</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Timu ya Kitkit Shule imechanganya ubora wake wa kiufundi pamoja na shauku yake ya kumaliza mahitaji yote ya kipekee ya watoto wote. Kwa kuingiza viwango bora vya kuunda michezo na tafiti kwenye elimu ya awali ya mtoto, sayansi ya neuro na nadharia ya kujifunza, Kitkit Shule imetengenezwa kuwezesha matokeo ya kujifunza yenye mafanikio kwa watoto wote, kuwafikia popote walipo na kuhimiza ushirikishwaji wa muda mrefu na hamasa ya kujifunza. </span></p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Tunaendeshwa na taarifa</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Timu yetu imejumuisha tafiti zinazoongoza kwenye sayansi ya neuro na elimu maalum, pamoja na mazoezi bora ya kimataifa kwenye kujifunza na mafunzo. Timu yetu pia inakusanya michakato kwa usalama, na kuunganisha taarifa za watumiaji kutoka kwenye programu zetu, ambazo tunazitumia kuimarisha matokeo ya kujifunza na matumizi.</span></p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="title">Kujitolea kwa Kusaidia Watoto Walioachwa</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Lengo la msingi la Enuma limekuwa watoto wenye uhitaji maalum au tofauti kwenye kujifunza, watoto ambao wanakosa rasilimali za kujifunzia na watoto ambao hawapati mifumo bora ya elimu.</span></p>
                <p><span style="font-weight: 400;">Mafanikio ya programu zetu ambazo zinapatikana kibiashara — zikijumuisha Todo Math, Kid in story Book maker, na Visual Schedule for Children with autism — Imedhihirisha kujitolea kwetu kwenye kutengeneza bidhaa bora katika kuwawezesha watoto wote</span><span style="font-weight: 400;">.</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
      <div class="section-our-founders">
        <div class="inner">
          <div class="section-row">
            <div class="section-col">
              <div class="title">Waanzilishi wetu</div>
              <div class="desc">
                <p><span style="font-weight: 400;">Ikiwa imeundwa mwaka 2012 na waunda michezo wawili ambao walihamasishwa na mtoto wao ambaye alikuwa ni mwenye uhitaji maalum, Enuma inaendeshwa na dhamira yake ya kuwashirikisha watoto na kuwawezesha wenye uhitaji maalum kujifunza wenyewe.</span></p>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-col">
              <div class="img">
                <img src="~@/assets/img/en/About/founder.jpeg" alt="">
              </div>
              <div class="founders">
                <p><strong>Sooinn Lee</strong><span style="color: #999999;"><span style="font-weight: 400;"><small>&nbsp;<small>— Mkurugenzi mkuu</small></small></span></span></p>
                <p><strong>Gunho Lee</strong> <span style="color: #999999;"><small><span style="font-weight: 400;"><small>— Mhandisi mkuu</small></span></small></span></p>
              </div>
            </div>
            <div class="section-col">
              <p class="quote"><strong>“Kuunda Kitkit Shule, tumetumia miongo ya elimu, michezo, na uzoefu wa maendeleo ya kimataifa. Tunaamini watoto ambao hawawezi kupata shule, msaada na rasilimali au ambao wanapata shida shuleni wanahitaji zana bora za kujifunzia”</strong></p>
              <p class="quote-name" style="text-align: right;"><span style="font-weight: 400;"><small>— Sooinn Lee, Mkurugenzi mkuu na Muongozaji wa ubunifu wa Enuma</small></span></p>
            </div>
          </div>
        </div>
      </div>

      <!-- section -->
<!--      <div class="section-partnerships">-->
<!--        <div class="inner">-->
<!--          <div class="title">Mahusiano</div>-->
<!--          <div class="desc">-->
<!--            <p><span style="font-weight: 400;">Kitkit shule ilianzishwa kwa msaada wa ukarimu kutoka Shirika la ushirikiano la Korea(KOICA) mpango wa teknolojia ya ubunifu(CTS). Hadithi yetu ya athari isingewezekana kuwepo bila msaada wa washirika wetu.</span></p>-->
<!--          </div>-->
<!--          <div class="partnerships-list">-->
<!--            <div class="list">-->
<!--              <div class="img"><img src="~@/assets/img/en/About/irc.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/ail.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/imagine.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/ce.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/xp.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/ub.png" alt=""></div>-->
<!--              <div class="img"><img src="~@/assets/img/en/About/gn.png" alt=""></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <toTop/>
    <Footer/>
  </div>
</template>

<script>
import header from '../../components/swheader'
import footer from '../../components/swfooter'
import toTop from '../../components/toTop'

export default {
  name: "swAbout",
  head: {
    title: {
      inner: 'Aboutus',
      separator: '-',
      complement: 'Swahili Kitkit School'
    },
    meta: [
      { name: 'msapplication-TileImage', content: require('../../assets/img/common/cropped-kitkit-phase3_icon-270x270.png') },
      { name: 'twitter:title', content: 'Kitkit School' },
      { name: 'twitter:image', content: require('../../assets/img/common/airbel-logo-black-tagline.jpeg')},
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: 'Aboutus - Swahili Kitkit School' },
      { property: 'og:description', content: 'Kuhusu sisi Kujenga Ujuzi wa Msingi kwa watoto WOTE Timu ya Kitkit™ Shule ni timu maalum ya mradi ya Kampuni ya Enuma, Kampuni ambayo lengo lake ni kutengeneza zana za kiteknolojia ambazo zinatawasaidia wanafunzi walioko katika mazingira magumu kujifunza kupata kujiamini na kujifunza wenyewe huku wakijipatia ujuzi wa msingi. Ikiwa imeundwa mwaka 2012 Berkeley, California, […]' },
      { property: 'og:site_name', content: 'Swahili Kitkit School' },
      { property: 'og:image', content: require('../../assets/img/common/EnumaGroupPhoto1600-2-1024x619.jpeg') },
    ],
    link: [
      { rel: 'canonical', href: 'http://kitkitschool.com/sw/aboutus/' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-32x32.png'), sizes: '32x32', type: 'image/png' },
      { rel: 'icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-192x192.png'), sizes: '192x192', type: 'image/png' },
      { rel: 'apple-touch-icon', href: require('../../assets/img/common/cropped-kitkit-phase3_icon-180x180.png'), sizes: '180x180', type: 'image/png' },
    ]
  },
  components : {
    Header : header,
    Footer : footer,
    toTop : toTop,
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      var scrollHeight = document.body.scrollHeight;
      var maxRotate = -18.868;
      var rotateVal = 1.836 + ((document.querySelector('html').scrollTop * maxRotate) / scrollHeight) * 10;
      var rotateImage = document.getElementById("rotateImage");
      rotateImage.style.transform = 'rotateZ('+ rotateVal +'deg)';
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/common';

.about-wrap {
  padding-top: 76px;
  overflow: hidden;

  .inner {
    max-width: 1140px;
    margin: 0 auto;
  }

  .section-row {
    display: flex;
  }

  img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  p {
    margin-bottom: 20px;
    line-height: 39.6px
  }

  b, strong {
    font-weight: 600;
  }

  .dotted-list {
    margin: 15px 0 15px 20px;

    li {
      list-style: outside;
      color: #333333;
      font-family: $lato;
      font-size: 22px;
      font-weight: 400;
      line-height: 35.2px;

      b {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .section-heading {
    position: relative;
    background-color: #6d684e;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    z-index: 1;

    .inner {
      padding: 10px;
    }

    .section-row {
      .section-col {
        width: 50%;

        &:first-child {
          display: flex;
          align-content: center;
          align-items: center;
          width: 40%;
        }

        &:nth-child(2) {
          width: 60%;
        }

        .title {
          color: #ffe000;
          font-family: "Ubuntu", Lato, Open sans;
          font-size: 6rem;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1.2em;
        }

        .img {
          transform: rotateZ(-4.72deg);

          img {
            border-style: solid;
            border-width: 7px 7px 7px 7px;
            border-color: #ffffff;
            transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
          }
        }
      }
    }
  }

  .section-info {
    background-color: #ffe000;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 150px 0px 100px 0px;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.2em;
      letter-spacing: -0.2px;
      margin-bottom: 20px;
    }

    .desc {
      padding: 10px 0px 0px 0px;

      p {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }

    .section-row {
      &:nth-child(2) {
        margin-top: 100px;
        margin-bottom: 0px;

        .section-col {
          display: flex;
          width: 50%;
          flex-direction: column;

          &:first-child {
            padding: 0px 60px 0px 0px;
          }

          &:nth-child(2) {
            padding: 0 0 0 60px;
          }
        }
      }

      &:nth-child(3) {
        margin-top: 130px;
        margin-bottom: 0px;
      }

      &:nth-child(4), &:nth-child(5) {
        margin-top: 100px;
        margin-bottom: 0px;
      }
    }
  }

  .section-our-founders {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px 90px 0px;
    background-color: #e8e8e8;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 52px;
      font-weight: 900;
      letter-spacing: -0.2px;
      margin-bottom: 20px;
    }

    .desc {
      margin: 0px 0px 0px 0px;
      padding: 10px 0px 0px 0px;

      p {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 39.6px;
      }
    }

    .section-row {
      &:first-child {
        .section-col {
          width: 100%;
        }
      }

      &:nth-child(2) {
        margin-top: 20px;
        margin-bottom: 0;

        .section-col {
          .img {
            margin-bottom: 20px;

            img {
              border-style: solid;
              border-width: 8px 8px 8px 8px;
              border-color: #ffffff;
            }
          }

          .founders {
            padding: 0px 0px 0px 10px;

            p {
              color: #333333;
              font-family: "Lato", Lato, Open sans;
              font-size: 22px;
              font-weight: 400;
              line-height: 0.5em;
            }
          }

          .quote {
            color: #333333;
            font-family: "Lato", Lato, Open sans;
            font-size: 26px;
            font-weight: 600;
            line-height: 2em;

            em {
              font-style: italic;
            }
          }

          .quote-name {
            font-size: 26px;

            small {
              font-size: 80%;
            }
          }

          &:first-child {
            width: 40%;
          }

          &:nth-child(2) {
            width: 60%;
            padding: 0px 0px 0px 50px;
          }
        }
      }
    }
  }

  .section-partnerships {
    padding: 100px 0 80px 0;

    .title {
      color: #333333;
      font-family: "Ubuntu", Lato, Open sans;
      font-size: 52px;
      font-weight: 900;
      letter-spacing: -0.2px;
      margin-bottom: 20px;
    }

    .desc {
      margin: 0px 0px 40px 0px;
      padding: 10px 0px 0px 0px;

      p {
        color: #333333;
        font-family: "Lato", Lato, Open sans;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 39.6px;
      }
    }

    .partnerships-list {
      .list {
        display: flex;

        .img {
          img {
            max-width: 85%;
            filter: brightness(100%) contrast(100%) saturate(0%) blur(0px) hue-rotate(0deg);
          }

          &:hover {
            img {
              filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
            }
          }

          &:first-child {
            padding: 0 0 0 5px;
            width: 10%;

            img {
              max-width: 55%;
            }
          }

          &:nth-child(2) {
            padding: 10px;
            width: 16%;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            padding: 10px;
            width: 16%;
          }

          &:nth-child(7) {
            width: 9.589%;

            img {
              max-width: 75%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 999px) {
  .about-wrap {
    .inner {
      max-width: 1025px;
    }

    p {
      line-height: 36px
    }

    .dotted-list {
      li {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .section-heading {
      .section-row {
        .section-col {

          &:first-child {
            padding: 0px 20px 0px 0px;
          }

          &:nth-child(2) {
            padding: 0px 0px 0px 20px;
          }

          .title {
            font-size: 40px;
          }
        }
      }
    }

    .section-info {
      padding: 80px 20px 80px 20px;

      .title {
        font-size: 30px;
      }

      .desc {

        p {
          font-size: 20px;
        }
      }

      .section-row {
        &:nth-child(2) {
          margin-top: 50px;
        }

        &:nth-child(3) {
          margin-top: 50px;
        }

        &:nth-child(4), &:nth-child(5) {
          margin-top: 50px;
        }
      }
    }

    .section-our-founders {
      margin-top: 0;
      margin-bottom: 0;
      padding: 60px 20px 40px 20px;

      .title {
        font-size: 30px;
      }

      .desc {
        p {
          font-size: 20px;
          line-height: 36px;
        }
      }

      .section-row {
        &:nth-child(2) {
          .section-col {
            .founders {
              p {
                font-size: 20px;
              }
            }

            .quote {
              font-size: 20px;
            }

            .quote-name {
              font-size: 16px;
              line-height: 40px;
            }
          }
        }
      }
    }

    .section-partnerships {
      padding: 60px 20px 40px 20px;

      .title {
        font-size: 30px;
      }

      .desc {
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 719px) {
  .about-wrap {
    padding-top: 90px !important;

    .inner {
      max-width: 768px;
    }

    p {
      margin-bottom: 20px;
      line-height: 39.6px
    }

    .dotted-list {
      li {
        font-size: 16px;
        line-height: 25.6px;
      }
    }

    .section-heading {
      .section-row {
        flex-direction: column;

        .section-col {
          width: 100%;
          padding: 0 !important;

          &:first-child {
            width: 100%;
          }

          &:nth-child(2) {
            width: 100%;
          }

          .img {
            transform: rotateZ(-1.936deg);
          }
        }
      }
    }

    .section-info {
      padding: 50px 20px 30px 20px;

      .desc {
        p {
          font-size: 16px;
          line-height: 28.8px;
        }
      }

      .section-row {
        &:nth-child(2) {
          flex-direction: column;

          .section-col {
            width: 100%;
            padding: 0 !important;
          }
        }
      }
    }

    .section-our-founders {
      .desc {

        p {
          font-size: 16px;
          line-height: 28.8px;
        }
      }

      .section-row {
        flex-direction: column;

        &:nth-child(2) {
          .section-col {
            .img {
              margin-bottom: 10px;
            }

            .founders {
              p {
                font-size: 16px;
                line-height: 28.8px;
                margin-bottom: 0;
              }
            }

            .quote {
              padding-top: 20px;
              font-size: 16px;
              line-height: 32px;

              em {
              }
            }

            .quote-name {
              small {
                font-size: 12.8px;
              }
            }

            &:first-child {
              width: 100%;
              padding: 0 !important;
            }

            &:nth-child(2) {
              width: 100%;
              padding: 0 !important;
            }
          }
        }
      }
    }

    .section-partnerships {

      .title {
        font-size: 30px;
      }

      .desc {
        p {
          font-size: 16px;
          line-height: 28.8px;
        }
      }

      .partnerships-list {
        .list {
          flex-wrap: wrap;

          .img {
            text-align: center;

            &:first-child {
              width: 33%;

              img {
                max-width: 30%;
              }
            }

            &:nth-child(2) {
              width: 33%;
            }

            &:nth-child(3),
            &:nth-child(6) {
              width: 34%;
            }

            &:nth-child(4),
            &:nth-child(5) {
              width: 33%;
            }

            &:nth-child(7) {
              width: 33%;

              img {
                max-width: 40%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
